import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  EsraPipingAPIClient,
  ServiceDetailViewModel,
} from 'src/app/shared/models/autogenerated-piping';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-service-details-add-edit',
  templateUrl: './service-details-add-edit.component.html',
  styleUrls: ['./service-details-add-edit.component.scss'],
})
export class ServiceDetailsAddEditComponent implements OnInit {
  inputFormGroup: UntypedFormGroup;
  serviceDetail: ServiceDetailViewModel;

  title: string;
  genericMissingValueMessage = 'This is a required field';

  submitClicked = false;
  isCreateMode = false;
  isEditMode = false;

  constructor(
    private esraApiClient: EsraPipingAPIClient,
    private snackBarService: SnackBarService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getStoredServiceDetail();

    if (this.isCreateMode) {
      this.title = 'Create a new service detail';
    }

    if (this.isEditMode) {
      this.title = 'Edit service detail';
    }

    this.initializeFormControls();
    this.setFormValues();
  }

  getStoredServiceDetail(): void {
    const storedServiceDetail = localStorage.getItem('serviceDetail');
    if (storedServiceDetail) {
      this.serviceDetail = JSON.parse(storedServiceDetail);
      localStorage.removeItem('serviceDetail');
      this.isEditMode = true;
    } else {
      this.isCreateMode = true;
    }
  }

  private setFormValues() {
    this.inputFormGroup.setValue({
      name: this.serviceDetail.name,
    });
  }

  private initializeFormControls(): void {
    this.inputFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }
  
  public keydownEnterHandler(e: any) {
    if (e.keyCode == 13) this.save();
  }

  public save(): void {
    this.submitClicked = true;

    if (this.inputFormGroup.valid) {
      if (this.isCreateMode) {
        const newServiceDetail = new ServiceDetailViewModel({
          name: this.inputFormGroup.get('name').value,
          isActive: true,
        });

        this.esraApiClient.createServiceDetail(newServiceDetail)
          .subscribe({
            next: () => {
              this.snackBarService.showSnackBar(
                true,
                `Service detail ${newServiceDetail.name} was successfully created.`
              );
              this.router.navigate(['/piping/dictionaries/servicedetails']);
            },
            error: () => {
              this.snackBarService.showSnackBar(
                true,
                `There may already be a service with the name, ${newServiceDetail.name}`
              )
            }
          });
      }

      if (this.isEditMode) {
        this.serviceDetail.name = this.inputFormGroup.get('name').value;
        this.esraApiClient.updateServiceDetail(this.serviceDetail)
          .subscribe({
            next: () => {
              this.snackBarService.showSnackBar(
                true,
                `Service detail, ${this.serviceDetail.name}, was successfully updated`
              )
              this.router.navigate(['/piping/dictionaries/servicedetails']);
            },
            error: (err) => {
              this.snackBarService.showSnackBar(
                true,
                err.response,
                'mat-snack-bar-error'
              )
            }
          });
      }
    }

    if (this.inputFormGroup.invalid) {
      this.inputFormGroup.markAllAsTouched();
    }
  }

  closeWindow() {
    window.close();
  }
}
