// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spec-title {
  text-align: left;
  font-weight: bold;
  letter-spacing: 0.02px;
  color: #6b6d6f;
  opacity: 1;
}

.download {
  color: #0066b2;
  cursor: pointer;
}

.download-disabled {
  color: #000000;
}

.demo-table {
  width: 60%;
  margin: 0 auto;
}

.mat-option {
  width: 300px;
}

::ng-deep .mdc-menu-surface.mat-mdc-select-panel {
  overflow: visible;
  width: 300% !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
