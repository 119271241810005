import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalToRational'
})
export class DecimalToRationalPipe implements PipeTransform {

  transform(value: number): string {
    const decimal = value % 1;
    const whole = value - decimal;
    if(decimal === 0){
      return whole.toString();
    } else {
      const stringDecimal = decimal.toString().replace('0.', '');
      let baseTenFactor = Math.pow(10, stringDecimal.length);
      let numerator = decimal * baseTenFactor;
      let denominator = baseTenFactor;
      let divisor = getGreatestCommonDenominator(numerator, denominator);
      numerator /= divisor;
      denominator /= divisor;
      if (whole === 0) {
        return numerator + '/' + denominator;
      } else {
        return whole + '-' + numerator + '/' + denominator;
      }
    }
  }
}


function getGreatestCommonDenominator(a: number, b: number): number {
  while (b !== 0) {
    let t = b;
    b = a % b;
    a = t;
  }
  return a;
}

