import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { HomeComponent } from './home/home.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NotAuthorizedComponent, HomeComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    SharedModule,
    RouterModule,
  ],
})
export class SampleModule {}
