// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solution-panel-pipe {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1607843137);
  opacity: 1;
  margin: 32px 25px;
  padding: 12px 0;
}
.solution-panel-pipe .title-container-pipe {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-left: 2%;
  width: 95%;
}
.solution-panel-pipe .title-container-pipe .name-container-pipe {
  display: flex;
  flex-direction: row;
}
.solution-panel-pipe .title-container-pipe .heading-pipe {
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0px;
  color: #707072;
  margin-right: 10px;
}
.solution-panel-pipe .title-container-pipe .sub-heading-pipe {
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0066b2;
  background: none;
  border: none;
  padding: 0;
  color: #069;
  cursor: pointer;
}
.solution-panel-pipe .prop-pipe {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.solution-panel-pipe .prop-label-pipe {
  font-family: "Gotham", Arial, sans-serif;
  font-weight: 100;
  font-size: 15px;
  text-align: left;
  font-weight: 100;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0 0 0.1em 0;
  padding: 0;
  margin-right: 6px;
  white-space: nowrap;
  line-height: 24px;
}
.solution-panel-pipe .prop-value-pipe {
  font-family: "Gotham", Arial, sans-serif;
  font-size: 15px;
  font-weight: 100;
  text-align: left;
  color: rgba(126, 126, 126, 0.8705882353);
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
}
.solution-panel-pipe .prop-value-pipe p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.column-container-pipe {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}
.column-container-pipe .left-col-pipe {
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  width: 28%;
  max-width: 28%;
}
.column-container-pipe .mid-col-pipe {
  margin-top: 3.6%;
  display: flex;
  flex-direction: column;
  width: 36%;
}
.column-container-pipe .right-col-pipe {
  margin-top: 3.6%;
  display: flex;
  flex-direction: column;
  margin-right: 2%;
  width: 36%;
}
.column-container-pipe .right-col-pipe .version-col-pipe {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.column-container-pipe .right-col-pipe .justify-end {
  align-self: flex-end;
}

.highlighted {
  background-color: #faab18;
  font-weight: bold;
}

.add-to-workspace-container {
  padding-right: 2%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
