import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'celsiusToFahrenheit',
})
export class CelsiusToFahrenheitPipe implements PipeTransform {
  transform(value: number, shouldConvert = true): string {
    if (shouldConvert) {
      const fahrenheit = value * (9 / 5) + 32;
      return fahrenheit.toFixed(0);
    } else {
      return value.toString();
    }
  }
}
