import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-esra1-compatibility',
  templateUrl: './esra1-compatibility.component.html',
  styleUrls: ['./esra1-compatibility.component.scss']
})
export class Esra1CompatibilityComponent {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    const pipeclassNameAndBusinessUnit = this.route.snapshot.paramMap?.get(
      'pipeclassNameAndBusinessUnit'
    );
    if (pipeclassNameAndBusinessUnit) {
      const pipeClassName = pipeclassNameAndBusinessUnit.split('_')[0];
      const businessUnit = pipeclassNameAndBusinessUnit.split('_')[2];
      this.router.navigate(['/pipingsummary/', pipeClassName, businessUnit]);}
  
  }

}
