// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  display: flex;
  flex-direction: column;
}

.add-service-temperature {
  margin-top: 2%;
}

.temperature-toggle-container {
  margin: 2% 3%;
}

.service-input .temp-input {
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  border: solid 1px red;
}

.remove-input * {
  height: 102px;
}

.service-temp-input-container {
  display: flex;
  align-items: center;
  margin: 2% 0;
}
.service-temp-input-container .entry {
  margin: 0 2%;
}

.service-temp-input-container * {
  margin-left: 1%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
