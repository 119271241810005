import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EsraPipingAPIClient } from 'src/app/shared/models/autogenerated-piping';

@Component({
  selector: 'app-edit-confirm',
  templateUrl: './edit-confirm.component.html',
  styleUrls: ['./edit-confirm.component.scss']
})
export class EditConfirmComponent  {

  constructor(
    private dialogRef: MatDialogRef<EditConfirmComponent>,
    private esraApiClient: EsraPipingAPIClient, 
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
 
  @Output() messageEvent = new EventEmitter<string>();
  faWindowClose = faWindowClose;
  referenceCount: number = 0;

  ngOnInit() {
    this.getReferenceCount();
  
  }

  getReferenceCount(): void { 
    if(this.data.type === 'Service'){
      this.esraApiClient.getPipeClassesByServiceId(this.data.id).subscribe({
        next: (res) => {
          if(res.length > 0){
            this.referenceCount = res.length;
          }
        },
        error: (err) => console.log("error in getReferenceCount()")
      });
      }

    if(this.data.type === 'Common Note'){
      this.esraApiClient.getPipeClassesByCommonNoteId(this.data.id).subscribe({
        next: (res) => {
          if(res.length > 0){
            this.referenceCount = res.length;
          }
        },
        error: (err) => console.log("error in getReferenceCount()")
      });
    }
    
    if(this.data.type === 'Service Detail'){
      this.esraApiClient.getPipeClassesByServiceDetailId(this.data.id).subscribe({
        next: (res) => {
          if(res.length > 0){
            this.referenceCount = res.length;
          }
        },
        error: (err) => console.log("error in getReferenceCount()")
      });
    }
  }

  emitConfirm(): void {
    this.messageEvent.emit("confirm");
    this.dialogRef.close();
  }
}
