import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, first, of, tap, throwError } from "rxjs";
import { InsightsService } from "src/app/shared/services/insights.service";
import { SnackBarService } from "src/app/shared/services/snack-bar.service";
import { UserService } from "../services/user.service";
import { environment } from "src/environments/environment";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor{

    constructor(private snackBarService: SnackBarService,
        private insightsService: InsightsService,
        private userService: UserService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                this.snackBarService.showError("The application encountered an error and cannot complete your request. Please try again later or use the Contact Us page for assistance");

                this.blobToText(error.error).pipe(first(),
                    catchError(err=> of(this.sendError(error, error.error))),
                    tap((text) => {
                        this.sendError(error, text);
                    })).subscribe();
                
                return throwError(() => error);
            })
        )
    }

    private sendError(error: HttpErrorResponse, text) {
        const ex: Error =  {
            name : error.name,
            message : error.message + '\nError occured For user: ' + this.userService.getCurrentUser().name + ' (' + this.userService.getCurrentUser().username + ') ',
            stack : text,
        }
        this.insightsService.logException(ex, 1);
        if(environment['isLocal']) {
            console.error(ex.message, ex.stack);
        }
    }

    /* Method borrowed from swagger autogen*/
    private blobToText(blob: any): Observable<string> {
        return new Observable<string>((observer: any) => {
            if (!blob) {
                observer.next("");
                observer.complete();
            } else {
                const reader = new FileReader();
                reader.onload = event => {
                    observer.next((event.target as any).result);
                    observer.complete();
                };
                reader.readAsText(blob);
            }
        });
    }

}