// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solution-panel-valve {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1607843137);
  opacity: 1;
  margin: 32px 25px;
  padding: 12px 0;
}
.solution-panel-valve .title-container-valve {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-left: 2%;
  width: 95%;
}
.solution-panel-valve .title-container-valve .heading-valve {
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0px;
  color: #707072;
  margin-right: 10px;
}
.solution-panel-valve .title-container-valve .sub-heading-valve {
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0066b2;
  background: none;
  border: none;
  padding: 0;
  color: #069;
  cursor: pointer;
}
.solution-panel-valve .title-container-valve .description-valve {
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0px;
  color: black;
}
.solution-panel-valve .solution-title-valve {
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
  width: 95%;
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0px;
  color: #0066b2;
}
.solution-panel-valve .solution-subtitle-valve {
  display: flex;
  width: 95%;
  font-family: "Gotham", Arial, sans-serif;
  font-size: 14px;
  color: #0066b2;
  margin-left: 2%;
  margin-right: 2%;
}
.solution-panel-valve .prop-valve {
  display: flex;
  flex-direction: row;
}
.solution-panel-valve .prop-label-valve {
  font-family: "Gotham", Arial, sans-serif;
  font-weight: 100;
  font-size: 15px;
  text-align: left;
  font-weight: 100;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0 0 0.1em 0;
  padding: 0;
  margin-right: 6px;
}
.solution-panel-valve .prop-value-valve {
  font-family: "Gotham", Arial, sans-serif;
  font-size: 15px;
  font-weight: 100;
  text-align: left;
  color: rgba(126, 126, 126, 0.8705882353);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.column-container-valve {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}
.column-container-valve .left-col-valve {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 2%;
  width: 23%;
}
.column-container-valve .mid-col-valve {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 39%;
}
.column-container-valve .right-col-valve {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-right: 2%;
  width: 38%;
}
.column-container-valve .right-col-valve .version-col-valve {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.column-container-valve .right-col-valve .justify-end {
  align-self: flex-end;
}

.highlighted {
  background-color: #faab18;
  font-weight: bold;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
