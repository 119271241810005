import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appValueOrDash]'
})
export class ValueOrDashDirective {


  constructor(private elementRef: ElementRef) { 
  }

  ngAfterViewInit(){
    if(this.elementRef.nativeElement.textContent.trim() === '') {
      this.elementRef.nativeElement.textContent = '-'
    }
}

}
