// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.silver-border-white-back {
  border-left: 1px solid silver;
  border-right: 1px solid silver;
  background-color: white;
}

.ellipses {
  border: 1px solid silver;
}

th {
  border: 1px solid silver;
  background-color: #f7f7f7;
  font-weight: bold;
  padding-top: 0px;
  padding-bottom: 0px;
}

.group-heading {
  border: 1px solid silver;
  background-color: #f7f7f7;
  font-weight: bold;
  padding-top: 0px;
  padding-bottom: 0px;
}

.mat-icon {
  float: right;
}

.componentType {
  padding-left: 35px;
  font-weight: bold;
  background-color: white;
  border-right: 1px solid silver;
}

.empty-cells {
  border-top: 1px solid silver;
  border-left: 1px solid silver;
  background-color: white;
}

.na {
  border-bottom: 1px solid silver;
}

td {
  padding: 5px;
}

table tr th,
table tr td {
  padding: 8px 8px;
}

.note-container {
  display: flex;
  flex-direction: row;
  color: #0066b2;
  border-right-width: 0px;
  border-left-width: 0px;
  background-color: white;
}
.note-container .note-element {
  margin-right: 3px;
}
.note-container .note-element:hover {
  margin-right: 3px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bolder;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
