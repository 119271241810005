import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductPanelHelper {
  public strReplaceHighlightHtml(str: string): string {
    if (str)
      return str.replace(/<em>/g, '<em class="highlighted">');
  }

  public arrReplaceHighlightHtml(array: string[]): string[] {
    if (array)
      array.forEach(this.strReplaceHighlightHtml)
    return array;
  }

  //adapted from https://stackoverflow.com/questions/822452/strip-html-from-text-javascript/47140708#47140708
  public static RemoveHTML(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }
}
