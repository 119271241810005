// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.email {
  color: #8c8f93;
}

.user-info {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.iconf-btn-custom {
  color: #e21836;
}

.user-entry {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 150px;
}

.member-options {
  display: flex;
  gap: 20px;
}

.read-only {
  margin: 3% 0;
  color: #0066b2;
  font-weight: bold;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
