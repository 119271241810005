import { Pipe, PipeTransform } from '@angular/core';
import { RevisionCommentStatus } from 'src/app/shared/models/autogenerated-coating';

@Pipe({
  name: 'revisionCommentStatus',
})
export class RevisionCommentStatusPipe implements PipeTransform {
  transform(value: RevisionCommentStatus): string {
    switch (value) {
      case RevisionCommentStatus._0: {
        return "Open";
      }
      case RevisionCommentStatus._1: {
        return "Resolved";
      }
      case RevisionCommentStatus._2: {
        return "Rejected";
      }
      default: {
        return '';
      }
    }
  }
}
