import { Component, OnInit, Output, ViewChild, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { faSearch, faPlusCircle, faSort, faTrashCan, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { ComponentViewModel, EsraPipingAPIClient, PipeClassViewModel, PipingRevisionViewModel, RevisionStatus, SimpleRevisionViewModel } from 'src/app/shared/models/autogenerated-piping';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { BehaviorSubject, first } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { TechModCreateComponent } from '../techmod-create/techmod-create.component';
import { UserService } from 'src/app/core/services/user.service';
import { indicate } from 'src/app/operators';
import { MultiSelectFilterComponent } from 'src/app/search/filters/multi-select/multi-select-filter/multi-select-filter.component';

class Filter {
  filterName: string | null;
  options: string[];

  constructor(filterName: string, options: string[]) {
    this.filterName = filterName;
    this.options = options;
  }
}

class TableObject {
    revisionId: number;
    title?: string;
    authorName?: string;
    status?: RevisionStatus;
    bu?: string;
    application: string;
    component?: string;
}

@Component({
  selector: 'app-piping-releases-and-revisions',
  templateUrl: './piping-releases-and-revisions.component.html',
  styleUrls: ['./piping-releases-and-revisions.component.scss'],
})
export class ReleasesAndRevisionsComponent implements OnInit {
    faSearch = faSearch;
    faPlusCircle = faPlusCircle;
    faSort = faSort;
    faTrashCan = faTrashCan;
    faArrowDown = faArrowDown;
    faArrowUp = faArrowUp;

    loadingIndicator$ = new BehaviorSubject<boolean>(false);

    revisions: Array<PipingRevisionViewModel>;
    checkedItems: PipingRevisionViewModel[] = [];

    titles: string[] = [];
    authors: string[] = [];
    statuses: number[] = [];
    businessUnits: string[] = [];
    applications: string[] = [];
    components: string[] = [];

    selectedFilters: string[] = [];

    revisionObjects: Array<PipeClassViewModel | ComponentViewModel> = [];

    tableComponents: Array<ComponentViewModel> = [];
    tablePipeClasses: Array<PipeClassViewModel> = [];
    tableObjects: Array<TableObject> = [];
    dataSource: MatTableDataSource<TableObject>;

    selectAllText = 'select all';
    columnNames: string[] = ['title', 'author', 'status', 'bu', 'application', 'component', 'trashCan'];
    revisionObjectTitles: string[] = [];

    revisionsToDelete: TableObject[] = [];
    canBulkDelete = false;

    // paginator variables
    paginatorTotalCount: number;
    currentPage = 0;
    pageSize = 10;
    pageSizes = [2, 5, 10, 25, 50, 100];

    STATUS_BOX_LENGTH = 871;
    
    drafts: number[] = [];
    underReview: number[] = [];
    readyForPublish: number[] = [];

    numOfRevisionsInDraft = 0;
    numOfRevisionsUnderReview = 0;
    numOfRevisionsReadyForPublish = 0;

    draftFraction = 0;
    reviewFraction = 0;
    readyForPublishFraction = 0;

    draftFractionArr: number[] = [];
    reviewFractionArr: number[] = [];
    readyForPublishFractionArr: number[] = [];
    
    isTitleAsc = false;
    isAuthorAsc = false;
    isStatusAsc = false;
    isBuAsc = false;
    isApplicationAsc = false;
    isComponentAsc = false;

    allRevisionsSelected = false;
    showIssueRevisionsButton = true;
    userIsAdmin = false;
    searchText = '';

    lastDeselectedOption: string;

    filters: Filter[] = [];

    loading$ = new BehaviorSubject<boolean>(false);

    @Output() checkedItem: EventEmitter<SimpleRevisionViewModel[]> = new EventEmitter<SimpleRevisionViewModel[]>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChildren(MultiSelectFilterComponent) childFilters: QueryList<MultiSelectFilterComponent>;

    constructor(
        private esraPipingAPIClient: EsraPipingAPIClient,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.esraPipingAPIClient.getUnpublishedRevisions()
            .pipe(
                indicate(this.loading$),
                first()
            )
            .subscribe(revisions => {
                this.revisions = [...revisions];
                this.convertPipeClassesAndComponentsToTableObjects(revisions);
                this.dataSource = new MatTableDataSource(this.tableObjects);
                this.dataSource.paginator = this.paginator;
                this.setNumOfStatuses();
                this.initializeUniqueRevisionObjectProperties(this.tableObjects);
                this.paginatorTotalCount = this.tableObjects.length;
                this.dataSource.filterPredicate = this.customFilterPredicate;
            });
        this.userIsAdmin = this.userService.canPublishPipingAndValvesRevisions();
    }

    customFilterPredicate(data: TableObject, allFiltersAsString: string): boolean {
        const filters: string[] = allFiltersAsString.split('*');

        const keyValuePairs = filters.map((str) => {
            const [filterType, val] = str.split(':');
            return { filterType, val};
        });

        if (keyValuePairs.length < 1) {
            return false;
        }

        const titles: string[] = [];
        const authorOids: string[] = [];
        const statuses: string[] = [];
        const businessUnits: string[] = [];
        const applications: string[] = [];
        const components: string[] = [];

        for (const keyValuePair of keyValuePairs) {
            const keyValueType = keyValuePair.filterType;
            switch (keyValueType) {
                case 'title':
                    titles.push(keyValuePair.val);
                    break;
                case 'author':
                    authorOids.push(keyValuePair.val);
                    break;
                case 'status':
                    statuses.push(keyValuePair.val);
                    break;
                case 'bu':
                    businessUnits.push(keyValuePair.val);
                    break;
                case 'application':
                    applications.push(keyValuePair.val);
                    break;
                case 'component':
                    components.push(keyValuePair.val);
                    break;
            }
        }

        let titleCheck = false;
        let authorOidCheck = false;
        let statusCheck = false;
        let buCheck = false;
        let applicationCheck = false;
        let componentCheck = false;

        if (titles.length < 1 || titles.includes(data.title)) {
            titleCheck = true;
        }

        if (authorOids.length < 1 || authorOids.includes(data.authorName)) {
            authorOidCheck = true;
        }

        if (statuses.length < 1 || statuses.includes(data.status.toString())) {
            statusCheck = true;
        }

        if (businessUnits.length < 1 || businessUnits.includes(data.bu)) {
            buCheck = true;
        }

        if (applications.length < 1 || applications.includes(data.application)) {
            applicationCheck = true;
        }

        if (components.length < 1 || components.includes(data.component)) {
            componentCheck = true;
        }

        return titleCheck && authorOidCheck && statusCheck && buCheck && applicationCheck && componentCheck;
    }

    convertPipeClassesAndComponentsToTableObjects(revisions: PipingRevisionViewModel[]) {
        revisions.forEach((revision) => {
            if (revision.pipeClasses !== null) {
                revision.pipeClasses.forEach((pipeClass) => {
                    const tableObject: TableObject = {
                        revisionId: revision.id,
                        title: pipeClass.name,
                        // BE needs to be updated so the SimpleRevisionViewModels contain an author's displayName
                        authorName: revision.authorName,
                        status: revision.status,
                        bu: pipeClass.businessUnits[0],
                        application: pipeClass.application,
                        component: 'pipe' 
                    };
                    this.tableObjects.push(tableObject);
                });
            }

            if (revision.components !== null) {
                revision.components.forEach(() => {
                    const tableObject: TableObject = {
                        revisionId: revision.id,
                        title: '',
                        authorName: revision.authorName,
                        status: revision.status,
                        bu: '', // populate after Component/BU relation is made
                        application: '', // populate after Component/Application relation is made
                        component: 'valve'
                    }
                    this.tableObjects.push(tableObject);
                });
            }
        });
    }

    initializeUniqueRevisionObjectProperties(tableObjects: Array<TableObject>) {
        tableObjects.forEach(obj => {
            if (!this.titles.includes(obj.title) && obj.title != '' && obj.title != null) {
                this.titles.push(obj.title);
            }
            if (!this.authors.includes(obj.authorName) && obj.authorName != '' && obj.authorName != null) {
                this.authors.push(obj.authorName);
            }
            if (!this.statuses.includes(obj.status) && obj.status != null) {
                this.statuses.push(obj.status);
            }
            if (!this.businessUnits.includes(obj.bu) && obj.bu != '' && obj.bu != null) {
                this.businessUnits.push(obj.bu);
            }
            if (!this.applications.includes(obj.application) && obj.application != '' && obj.application != null) {
                this.applications.push(obj.application);
            }
            if (!this.components.includes(obj.component)) {
                this.components.push(obj.component);
            }
        });

        const uniqueStatusesAsStrings = [];
        for (const status of this.statuses) {
            uniqueStatusesAsStrings.push(this.convertStatusToString(status));
        }

        if (this.titles.length > 0) {
            this.filters.push(new Filter('title', this.titles));
        }

        if (this.authors.length > 0) {
            this.filters.push(new Filter('author', this.authors));
        }

        if (this.statuses.length > 0) {
            this.filters.push(new Filter('status', uniqueStatusesAsStrings));
        }

        if (this.businessUnits.length > 0) {
            this.filters.push(new Filter('bu', this.businessUnits));
        }

        if (this.applications.length > 0) {
            this.filters.push(new Filter('application', this.applications));
        }

        if (this.components.length > 0) {
            this.filters.push(new Filter('component', this.components));
        }
    }

    setNumOfStatuses() {
        for (const tableObject of this.tableObjects) {
            switch (tableObject.status) {
                case 0:
                    this.numOfRevisionsInDraft++;
                    break;
                case 1:
                    this.numOfRevisionsUnderReview++;
                    break;
                case 2:
                    this.numOfRevisionsReadyForPublish++;
                    break;
            }
        }

        const totalReviews = this.tableObjects.length;

        this.draftFraction = Math.floor((this.numOfRevisionsInDraft / totalReviews) * this.STATUS_BOX_LENGTH);
        this.reviewFraction = Math.floor((this.numOfRevisionsUnderReview / totalReviews) * this.STATUS_BOX_LENGTH);
        this.readyForPublishFraction = Math.floor((this.numOfRevisionsReadyForPublish / totalReviews) * this.STATUS_BOX_LENGTH);

        for (let i = 0; i < this.draftFraction; i++) {
            this.draftFractionArr.push(0);
        }

        for (let i = 0; i < this.reviewFraction; i++) {
            this.reviewFractionArr.push(0);
        }

        for (let i = 0; i < this.readyForPublishFraction; i++) {
            this.readyForPublishFractionArr.push(0);
        }
    }

    setLastDeselectedOption(event) {
        this.lastDeselectedOption = event;
    }

    onCheckboxChange(event: Set<string>, filter: Filter) {
        const setValues = event.values();
        let filterValue;
        let filterToAdd = '';
        let lastDeselectedOptionWithFilterInfo = '';

        for (const value of setValues) {    
            if (filter.filterName === 'status') {
                filterValue = this.convertStatusStringToNumStr(value);
            } else {
                filterValue = value;
            }
            filterToAdd = `${filter.filterName}:${filterValue}`;
        }

        if (filter.filterName === 'status') {
            this.lastDeselectedOption = this.convertStatusStringToNumStr(this.lastDeselectedOption);
        }
        lastDeselectedOptionWithFilterInfo = `${filter.filterName}:${this.lastDeselectedOption}`;

        if (this.selectedFilters.includes(lastDeselectedOptionWithFilterInfo)) {
            this.selectedFilters = this.selectedFilters
                .filter(f => f !== lastDeselectedOptionWithFilterInfo);
        } else if (this.selectedFilters.includes(lastDeselectedOptionWithFilterInfo + '*')) {
            this.selectedFilters = this.selectedFilters
                .filter(f => f !== `${lastDeselectedOptionWithFilterInfo}*`);
        } else {
            this.selectedFilters.push(filterToAdd);
        }

        this.applyFilters();
    }

    clearAllFilters() {
        this.childFilters.forEach((childFilter) => {
            childFilter.clearSelections();
        });

        this.selectedFilters = [];
        this.dataSource.filter = '';
    }

    search() {
        this.dataSource.filterPredicate = (tableObj: TableObject) => {
            const userInput = this.lowerCaseAndTrimStr(this.searchText);

            if (tableObj.title) {
                const recordTitle = this.lowerCaseAndTrimStr(tableObj.title);
                if (recordTitle.includes(userInput)) {
                    return true;
                }
            }

            if (tableObj.authorName) {
                const recordAuthor = this.lowerCaseAndTrimStr(tableObj.authorName);
                if (recordAuthor.includes(userInput)) {
                    return true;
                }
            }

            if (tableObj.status) {
                const recordStatus = this.lowerCaseAndTrimStr(this.convertStatusToString(tableObj.status));
                if (recordStatus.includes(userInput)) {
                    return true;
                }
            }

            if (tableObj.bu) {
                const recordBU = this.lowerCaseAndTrimStr(tableObj.bu);
                if (recordBU.includes(userInput)) {
                    return true;
                }
            }

            if (tableObj.application) {
                const recordApplication = this.lowerCaseAndTrimStr(tableObj.application);
                if (recordApplication.includes(userInput)) {
                    return true;
                }
            }

            if (tableObj.component) {
                const recordComponent = this.lowerCaseAndTrimStr(tableObj.component);
                if (recordComponent.includes(userInput)) {
                    return true;
                }
            }

            return false;
        }
        this.dataSource.filter = this.searchText;
    }

    lowerCaseAndTrimStr(input: string): string {
        if (input) {
            return input.toLowerCase().trim();
        }
    }

    clearSearchText() {
        this.searchText = '';
        this.clearAllFilters();
    }

    markRevisionForDeletion(event, revision: TableObject): void {
        const isChecked: boolean = event.target.checked;

        if (isChecked) {
            this.revisionsToDelete.push(revision);
            this.canBulkDelete = this.nonDraftRevisionsOnly(this.revisionsToDelete);
        }
        
        if (!isChecked) {
            this.removeFromArray(this.revisionsToDelete, revision);
            this.canBulkDelete = this.nonDraftRevisionsOnly(this.revisionsToDelete);
        }
    }

    selectRevision(event, revisionId) {
        const isChecked: boolean = event.target.checked;

        if (isChecked) {
            const selectedItem = this.revisions.find(revision => revision.id === revisionId);
            this.checkedItems.push(selectedItem);
        } else {
            this.checkedItems = this.checkedItems.filter(revision => revision.id !== revisionId);
        }
    }

    convertStatusToString(statusNum: number) {
        if (statusNum === 0) return 'Draft';
        if (statusNum === 1) return 'Under Review';
        if (statusNum === 2) return 'Ready for Publish';
        if (statusNum === 3) return 'Published';
    }

    convertStatusStringToNumStr(statusString: string) {
        if (statusString === 'Draft') return '0';
        if (statusString === 'Under Review') return '1';
        if (statusString === 'Ready for Publish') return '2';
        if (statusString === 'Published') return '3';
    }

    createNewRevision() {
        this.snackBarService.showInfo('Create new revision has not been implemented yet');
    }

    openIssueRevisionsDialog() {
        if (this.checkedItems.length < 1) {
            this.snackBarService.showSnackBar(
                    true, 
                    'You have not selected any revisions to issue', 
                    null,
                    null,
                    2000,
                    'center',
                    'bottom'
                );
            return;
        }

        if (this.userService.canPublishPipingAndValvesRevisions()) {
            const dialogRef = this.dialog.open(TechModCreateComponent, {
                width: '65%',
                maxHeight: '80%',
                data: {selectedItems: this.checkedItems}
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result === 'reload') {
                    location.reload();
                }
            });
        } else {
            this.snackBarService.showError('You are not authorized to issue revisions');
        }
    }

    deleteRevision(revisionToDelete: TableObject) {
        if (window.confirm(`Are you sure you want to delete revision ${revisionToDelete.title}?`)) {
            this.esraPipingAPIClient.deleteRevision(revisionToDelete.revisionId)
                .subscribe(() => {
                    location.reload();
                });
        }
    }

    deleteRevisions(revisionsToDelete: TableObject[]) {
        if (revisionsToDelete.length > 0 && this.nonDraftRevisionsOnly(this.revisionsToDelete)) {
            let names = '';

            for (let i = 0; i < revisionsToDelete.length; i++) {
                names += revisionsToDelete[i].title;
                if (i != revisionsToDelete.length - 1) {
                    names += ', '
                }
            }
    
            if (window.confirm(`Are you sure you want to delete the ${this.revisionsToDelete.length} revisions? (${names})`)) {
                for (const revision of revisionsToDelete) {
                    this.esraPipingAPIClient.deleteRevision(revision.revisionId).subscribe(() => {
                        location.reload();
                    });
                }
            }   
        } else {
            this.snackBarService.showInfo('No revisions are currently selected.');
        }
    }

    updatePage(event: PageEvent) {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
    }

    sortTable(columnName: string): void {
        const sortedDataSource = this.dataSource.data;

        sortedDataSource.sort((a, b) => {
            if (columnName === 'title' && this.titles.length > 1) {
                return this.compare(a.title.toLowerCase(), b.title.toLowerCase(), this.isTitleAsc);
            } else if (columnName === 'author' && this.authors.length > 1) {
                return this.compare(a.authorName, b.authorName, this.isAuthorAsc);
            } else if (columnName === 'status' && this.statuses.length > 1) {
                return this.compare(a.status.toString(), b.status.toString(), this.isStatusAsc);
            } else if (columnName === 'bu' && this.businessUnits.length > 1) {
                return this.compare(a.bu, b.bu, this.isBuAsc);
            } else if (columnName === 'application' && this.applications.length > 2) {
                return this.compare(a.application, b.application, this.isApplicationAsc);
            } else if (columnName === 'component' && this.components.length > 1) {
                return this.compare(a.component, b.component, this.isComponentAsc);
            }
        });
        this.dataSource.data = sortedDataSource;

        switch (columnName) {
            case 'title':
                this.isTitleAsc = !this.isTitleAsc;
                break;
            case 'author':
                this.isAuthorAsc = !this.isAuthorAsc;
                break;
            case 'status':
                this.isStatusAsc = !this.isStatusAsc;
                break;
            case 'bu':
                this.isBuAsc = !this.isBuAsc;
                break;
            case 'application':
                this.isApplicationAsc = !this.isApplicationAsc;
                break;
            case 'component':
                this.isComponentAsc = !this.isComponentAsc;
                break;
        }
    }

    compare(a: string, b: string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    removeFromArray(array: unknown[], element: unknown): void {
        const removalIndex = array.indexOf(element);
        if (removalIndex !== -1) {
            array.splice(removalIndex, 1);
        }
    }

    convertSelectedFiltersIntoString(allFiltersAsString) {
        for (let i = 0; i < this.selectedFilters.length; i++) {
            allFiltersAsString += this.selectedFilters[i];
            if (i < this.selectedFilters.length - 1) {
                allFiltersAsString += '*';
            }
        }
        return allFiltersAsString;
    }

    applyFilters() {
        let allFiltersAsString = '';
        allFiltersAsString = this.convertSelectedFiltersIntoString(allFiltersAsString);
        this.dataSource.filter = allFiltersAsString;
    }

    selectAllRevisions() {
        if (!this.allRevisionsSelected) {
            this.dataSource.filteredData.forEach((tableObj) => {
                const revisionToAdd = this.revisions.find(revision => revision.id === tableObj.revisionId);
                if (
                    revisionToAdd
                    && !this.checkedItems.some(item => item.id === revisionToAdd.id) 
                    && (revisionToAdd.pipeClasses.length > 0 || revisionToAdd.components.length > 0)
                ) {
                    this.checkedItems.push(revisionToAdd);
                }
                this.revisionsToDelete.push(tableObj);
            });
    
            const checkboxes = document.querySelectorAll('table input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                (checkbox as HTMLInputElement).checked = true;
            });

            this.selectAllText = 'deselect all';
        } else {
            this.checkedItems = [];
            this.revisionsToDelete = [];

            const checkboxes = document.querySelectorAll('table input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                (checkbox as HTMLInputElement).checked = false;
            });

            this.selectAllText = 'select all';
        }

        this.allRevisionsSelected = !this.allRevisionsSelected;
    }

    private nonDraftRevisionsOnly(revisionsArray:TableObject[]): boolean {
        return (revisionsArray.length > 0 && revisionsArray.filter(revision => revision.status !== RevisionStatus._0).length === 0) ? true : false;
    }
}
