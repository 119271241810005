import { Pipe, PipeTransform } from '@angular/core';
import { RevisionApprovalStatus } from 'src/app/shared/models/autogenerated-coating';

@Pipe({
  name: 'approvalStatus',
})
export class ApprovalStatusPipe implements PipeTransform {
  transform(value: RevisionApprovalStatus): string {
    switch (value) {
      case RevisionApprovalStatus._0: {
        return 'pending';
      }
      case RevisionApprovalStatus._1: {
        return 'rejected';
      }
      case RevisionApprovalStatus._2: {
        return 'approved';
      }
      default: {
        return '';
      }
    }
  }
}
