import { OnInit, Component, Input } from '@angular/core';
import { SolutionCoat } from '../../models/autogenerated-coating';

@Component({
  selector: 'app-coat-detail',
  templateUrl: './Coat-Detail.component.html',
  styleUrls: ['./Coat-Detail.component.scss'],
})
export class CoatDetailComponent implements OnInit {
  @Input()
  public solutionCoat: SolutionCoat;

  coatType: string;
  genericClassification: string;
  dft: string;
  notes: string;

  ngOnInit() {
    this.coatType = this.solutionCoat.coatModel.coatName;
    this.genericClassification = this.solutionCoat.coatModel.genericClassification;
    this.dft = this.solutionCoat.coatModel.dft;
    this.notes = this.solutionCoat.coatModel.notes;
  }
}
