export const notNullOrEmpty = (data: string): boolean =>
  data && data.trim().length > 0;

// using regex from https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
export function isGuid(value: string): boolean {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(value);
}

export function uniqueFilter(value, index, self) {
  return self.indexOf(value) === index;
}
