import { Observable, Subject, defer } from 'rxjs';
import { finalize } from 'rxjs/operators';

export const prepare = <T>(callback: () => void) => {
  return (source: Observable<T>): Observable<T> =>
    defer(() => {
      callback();
      return source;
    });
};

export const indicate = <T>(indicator: Subject<boolean>) => {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      prepare(() => indicator.next(true)),
      finalize(() => indicator.next(false))
    );
};

export const indicateWithCounter = <T>(indicator: Subject<boolean>, counter: Counter) => {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      prepare(() => {
        counter.increase();
        indicator.next(true);
      }),
      finalize(() => {
        if(counter.decrease() <= 0 ) {
          indicator.next(false);
        }
      })
    );
};

export class Counter {
  private counter = 0;

  increase(): number {
    this.counter++;
    return this.counter;
  }

  decrease(): number {
    this.counter--;

    if(this.counter < 0) {
      this.counter = 0;
    } 

    return this.counter;
  }

  getValue(): number {
    return this.counter;
  }
}
