import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import {
  EsraWeldingAPIClient,
  ICmidUser,
} from 'src/app/shared/models/autogenerated-welding';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-welding-approver',
  templateUrl: './edit-welding-approver.component.html',
  styleUrls: ['./edit-welding-approver.component.scss'],
})
export class EditWeldingApproverComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EditWeldingApproverComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private esraWeldingApiClient: EsraWeldingAPIClient,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.approvers = this.data.approvers;
    this.revisionId = this.data.revisionId;
  }

  @Input()
  revisionId: number;

  @Input()
  approvers: any[] = [];

  @Output()
  approver = new EventEmitter<ICmidUser>();

  @Output()
  indicator$ = new BehaviorSubject<boolean>(false);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  approverOptions$: Observable<ICmidUser[]> =
  this.getWeldingRevisionApprovers().pipe(
    indicate(this.loading$),
    first());

  revisionApprover: ICmidUser;

  addApproverButtonEnabled = false;
  approverListEnabled = true;

  onDelete(approverOid) {
    this.approvers = this.approvers.filter(x => x["approverOid"] != approverOid);
  }

  getWeldingRevisionApprovers(): Observable<ICmidUser[]> {
    return this.esraWeldingApiClient.getWeldingRevisionApprovers().pipe(
      indicate(this.indicator$),
      first()
    );
  }

  onApproverSelect(event) {
    const selectedApprover = event.value;
    if (!this.approvers.find((x) => x.approverOid == selectedApprover.objectId)) {
      selectedApprover.approverOid = selectedApprover.objectId
      this.approvers.push({
        "id": 0, 
        "approverOid": selectedApprover.approverOid, 
        "displayName":selectedApprover.displayName,
        "status":""
        });
    }
  }

  save()
  {
    const idList= this.approvers.map(x=> x.approverOid);
    this.esraWeldingApiClient.updateApprovers(this.revisionId, idList)
      .pipe(indicate(this.loading$),
          first())
      .subscribe({next: () => this.dialogRef.close(),
      error: (error) => this.snackBarService.showSnackBar(true, error.response, 'mat-snack-bar-error')
      });
  }

  cancelAction() {
    this.dialogRef.close();
  }

}
