import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  HeaderComponent,
  MenuComponent,
  FooterComponent,
  TabbedComponent,
  DropdownComponent,
  CardSliderComponent,
  SliderComponent,
  DrawerComponent,
  NpSetLayoutDirective,
  NpSelectDirective,
  NpTableDirective,
  AlertComponent,
} from '@cvx/nextpage';

const nextpageComponents = [
  HeaderComponent,
  MenuComponent,
  FooterComponent,
  TabbedComponent,
  DropdownComponent,
  CardSliderComponent,
  SliderComponent,
  DrawerComponent,
  NpSetLayoutDirective,
  NpSelectDirective,
  NpTableDirective,
  AlertComponent,
];

@NgModule({
    declarations: [nextpageComponents],
    imports: [CommonModule],
    exports: [nextpageComponents]
})
export class NextpageModule {}
