import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import {
  RevisionCommentStatus,
  RevisionCommentViewModel,
} from '../../../shared/models/autogenerated-coating';
import { RevisionCommentStatusPipe } from '../../pipes/revision-comment-status.pipe';
import { RevisionCommentUserViewModel } from '../comments-thread/comment-thread.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  constructor(private userService: UserService) {}

  @Input()
  comment: RevisionCommentUserViewModel;

  @Input() hasRevisionPermission: boolean;
  @Input() isPublishedRevision: boolean;

  @Input() isJustUpdatedComment: boolean;

  @Output('cancelParentComment') cancelParentComment: EventEmitter<any> =
    new EventEmitter();
  @Output('editParentComment') editParentComment: EventEmitter<any> =
    new EventEmitter();
  @Output('saveParentComment') public saveParentComment = new EventEmitter<{
    id: number;
    comment: RevisionCommentViewModel;
  }>();
  @Output('resetUpdatedComment')
  public resetUpdatedComment: EventEmitter<number> = new EventEmitter();
  @Output('deleteParentComment') deleteParentComment: EventEmitter<any> =
    new EventEmitter();
  @Output('addParentReply') addParentReply: EventEmitter<any> =
    new EventEmitter();
  @Output('cancelParentReply') public cancelParentReply = new EventEmitter<{
    commentId: number;
    replyId: number;
  }>();
  @Output('editParentReply') public editParentReply = new EventEmitter<{
    commentId: number;
    replyId: number;
  }>();
  @Output('saveParentReply') public saveParentReply = new EventEmitter<{
    replyId: number;
    reply: RevisionCommentViewModel;
  }>();
  @Output('deleteParentReply') public deleteParentReply = new EventEmitter<{
    commentId: number;
    replyId: number;
  }>();

  readonly currentUserProfile$ = this.userService.currentUserProfile$;

  public commentText: string;
  public replyText: string;

  public revisionCommentStatus = Object.keys(RevisionCommentStatus).filter(
    (k) => !isNaN(Number(k))
  );

  ngOnInit() {
    this.commentText = this.comment.comment;
  }
  getCommentStatusText(commentStatus: string): string {
    const revisionCommentStatusPipe = new RevisionCommentStatusPipe();
    return String(revisionCommentStatusPipe.transform(Number(commentStatus)));
  }

  getCommentStatusValue(commentStatus: string): number {
    return Number(commentStatus);
  }

  updateRevisionCommentSelection() {
    this.saveParentComment.emit({ id: this.comment.id, comment: this.comment });
    this.resetUpdatedComment.emit(-1);
  }

  cancelComment() {
    this.cancelParentComment.emit(this.comment.id);
  }

  editComment() {
    this.editParentComment.emit(this.comment.id);
  }

  saveComment() {
    if (this.commentText.trim() != '') {
      this.comment.comment = this.commentText;
      this.saveParentComment.emit({
        id: this.comment.id,
        comment: this.comment,
      });
      this.resetUpdatedComment.emit(-1);
    } else {
      alert('Please add a comment');
    }
  }

  deleteComment() {
    if (
      confirm(
        `Are you sure you want to remove the comment?\n\n${this.comment.comment}`
      )
    ) {
      this.deleteParentComment.emit(this.comment.id);
    }
  }

  checkCommentHighlight(): string {
    if (this.isJustUpdatedComment) {
      setTimeout(() => {
        this.isJustUpdatedComment = false;
        this.resetUpdatedComment.emit(-1);
      }, 10000);

      return 'highlighted';
    }

    return '';
  }

  addReply() {
    this.replyText = '';
    this.addParentReply.emit(this.comment.id);
  }

  cancelReply(replyId: number) {
    this.cancelParentReply.emit({ commentId: this.comment.id, replyId });
  }

  editReply(replyId: number) {
    this.replyText = this.comment.replies.find(
      (reply) => reply.id === replyId
    )?.comment;
    this.editParentReply.emit({ commentId: this.comment.id, replyId });
  }

  saveReply(replyId: number) {
    if (this.replyText.trim() != '') {
      const reply = this.comment.replies.find((r) => r.isAdd || r.isEdit);
      reply.comment = this.replyText;
      this.saveParentReply.emit({ replyId, reply });
    } else {
      alert('Please add a reply');
    }
  }

  deleteReply(replyId: number, replyComment: string) {
    if (
      confirm(
        `Are you sure you want to remove the reply\n\n${replyComment}\n\nfrom the comment?\n\n${this.comment.comment}`
      )
    ) {
      this.deleteParentReply.emit({ commentId: this.comment.id, replyId });
    }
  }
}
