import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'PsigToKpa',
})
export class PsigToKpaPipe implements PipeTransform {
  transform(value: number): string {
    const convConst = 6.8947572932;
    const kpa = Math.round((value * convConst)/5)*5;
    return kpa.toFixed(0);
  }
}