// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-or-archive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.create-new-revision {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.create-new-revision .mat-mdc-form-field {
  width: 100%;
}

.archive-revision {
  margin-left: 2%;
}

.select-approver {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.send-for-approval-container {
  display: flex;
  flex-direction: row;
  gap: 60px;
  margin-top: 3%;
  margin-bottom: 3%;
  justify-content: center;
}

.scrollable-content {
  height: 54%;
  width: 72%;
  position: fixed;
}
.scrollable-content h4 {
  text-align: left;
}

#selection-wrapper {
  width: 80%;
}

#selection-container {
  position: fixed;
}

#button-container {
  float: right;
  width: 40%;
}

#archive-button {
  margin: auto;
}

#archive-file-para {
  text-align: center;
}

.loading-progress-bar {
  margin: 2%;
}

.esra-select {
  margin-left: 15%;
  width: 100%;
  padding: 2%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
