// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  margin: 2%;
}

.top-container {
  margin-bottom: 2%;
  height: 20%;
  display: flex;
  justify-content: space-between;
}
.top-container #title {
  font-size: xx-large;
  font-weight: bold;
}

.middle-container {
  height: 60%;
  overflow: auto;
}

.lower-container {
  height: 20%;
  margin-top: 20px;
}
.lower-container button {
  width: 40%;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.save-button {
  float: right;
}

.cancel-button {
  float: left;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
