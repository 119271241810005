import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { map, tap, first } from 'rxjs/operators';
import { Subscription } from 'rxjs/internal/Subscription';
import { BehaviorSubject, Observable, of, forkJoin } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TechnologyEnum } from 'src/app/shared/models/technology-enum';
import { Counter, indicate, indicateWithCounter } from 'src/app/operators';
import { WeldingProcedureViewModel } from '../../../shared/models/autogenerated-welding';
import { EsraWeldingAPIClient } from 'src/app/shared/models/autogenerated-welding';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  CoatingSystemSolutionViewModel,
  EsraCoatingsAPIClient,
} from 'src/app/shared/models/autogenerated-coating';
import { ActivatedRoute } from '@angular/router';
import { WELDING, PIPING } from 'src/app/shared/shared.module';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { DownloadService } from 'src/app/core/services/download.service';
import { EsraPipingAPIClient, WorkspaceViewModel } from 'src/app/shared/models/autogenerated-piping';
import { PipingAndValvesNavigationService } from 'src/app/piping-valves/services/piping-and-valves-navigation.service';

export interface Task {
  name: string;
  completed: boolean;
  subtasks?: Task[];
}

@Component({
  selector: 'app-my-workspace',
  templateUrl: './my-workspace.component.html',
  styleUrls: ['./my-workspace.component.scss'],
})
export class MyWorkspaceComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup') tabGroup;

  constructor(
    private esraCoatingApiClient: EsraCoatingsAPIClient,
    private esraWeldingApiClient: EsraWeldingAPIClient,
    private esraPipingApiClient: EsraPipingAPIClient,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
    private downloadService: DownloadService,
    public pipingAndValvesNavigator: PipingAndValvesNavigationService,
  ) {}

  private readonly subscription = new Subscription();

  coatingSolutions$: Observable<CoatingSystemSolutionViewModel[]>;
  weldingProcedures$: Observable<WeldingProcedureViewModel[]>;
  pipingWorkspace$: Observable<WorkspaceViewModel>;

  downloadCompleted = false;
  showInfo = false;
  isSelectAll = false;

  currentPage = 0;
  lastPage: number;

  totalCount: number;
  pageSize = 10;

  welding: TechnologyEnum = TechnologyEnum.WELDING;

  selectedRecordIds = new Set<any>();
  task: Task = {
    name: 'SelectAll',
    completed: false,
    subtasks: [],
  };

  exportingPdf$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(false);
  tabIndex: number;

  ngOnInit() {
    this.subscription.add(
      this.route.queryParams.subscribe((params) => {
        const technology = params.technology;

        if (technology.toLowerCase() === PIPING.toLowerCase()) {
          this.tabIndex = 2;
        } else if (technology.toLowerCase() === WELDING.toLowerCase())  {
          this.tabIndex = 1;
        } else {
          this.tabIndex = 0;
        }
      })
    );

    this.subscription.add(
      this.getCoatingSystemSolutions()
        .pipe(
          indicate(this.loading$),
          tap((solutions) => (this.coatingSolutions$ = of(solutions)))
        )
        .subscribe()
    );

    this.subscription.add(
      this.getWeldingProcedures()
        .pipe(
          indicate(this.loading$),
          tap((procedures) => (this.weldingProcedures$ = of(procedures)))
        )
        .subscribe()
    );

    this.subscription.add(
      this.getWorkspaceViewModel()
        .pipe(
          indicate(this.loading$),
          tap((workSpace) => (this.pipingWorkspace$ = of(workSpace)))
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getCoatingSystemSolutions(): Observable<CoatingSystemSolutionViewModel[]> {
    return this.esraCoatingApiClient.getMyWorkspace();
  }

  getWeldingProcedures(): Observable<WeldingProcedureViewModel[]> {
    return this.esraWeldingApiClient
      .getMyWorkspace()
      .pipe(indicate(this.loading$));
  }

  getWorkspaceViewModel(): Observable<WorkspaceViewModel> {
    return this.esraPipingApiClient
      .getWorkspace()
      .pipe(indicate(this.loading$));
  }

  selectAll(isChecked: boolean) {
    this.isSelectAll = isChecked;
    if (this.task.subtasks == null) return;
    if (
      isChecked &&
      this.tabGroup.selectedIndex == Number(TechnologyEnum.COATINGS)
    ) {
      this.subscription.add(
        this.coatingSolutions$
          .pipe(
            map((solutions) => {
              solutions.forEach((solution) =>
                this.selectedRecordIds.add(solution.solutionId)
              );
            })
          )
          .subscribe()
      );
    } else if (
      isChecked &&
      this.tabGroup.selectedIndex == Number(TechnologyEnum.WELDING)
    ) {
      this.subscription.add(
        this.weldingProcedures$
          .pipe(
            map((weldingProcedures) =>
              weldingProcedures.forEach((weldingProcedure) =>
                this.selectedRecordIds.add(weldingProcedure.weldingProcedureId)
              )
            )
          )
          .subscribe()
      );
    } else if (isChecked && this.tabIndex == 2) {
      this.subscription.add(
        this.pipingWorkspace$.pipe(
          map((pipingWorkspace) =>
            pipingWorkspace.pipingEntries.forEach((pipingEntry) => 
              this.selectedRecordIds.add(pipingEntry.pipeClassId)
            )
          )
        ).subscribe()
      );

      this.subscription.add(
        this.pipingWorkspace$.pipe(
          map((pipingWorkspace) => 
            pipingWorkspace.valveEntries.forEach((valveEntry) => 
              this.selectedRecordIds.add(valveEntry.valveId)
            )
          )
        ).subscribe()
      );
    } else {
      this.selectedRecordIds = new Set<any>();
    }
    this.task.subtasks.forEach((t) => (t.completed = isChecked));
  }

  isChecked(recordId: string): boolean {
    return this.selectedRecordIds.has(recordId);
  }

  onCheckboxChange(event: MatCheckboxChange, recordId: any) {
    if (event.checked) {
      this.selectedRecordIds.add(recordId);
    } else {
      this.selectedRecordIds.delete(recordId);
    }
  }

  hasItemsSelected() {
    return this.selectedRecordIds.size <= 0;
  }

  showInfoPanel() {
    this.showInfo = true;
    setTimeout(() => this.showInfo, 5500);
  }

  removeSelectedFromMyWorkspace() {
    if (this.selectedRecordIds.size === 0)
      this.snackBarService.showSnackBar(true, 'No records selected to remove');
    else if (this.tabGroup.selectedIndex == Number(TechnologyEnum.COATINGS)) {
      this.subscription.add(
        this.esraCoatingApiClient
          .removeCoatingSolutionsFromWorkspace(
            Array.from(this.selectedRecordIds)
          )
          .pipe(indicate(this.loading$))
          .subscribe(() => {
            this.selectedRecordIds.clear();
            this.isSelectAll = false;
            this.coatingSolutions$ = this.getCoatingSystemSolutions();
            this.snackBarService.showSnackBar(
              true,
              'Removed coating solution(s) from workspace.'
            );
          })
      );
    } else if (this.tabGroup.selectedIndex == Number(TechnologyEnum.WELDING)) {
      this.subscription.add(
        this.esraWeldingApiClient
          .removeWeldingProceduresFromWorkspace(
            Array.from(this.selectedRecordIds)
          )
          .subscribe(() => {
            this.selectedRecordIds.clear();
            this.weldingProcedures$ = this.getWeldingProcedures();
            this.snackBarService.showSnackBar(
              true,
              'Removed record(s) from welding workspace.'
            );
          })
      );
    } else if (this.tabIndex === 2) {
      this.subscription.add(
        this.esraPipingApiClient
          .removePipeClassesFromWorkspace(
            Array.from(this.selectedRecordIds)
          )
          .subscribe(() => {
            this.selectedRecordIds.clear();
            this.pipingWorkspace$ = this.getWorkspaceViewModel();
            this.snackBarService.showSnackBar(
              true,
              'Removed record(s) from piping and valves workspace'
            );
          })
      )
    }
  }

  downloadSelectedWeldingProcedures() {
    const observables: Observable<any>[] = [];

    const selected = Array.from(this.selectedRecordIds);
    const counter = new Counter();

    selected.forEach((wpsId) => {
      observables.push(
        this.weldingProcedures$.pipe(
          map((wps) => wps.filter((x) => x.weldingProcedureId == wpsId)[0]),
          tap((wps) =>
            this.downloadService.downloadFile(
              this.esraWeldingApiClient.getWeldingDocument(wps.filename, undefined).pipe(indicateWithCounter(this.loading$, counter)),
              wps.filename
            )
          )
        )
      );
    });

    forkJoin(observables).pipe(first()).subscribe();
  }

  exportSelected() {
    return this.downloadService.downloadFile(
        this.esraCoatingApiClient
          .getWorkspaceReportForSelectedCoatingSolutions(Array.from(this.selectedRecordIds)).pipe(indicate(this.loading$)), 
            );
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.selectedRecordIds.clear();
    this.isSelectAll = false;
  };

}
