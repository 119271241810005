import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ApproverType,
  CoatingRevisionViewModel,
  RevisionApprovalStatus,
  RevisionApprovalViewModel,
} from 'src/app/shared/models/autogenerated-coating';
import { BehaviorSubject } from 'rxjs';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { SnackBarService } from '../../services/snack-bar.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-edit-approvals',
  templateUrl: './edit-approvals.component.html',
  styleUrls: ['./edit-approvals.component.scss'],
})
export class EditApprovalsComponent implements OnInit {
  @Input()
  isReadOnly = true;
  @Input()
  title: string;
  @Input()
  coatingRevisionViewModel: CoatingRevisionViewModel;

  @Input()
  saveAction: () => void;
  @Input()
  cancelAction: () => void;

  loading$ = new BehaviorSubject<boolean>(false);
  formGroup: UntypedFormGroup;
  approvers: RevisionApprovalViewModel[] = [];

  roles = Object.keys(ApproverType).filter((item) => {
    return !isNaN(Number(item));
  });

  constructor(
    public dialogRef: MatDialogRef<EditApprovalsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    //Initialize 'Input" varaibles in case we are calling as modal
    if (this.data.coatingRevisionViewModel) {
      this.coatingRevisionViewModel = this.data.coatingRevisionViewModel;
      this.title = this.data.title;
      this.saveAction = this.data.saveAction;
      this.cancelAction = this.data.cancelAction;
      if (this.data.isReadOnly) {
        this.isReadOnly = this.data.isReadOnly;
      }
    }

    this.approvers = this.coatingRevisionViewModel.approvals;

    this.formGroup = this._formBuilder.group({
      role: ['', Validators.nullValidator],
      user: ['', Validators.required],
    });
  }

  addRevisionApproval(user: MicrosoftGraph.User): void {
    const role = this.formGroup.value.role;
    if (role === null || role === undefined || role.trim() === '') {
      this.snackBarService.showSnackBar(true, 'Please select role first');
      this.formGroup.get('role').markAsTouched({ onlySelf: true });
      return;
    }

    if (this.userExistsWithinGivenRole(this.approvers, user.id, role) !== -1) {
      this.snackBarService.showSnackBar(
        true,
        `${user.displayName} is already added to this revision`, 
        'mat-snack-bar-error'
      );
    } else {
      const member = new RevisionApprovalViewModel({
        name: user.displayName,
        approverOid: user.id,
        status: RevisionApprovalStatus._0,
        approverType: role,
        coatingRevisionId: this.coatingRevisionViewModel.id,
      });

      this.approvers = [...this.approvers, member];
      this.formGroup.reset();
    }
  }

  private userExistsWithinGivenRole(
    usersList: RevisionApprovalViewModel[],
    userOid: string,
    role: ApproverType
  ): number {
    const index = usersList.findIndex(
      (approver) => approver.approverOid === userOid
    );

    if (
      index !== -1 &&
      ApproverType[usersList[index].approverType] === ApproverType[role]
    ) {
      return index;
    } else {
      return -1;
    }
  }

  removeMember(member: RevisionApprovalViewModel): void {
    this.approvers = this.approvers.filter(
      (user) =>
        user.approverOid !== member.approverOid ||
        (user.approverOid === member.approverOid &&
          user.approverType !== member.approverType)
    );
  }

  /**
   * @param {Array.<ApproverType, RevisionApprovalViewModel, FormControl>} memberTuple description
   * @param {number} memberTuple[0] description
   * @param {number} memberTuple[1] modified object
   * @param {number} memberTuple[2] FormControl object that controls select-option dropdown for provided RevisionApprovalViewModel
   */
  updateMember(
    memberTuple: [ApproverType, RevisionApprovalViewModel, UntypedFormControl]
  ) {
    const selectedApproverType = memberTuple[1].approverType;
    const memberWithGivenRoleAlreadyExists = this.approvers.filter(
      (approver) =>
        approver.approverOid === memberTuple[1].approverOid &&
        ApproverType[approver.approverType] ===
          ApproverType[selectedApproverType] &&
        approver.id !== memberTuple[1].id
    );

    if (memberWithGivenRoleAlreadyExists.length > 0) {
      this.snackBarService.showSnackBar(
        true,
        `User  ${memberTuple[1].name} already exists in this revision with role: ${memberTuple[1].approverType}`,
        'mat-snack-bar-error'
      );

      // If user already exists with selected role we have to "reset role" to previous value
      if (
        ApproverType[selectedApproverType] === ApproverType[ApproverType._0]
      ) {
        memberTuple[2].setValue(ApproverType._1.toString());
      } else {
        memberTuple[2].setValue(ApproverType._0.toString());
      }
    }
  }

  checkApproversForValidity(): boolean {
    //if author is also the sole approver
    if(this.approvers.length == 1 && this.approvers[0].approverOid == this.userService.getCurrentUser().localAccountId){
      return false;
    }
    else {
      return true;
    }
  }

  save(): void {
    if(this.checkApproversForValidity()){
      this.coatingRevisionViewModel.approvals = this.approvers;
      this.saveAction();
    }
    else{
      this.snackBarService.showSnackBar(true, 'you must add an approver other than yourself');
    }
  }
}
