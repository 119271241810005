import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnChanges
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'app-mat-paginator-go-to',
    templateUrl: './mat-paginator-go-to.component.html',
    styleUrls: ["mat-paginator-go-to.component.scss"]
})
export class MatPaginatorGoToComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @Input() disabled = false;
    @Input() hidePageSize = false;
    @Input() pageSizeOptions: number[];
    @Input() showFirstLastButtons = false;
    @Input() pageIndex: number;
    @Input() length: number;
    @Input() pageSize: number;
    @Output() page = new EventEmitter<PageEvent>();

    goTo: number;
    pageNumbers: number[];

    ngOnInit() {
        this.updateGoto();
    } 

    ngOnChanges(): void {
        this.updateGoto();
    }

    updateGoto() {
        this.goTo = (this.pageIndex || 0) + 1;
        this.pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.length / this.pageSize); i++) {
            this.pageNumbers.push(i);
        }
    }

    paginationChange(pageEvt: PageEvent) {
        this.length = pageEvt.length;
        this.pageIndex = pageEvt.pageIndex;
        this.pageSize = pageEvt.pageSize;
        this.updateGoto();
        this.emitPageEvent(pageEvt);
    }

    goToChange() {
        this.paginator.pageIndex = this.goTo - 1;
        const event: PageEvent = {
            length: this.paginator.length,
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
        };
        this.paginator.page.next(event);
        this.emitPageEvent(event);
    }

    emitPageEvent(pageEvent: PageEvent) {
        this.page.next(pageEvent);
    }

    firstPage() {
        this.paginator.firstPage;
        this.goTo = 1;
    }

    lastPage() {
        this.pageIndex = Math.ceil(this.length / this.pageSize) -1;
    }
}