import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeCharacters',
})
export class RemoveCharactersPipe implements PipeTransform {
  transform(inputString: string, toRemove: string): string {
    return inputString.replace(toRemove, '');
  }
}
