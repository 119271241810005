// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  padding: 2%;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 3%;
}
.action-buttons .btn-wrapper {
  margin-left: 2%;
}

.input-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 5px 15px;
}

.progress-bar-container {
  min-height: 6px;
  margin: 2% 3%;
}
.progress-bar-container .searching-progress-bar {
  height: 5px;
}

.row-conatiner {
  display: flex;
  justify-content: space-around;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
