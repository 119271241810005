import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { indicateWithCounter, Counter } from 'src/app/operators';
import { EsraCoatingsAPIClient, CoatViewModel } from 'src/app/shared/models/autogenerated-coating';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-coat-dialog',
  templateUrl: './add-coat-dialog.component.html',
  styleUrls: ['./add-coat-dialog.component.scss']
})
export class AddCoatDialogComponent implements OnInit {

  formGroup: UntypedFormGroup;
  coatNames$: Observable<string[]>;
  genericClassifications$: Observable<string[]>;
  loadingCoat$ = new BehaviorSubject<boolean>(false);
  coatingSystemGuid: string;
  coatingSystemId: string;

  constructor(    
    public dialogRef: MatDialogRef<AddCoatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder,
    private _esraCoatingClient: EsraCoatingsAPIClient) { }

  ngOnInit(): void {
    if (this.data) {
      this.coatingSystemGuid = this.data.coatingSystemGuid;
      console.log(this.data);
    }
    let counter = new Counter();
    this.formGroup = this._formBuilder.group({
      coatName: ['', Validators.required],
      genericClassification: ['', Validators.nullValidator],
      dft: ['', Validators.nullValidator],
      notes: ['', Validators.nullValidator],
    });
    this.coatNames$ = this._esraCoatingClient.getCoatPropertyValues('coatNameId')
       .pipe(indicateWithCounter(this.loadingCoat$, counter),
        first());
    this.genericClassifications$ = this._esraCoatingClient.getCoatPropertyValues('genericClassification')
      .pipe(indicateWithCounter(this.loadingCoat$, counter),
        first());
            
  }
  
  save() {
    const newCoat = new CoatViewModel({
      coatingSystemId: this.coatingSystemGuid,
      coatName: this.formGroup.value.coatName,
      genericClassification: this.formGroup.value.genericClassification,
      dft: this.formGroup.value.dft,
      notes: this.formGroup.value.notes,
    });

    let counter = new Counter();

    this._esraCoatingClient.addCoatToCoatingSystem(newCoat)
      .pipe(indicateWithCounter(this.loadingCoat$, counter),
      first(),
      tap((result) =>  {
        this.dialogRef.close(result);
      }))
      .subscribe();
    
  }

  cancel() {
    this.dialogRef.close();
  }

}
