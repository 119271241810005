// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-flex {
  display: flex;
  justify-content: right;
}

.go-to-container {
  display: flex;
  align-items: baseline;
}
.go-to-container mat-form-field {
  max-width: 80px;
  margin: 6px 4px 0 4px;
  font-size: 12px;
}
.go-to-container .go-to-label {
  margin: 0 4px;
  font-size: 12px;
  font-family: "Gotham", "Arial", sans-serif;
  color: rgba(0, 0, 0, 0.54);
}

.of-pageNumbers-last {
  font-family: "Gotham", "Arial", sans-serif;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
