import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dictionary-delete',
  templateUrl: './dictionary-delete.component.html',
  styleUrls: ['dictionary-delete.component.scss'],
})
export class DictionaryDeleteComponent implements OnInit {
  objectType: string;
  objectName: string;
  faWindowClose = faWindowClose;

  constructor(
    public dialogRef: MatDialogRef<DictionaryDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      objectType: string,
      objectName: string,
      item: object
    }
  ) {}

  ngOnInit(): void {
    this.objectType = this.data.objectType;
    this.objectName = this.data.objectName;
  }

  capitalizeFirstLetter(str: string): string {
    if (str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return '';
    }
  }
}
