// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-family: "Gotham", Arial, sans-serif;
  color: #0066b2;
  height: 100%;
}

.title {
  font-weight: bold;
  font-size: 20pt;
  letter-spacing: 0px;
}

.content {
  font-size: 12pt;
  color: black;
  text-align: center;
}

.buttons-section {
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: space-evenly;
  row-gap: 100px;
}
.buttons-section button {
  margin: 10px 15px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
