import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ReplaySubject,
  BehaviorSubject,
  combineLatest,
  Subscription,
  Observable,
} from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { GraphService } from 'src/app/core/services/graph.service';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import {
  RevisionCommentViewModel,
  RevisionStatus,
} from 'src/app/shared/models/autogenerated-coating';
import { UserService } from 'src/app/core/services/user.service';
import {
  CommentThreadService,
  RevisionApprovalUserViewModel,
  RevisionCommentUserViewModel,
} from './comment-thread.service';
import { AccountInfo } from '@azure/msal-browser';

@Component({
  selector: 'app-comments-thread',
  templateUrl: './comments-thread.component.html',
  styleUrls: ['./comments-thread.component.scss'],
})
export class CommentsThreadComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  // subjects for displaying loading progress indicators.
  loadingRevision$ = new BehaviorSubject<boolean>(false);

  @Input()
  revisionId: number;

  @Input()
  authorOid: string;

  @Input()
  approvals: RevisionApprovalUserViewModel[];

  @Input()
  revisionComments: RevisionCommentUserViewModel[];

  @Input()
  revisionStatus: RevisionStatus;

  @Input()
  linkedCoatingSystemGuid?: string;

  @Input()
  linkedCoatingProductGuid?: string;

  @Input()
  linkedCoatingSystemSolutionGuid?: string;

  approversFromGraph$ = new ReplaySubject<Map<string, MicrosoftGraph.User>>(1);

  justUpdatedCommentIndex = -1;
  hasRevisionPermission = false;
  isPublishedRevision = false;

  constructor(
    private graphService: GraphService,
    private userService: UserService,
    private commentsService: CommentThreadService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.commentsService
        .getPublishedCommentsSubject()
        .subscribe((newComment) => {
          if (
            !this.revisionComments.find(
              (comment) => comment.id === newComment.id
            )
          ) {
            this.revisionComments.push(newComment);
          }
        })
    );

    if (
      this.linkedCoatingSystemGuid ||
      this.linkedCoatingProductGuid ||
      this.linkedCoatingSystemSolutionGuid
    ) {
      this.filterCommentsToOnlyRelatedOnes();
    }

    this.publishRevisionMembersDetailsFromGraph();
    this.initComments();

    if (
      this.revisionStatus != RevisionStatus._3 &&
      this.authorOid !== null &&
      this.authorOid !== ''
    ) {

      this.userService.currentUserProfile$
        .pipe(filter((currentUser) => this.checkIfUserHasPermissions(currentUser)),
            tap(currentUser => this.hasRevisionPermission = true))
        .subscribe();
    } else {
      this.isPublishedRevision = true;
    }
  }

  private checkIfUserHasPermissions(user: AccountInfo) {
    return user.localAccountId === this.authorOid || 
      (this.approvals && this.approvals.filter((approver) => approver.approverOid === user.localAccountId).length > 0)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /*
   * Method proactively gathers data about all users that were active ( approvers/reviewers, comments and replies authors)
   * within given revision to avoid multiple requests to Graph service
   */
  publishRevisionMembersDetailsFromGraph(): void {
    if (!this.approvals || !this.revisionComments){
      return;
    }

    const uniqueUserOids = [
      ...new Set([
        ...this.approvals.map((approval) => approval.approverOid),
        ...this.revisionComments
          .map((comment) => comment.createdByOid)
          .filter((oid) => oid && oid !== ''),
        ...this.revisionComments
          .map((comment) => comment.updatedByOid)
          .filter((oid) => oid && oid !== ''),
      ]),
    ];

    if(!uniqueUserOids.includes(this.authorOid)) {
      uniqueUserOids.push(this.authorOid);
    }

    this.graphService
      .getUsersByOids(uniqueUserOids)
      .pipe(
        first(),
        tap((membersList) =>
          this.approversFromGraph$.next(
            new Map(membersList.map((user) => [user.id, user]))
          )
        )
      )
      .subscribe();
  }

  /**
   * Filter comments only to display releated to specified Coating System, Coating System Solution ort Coating Product
   */
  private filterCommentsToOnlyRelatedOnes() {
    const id = this.getFirstNotEmptyGuid();
    
    this.revisionComments = this.revisionComments.filter(
      (comment) =>
        comment.linkedCoatingSystemId === id ||
        comment.linkedCoatingProductId === id ||
        comment.linkedCoatingSystemSolutionId === id
    );
  }

  private getFirstNotEmptyGuid(): string {
    if( this.linkedCoatingSystemGuid) {
      return this.linkedCoatingSystemGuid;
    } else {
      return this.linkedCoatingProductGuid
      ? this.linkedCoatingProductGuid
      : this.linkedCoatingSystemSolutionGuid;
    }
  }

  getApprovals(): Observable<RevisionApprovalUserViewModel[]> {
    return combineLatest([this.approversFromGraph$]).pipe(
      map(([users]) => {
        const approvers = new Array<RevisionApprovalUserViewModel>();
        this.approvals.forEach((approval) =>
          approvers.push(approval)
        );

        approvers.forEach((approver) => {
          if (users.has(approver.approverOid)) {
            approver.surname = users.get(approver.approverOid).surname;
            approver.givenName = users.get(approver.approverOid).givenName;
          }
        });

        return approvers;
      })
    );
  }

  initComments(): void {
    combineLatest([this.approversFromGraph$])
      .pipe(
        first(),
        map(([users]) => {
          this.revisionComments.forEach((comment) => {
            if (users.has(comment.createdByOid)) {
              comment.authorName = `${
                users.get(comment.createdByOid).givenName
              } ${users.get(comment.createdByOid).surname}`;
            }

            if (comment.authorName) {
              comment.initials =
                comment.authorName.split(' ')[0].charAt(0) +
                ' ' +
                comment.authorName.split(' ')[1].charAt(0);
            }
            comment.isAdd = false;
            comment.isEdit = false;

            comment.replies.forEach((reply) => {
              if (users.has(reply.createdByOid)) {
                reply.authorName = `${
                  users.get(reply.createdByOid).givenName
                } ${users.get(reply.createdByOid).surname}`;
              }

              if (reply.authorName) {
                reply.initials =
                  reply.authorName.split(' ')[0].charAt(0) +
                  ' ' +
                  reply.authorName.split(' ')[1].charAt(0);
              }
              reply.isAdd = false;
              reply.isEdit = false;
            });
          });
        })
      )
      .subscribe();
  }

  // comment Section
  addComment() {
    if (this.anyUnsavedCommentsorReply() === true) {
      alert('Unsaved Comments or Reply');
    } else {
      this.addCommentOperation();
      this.resetUpdatedComment();
    }
  }

  private addCommentOperation(): void {
    combineLatest([
      this.commentsService.addNewComment(
        this.userService.getCurrentUser().localAccountId
      ),
    ]).subscribe(([comment]) => {
      if (this.linkedCoatingSystemGuid) {
        comment.linkedCoatingSystemId = this.linkedCoatingSystemGuid;
      }

      if (this.linkedCoatingProductGuid) {
        comment.linkedCoatingProductId = this.linkedCoatingProductGuid;
      }

      if (this.linkedCoatingSystemSolutionGuid) {
        comment.linkedCoatingSystemSolutionId =
          this.linkedCoatingSystemSolutionGuid;
      }

      this.revisionComments.push(comment);
    });
  }

  editParentComment(id: number) {
    if (this.anyUnsavedCommentsorReply() === true) {
      alert('Unsaved Comments or Reply');
    } else {
      this.editParentCommentOperation(id);
      this.resetUpdatedComment();
    }
  }

  private editParentCommentOperation(id: number): void {
    const comment = this.revisionComments.find((c) => c.id === id);
    comment.isEdit = true;
  }

  saveParentComment(data: any) {
    let comment: Observable<RevisionCommentViewModel>;
    if (data.id === 0) {
      comment = this.commentsService.addParentCommentReplyOperation(
        this.revisionId,
        data.comment
      );
    } else {
      comment = this.commentsService.updateParentCommentReplyOperation(
        this.revisionId,
        data.comment
      );
    }

    comment.subscribe((com) => {
      this.updateParentComments(com, data.id);
    });
  }

  private updateParentComments(
    comment: RevisionCommentViewModel,
    id: number
  ): void {
    const changedComment = this.revisionComments.find((c) => c.id === id);

    if (id === 0) {
      changedComment.id = comment.id;

      this.resetUpdatedComment(this.revisionComments.length - 1);
    } else {
      this.resetUpdatedComment(this.revisionComments.indexOf(changedComment));
    }

    changedComment.isAdd = false;
    changedComment.isEdit = false;
  }

  private anyUnsavedCommentsorReply(): boolean {
    const anyUnsavedComment = this.revisionComments.some(
      (c) => c.isAdd === true || c.isEdit === true
    );

    if (anyUnsavedComment === true) {
      return anyUnsavedComment;
    } else {
      let anyUnsavedReply = false;
      this.revisionComments.forEach((comment) => {
        const unSavedReplies = comment.replies.some(
          (c) => c.isAdd === true || c.isEdit === true
        );
        if (unSavedReplies === true) {
          anyUnsavedReply = true;
        }
      });
      return anyUnsavedReply;
    }
  }

  resetUpdatedComment(index: number = -1): void {
    this.justUpdatedCommentIndex = index;
  }

  cancelParentComment(id: number) {
    this.cancelParentCommentOperation(id);
  }

  private cancelParentCommentOperation(id: number): void {
    const cancelledComment = this.revisionComments.find((c) => c.id === id);

    if (id === 0) {
      this.revisionComments.pop();
    }

    cancelledComment.isAdd = false;
    cancelledComment.isEdit = false;
  }

  isUpdated(index): boolean {
    if (this.justUpdatedCommentIndex === index) {
      return true;
    }
    return false;
  }

  addParentReply(commentId: number) {
    if (this.anyUnsavedCommentsorReply() === true) {
      alert('Unsaved Comments or Reply');
    } else {
      this.addParentReplyOperation(commentId);
      this.resetUpdatedComment();
    }
  }

  private addParentReplyOperation(commentId: number): void {
    combineLatest([this.approversFromGraph$])
      .pipe(
        map(([users]) => {
          const comment = this.revisionComments.find((c) => c.id === commentId);
          const reply = this.commentsService.addNewReply(
            users.get(this.userService.getCurrentUser().localAccountId),
            comment.id
          );
          comment.replies.push(reply);
        })
      )
      .subscribe();
  }

  cancelParentReply(data: any) {
    this.cancelParentReplyOperation(data.commentId, data.replyId);
  }

  private cancelParentReplyOperation(commentId: number, replyId: number): void {
    const comment = this.revisionComments.find((c) => c.id === commentId);
    const cancelledReply = comment.replies.find((c) => c.id === replyId);

    if (replyId === 0) {
      comment.replies.pop();
    }

    cancelledReply.isAdd = false;
    cancelledReply.isEdit = false;
  }

  editParentReply(data: any) {
    if (this.anyUnsavedCommentsorReply() === true) {
      alert('Unsaved Comments or Reply');
    } else {
      this.editParentReplyOperation(data.commentId, data.replyId);
      this.resetUpdatedComment();
    }
  }

  private editParentReplyOperation(commentId: number, replyId: number): void {
    let comment = this.revisionComments.find((c) => c.id === commentId);
    let reply = comment.replies.find((r) => r.id === replyId);
    reply.isEdit = true;
  }

  saveParentReply(data: any) {
    let reply: Observable<RevisionCommentViewModel>;
    if (data.replyId === 0) {
      reply = this.commentsService.addParentCommentReplyOperation(
        this.revisionId,
        data.reply
      );
    } else {
      reply = this.commentsService.updateParentCommentReplyOperation(
        this.revisionId,
        data.reply
      );
    }

    reply.subscribe((com) => {
      this.updateParentReplies(com, data.replyId);
    });
  }

  private updateParentReplies(
    reply: RevisionCommentViewModel,
    replyId: number
  ): void {
    const comment = this.revisionComments.find(
      (c) => c.id === reply.parentCommentId
    );
    const changedReply = comment.replies.find((r) => r.id === replyId);

    if (replyId === 0) {
      changedReply.id = reply.id;
    }

    changedReply.isAdd = false;
    changedReply.isEdit = false;
  }

  deleteParentComment(commentId: number) {
    if (this.anyUnsavedCommentsorReply() === true) {
      alert('Unsaved Comments or Reply');
    } else {
      const comment: Observable<RevisionCommentViewModel> =
        this.commentsService.deleteParentCommentReplyOperation(
          this.revisionId,
          commentId
        );

      comment.subscribe((com) => {
        this.updateAfterdeleteCommentOperation(commentId);
      });
    }
  }

  private updateAfterdeleteCommentOperation(commentId: number): void {
    const deletedCommentIndex = this.revisionComments.findIndex(
      (c) => c.id === commentId
    );

    if (deletedCommentIndex > -1) {
      this.revisionComments.splice(deletedCommentIndex, 1);
    }
  }

  deleteParentReply(data: any) {
    if (this.anyUnsavedCommentsorReply() === true) {
      alert('Unsaved Comments or Reply');
    } else {
      const comment: Observable<RevisionCommentViewModel> =
        this.commentsService.deleteParentCommentReplyOperation(
          this.revisionId,
          data.replyId
        );

      comment.subscribe((com) => {
        this.updateAfterdeleteReplyOperation(data.commentId, data.replyId);
      });
    }
  }

  private updateAfterdeleteReplyOperation(
    commentId: number,
    replyId: number
  ): void {
    const deletedComment = this.revisionComments.find(
      (c) => c.id === commentId
    );

    const deletedReplyIndex = deletedComment.replies.findIndex(
      (r) => r.id === replyId
    );
    if (deletedReplyIndex > -1) {
      deletedComment.replies.splice(deletedReplyIndex, 1);
    }
  }

  private getUserDisplayName(graphUser: MicrosoftGraph.User): string {
    return graphUser.givenName + ' ' + graphUser.surname;
  }
}
