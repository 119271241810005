import { CanActivateFn } from "@angular/router";
import { map, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "../services/user.service";
import { inject } from "@angular/core";
import { SnackBarService } from "src/app/shared/services/snack-bar.service";

export function productionGuard(): CanActivateFn {
    return () => !environment.production;
}

export function userInRole(role: string): CanActivateFn {
    return () => {
        const userService: UserService = inject(UserService);
        const snackBarService: SnackBarService = inject(SnackBarService);
        return userService.roles$.pipe(
            map(userRoles => userRoles.filter(userRole => role === userRole).length > 0),
            tap(isAuthorized => {if(!isAuthorized)snackBarService.showError('You are not authorized to access this resource')}),
        )
    }
}
