// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: xx-large;
  font-weight: bold;
}

.middle-container {
  height: 60%;
  overflow: auto;
}
.middle-container .validation-message {
  color: #97002e;
}

.lower-container {
  height: 20%;
  margin-top: 20px;
}
.lower-container button {
  width: 40%;
}

.cancel-button {
  float: left;
}

.save-button {
  float: right;
}

.input-container {
  display: flex;
  flex-direction: column;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
