import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  EsraWeldingAPIClient,
  DocumentStatus,
  WeldingProcedureViewModel,
} from 'src/app/shared/models/autogenerated-welding';
import { TechnologyEnum } from 'src/app/shared/models/technology-enum';
import { ProductPanelHelper } from '../product-panel-helper';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { indicate } from 'src/app/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-welding-product-panel',
  templateUrl: './welding-product-panel.component.html',
  styleUrls: ['./welding-product-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WeldingProductPanelComponent implements OnInit {
  @Input()
  product: WeldingProcedureViewModel;
  @Input()
  technology: TechnologyEnum;
  @Input()
  addWorkspaceShow: boolean;
  @Input()
  documentStatus: DocumentStatus;

  loadingIndicator$ = new BehaviorSubject<boolean>(false);

  constructor(
    private pdfPreviewDialog: MatDialog,
    private esraWeldingApiClient: EsraWeldingAPIClient,
    private snackBarService: SnackBarService,
    private productPanelHelper: ProductPanelHelper
  ) {}

  ngOnInit(): void {
    this.product.filename = this.productPanelHelper.strReplaceHighlightHtml(
      this.product.filename
    );

    this.product.baseMetalPNo1 =
      this.productPanelHelper.strReplaceHighlightHtml(
        this.product.baseMetalPNo1
      );

    this.product.baseMetalPNo2 =
      this.productPanelHelper.strReplaceHighlightHtml(
        this.product.baseMetalPNo2
      );

    if (this.product.thicknessMinInch != null) {
      this.product.thicknessMinInch =
        this.productPanelHelper.strReplaceHighlightHtml(
          this.product.thicknessMinInch
        );
    }

    if (this.product.thicknessMaxInch != null) {
      this.product.thicknessMaxInch =
        this.productPanelHelper.strReplaceHighlightHtml(
          this.product.thicknessMaxInch
        );
    }

    if (this.product.electrodes != null) {
      this.product.electrodes = this.product.electrodes
        .filter((electrode) => electrode != null)
        .map((electrode) =>
          this.productPanelHelper.strReplaceHighlightHtml(electrode)
        );
    }

    if (this.product.weldProcess != null) {
      this.product.weldProcess = this.product.weldProcess
        .filter((weldProcess) => weldProcess != null)
        .map((weldProcess) =>
          this.productPanelHelper.strReplaceHighlightHtml(weldProcess)
        );
    }
  }

  OpenDetail(fileName: string) {
    //we need to remove all potential <em> tags before trying to retrieve the file
    let cleanedFileName = ProductPanelHelper.RemoveHTML(fileName);
    this.esraWeldingApiClient
      .getWeldingDocument(cleanedFileName, this.documentStatus)
      .pipe(
        indicate(this.loadingIndicator$)
      )
      .subscribe({
        next: (response) => {
          window.open(URL.createObjectURL(response.data), '_blank');
        },
        error: (err) => {
          console.log("error: " + err);
          this.snackBarService.showError("PDF Document Not Found");
        }
      })
  }


}
