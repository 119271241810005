import { Pipe, PipeTransform } from '@angular/core';
import { RevisionStatus } from 'src/app/shared/models/autogenerated-piping';

@Pipe({
  name: 'pipingRevisionStatus',
})
export class PipingRevisionStatusPipe implements PipeTransform {
  transform(value: RevisionStatus): string {
    switch (value) {
      case RevisionStatus._0: {
        return 'Draft';
      }
      case RevisionStatus._1: {
        return 'Under Review';
      }
      case RevisionStatus._2: {
        return 'Ready For Publish';
      }
      case RevisionStatus._3: {
        return 'Published';
      }
      default: {
        return '';
      }
    }
  }
}