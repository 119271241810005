import { Component, Inject, OnInit } from '@angular/core';
import { PressureTemperatureCellViewModel, PressureTemperatureTableViewModel } from 'src/app/shared/models/autogenerated-piping';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { uniqueFilter } from 'src/app/shared/utils';
import { PsigToKpaPipe } from 'src/app/shared/pipes/psig-to-kpa.pipe';
import { FahrenheitToCelsiusPipe } from 'src/app/shared/pipes/fahrenheit-to-celcius.pipe';
import { KpaToPsigPipe } from 'src/app/shared/pipes/kpa-to-psig.pipe';
import { CelsiusToFahrenheitPipe } from 'src/app/shared/pipes/celcius-to-fahrenheit.pipe';

@Component({
  selector: 'app-pressure-temp-preview',
  templateUrl: './pressure-temp-preview.component.html',
  styleUrls: ['./pressure-temp-preview.component.scss'],
  providers: [
    PsigToKpaPipe,
    FahrenheitToCelsiusPipe,
    KpaToPsigPipe,
    CelsiusToFahrenheitPipe,
  ],
})
export class PressureTempPreviewComponent implements OnInit {
  constructor(
    private psigToKpaPipe: PsigToKpaPipe,
    private farenheitToCelciusPipe: FahrenheitToCelsiusPipe,
    private kpaToPsigPipe: KpaToPsigPipe,
    private celciusToFahrenheitPipe: CelsiusToFahrenheitPipe,
    @Inject(MAT_DIALOG_DATA)
    public presTempTable: PressureTemperatureTableViewModel
  ) {}

  faWindowClose = faWindowClose;

  defaultPipeClassPressureUnits = 'psig';
  defaultPipeClassTemperatureUnits = 'F';
  secondPipeClassPressureUnits: string = undefined;
  secondPipeClassTemperatureUnits: string = undefined;
  isMetric = false;

  readOnlyDatasource: Array<{temperature1: string, pressure1:string, temperature2:string, pressure2: string}>;

  ngOnInit(): void {
    this.sortTableCellsByTemp();
    this.setupUnits()
    this.readOnlyDatasource = this.initializeReadOnlyTable(this.presTempTable.cells);
  }

  /**
   * This function sorts all cells in each pressureTemperatureTable in ascending order
   */
  private sortTableCellsByTemp(): void {
    this.presTempTable.cells.sort((a, b) => {
      if (a.temperature > b.temperature) {
        return 1;
      }
      if (a.temperature < b.temperature) {
        return -1;
      }
      return 0;
    });
  }

  private setupUnits() {
    const pressureUnits = this.presTempTable.cells.map(cell => cell.pressureUnit).filter(uniqueFilter);
    //Legacy check - We check if we have values for more than one unit pair (one pair will be always psig/F)
    if (pressureUnits.length > 1) {
      pressureUnits.splice(pressureUnits.indexOf(this.defaultPipeClassPressureUnits), 1);
      if(pressureUnits.length != 0) {
        this.secondPipeClassPressureUnits = pressureUnits[0];
        const temperatureUnits = this.presTempTable.cells.map(cell => cell.temperatureUnit).filter(uniqueFilter);
        temperatureUnits.splice(pressureUnits.indexOf(this.defaultPipeClassTemperatureUnits), 1);
        this.secondPipeClassTemperatureUnits = temperatureUnits[0];
      }
    } else {
      if (this.defaultPipeClassPressureUnits != pressureUnits[0]) {
        this.isMetric = true;
      }
      this.defaultPipeClassPressureUnits = pressureUnits[0];
      this.defaultPipeClassTemperatureUnits = this.presTempTable.cells.map(cell => cell.temperatureUnit).filter(uniqueFilter)[0];
      this.secondPipeClassPressureUnits = this.isMetric ? 'psig' : 'kPag';
      this.secondPipeClassTemperatureUnits = this.isMetric ? 'F' : 'C';
    }
  }

  private initializeReadOnlyTable(cells: PressureTemperatureCellViewModel[] ): Array<{temperature1: string, pressure1:string, temperature2:string, pressure2: string}> {
    const ptTablesUnits1 = this.filterOnlySpecificPressureUnits(cells, this.defaultPipeClassPressureUnits);
    let ptTablesUnits2 : PressureTemperatureCellViewModel[]; 
    if( ptTablesUnits1.filter(ptCell => ptCell.pressureUnit == this.secondPipeClassPressureUnits).length > 0) {
      ptTablesUnits2 = this.filterOnlySpecificPressureUnits(cells, this.secondPipeClassPressureUnits);
    } else {
      ptTablesUnits2 = ptTablesUnits1.map(ptCell => {
        const newPtCell = new PressureTemperatureCellViewModel();
        const pp = this.isMetric ? Number(this.kpaToPsigPipe.transform(ptCell.pressure)) : Number(this.psigToKpaPipe.transform(ptCell.pressure));

        newPtCell.pressure = pp
        newPtCell.temperature = this.isMetric ? Number(this.celciusToFahrenheitPipe.transform(ptCell.temperature)) : Number(this.farenheitToCelciusPipe.transform(ptCell.temperature));

        return newPtCell;
      });
    }

    const array = [] 

    if(ptTablesUnits1.length != ptTablesUnits2.length) {
      console.error('Number of values for units: ' + this.defaultPipeClassPressureUnits + ' and ' + this.secondPipeClassPressureUnits + " are diffeent. Should be same. Some results might not be visible")
    }

    for (let i = 0; i< ptTablesUnits1.length; i++) {
      const item = {
        temperature1: [ptTablesUnits1[i].temperature],
        pressure1: [ptTablesUnits1[i].pressure],
        temperature2: [ptTablesUnits2[i].temperature],
        pressure2: [ptTablesUnits2[i].pressure],
      };
      array.push(item);

    }

    return array;
  }
  
  //Possibly to be extracted as pressure-temp-edit.component has same methods
  filterOnlySpecificPressureUnits(cellsToFilter: PressureTemperatureCellViewModel[], unit: string): PressureTemperatureCellViewModel[] {
    return cellsToFilter.filter(cell => cell.pressureUnit.toLowerCase() == unit.toLowerCase());
  }

  filterOnlySpecificTemperatureUnits(cellsToFilter: PressureTemperatureCellViewModel[], unit: string): PressureTemperatureCellViewModel[] {
    return cellsToFilter.filter(cell => cell.temperatureUnit.toLowerCase() == unit.toLowerCase());
  }
}
