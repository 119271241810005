import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  ApproverType,
  CoatingRevisionViewModel,
  EsraCoatingsAPIClient,
  TechnologyStandardViewModel,
} from '../../models/autogenerated-coating';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { SnackBarService } from '../../services/snack-bar.service';
import { indicate } from 'src/app/operators';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-new-coating-revision',
  templateUrl: 'new-coating-revision.component.html',
  styleUrls: ['./new-coating-revision.component.scss'],
})
export class NewCoatingRevisionComponent implements OnInit {
  maxDescriptionLength = 250;
  selectedStep = 0;
  isLinear = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  coatingRevisionViewModel: CoatingRevisionViewModel;
  primary: ThemePalette = 'primary';

  roles = Object.keys(ApproverType).filter((item) => {
    return !isNaN(Number(item));
  });

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  adding$ = new BehaviorSubject<boolean>(false);

  technologySpecifications$: Observable<TechnologyStandardViewModel[]> =
    this.getTechnologyStandards();

  constructor(
    public dialogRef: MatDialogRef<NewCoatingRevisionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder,
    private esraCoatingClient: EsraCoatingsAPIClient,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      technology: ['', Validators.required],
      importance: [false, Validators.nullValidator],
    });

    this.secondFormGroup = this._formBuilder.group({
      role: ['', Validators.nullValidator],
      user: ['', Validators.required],
    });
  }

  private getTechnologyStandards(): Observable<TechnologyStandardViewModel[]> {
    return this.esraCoatingClient.getTechnologyStandards();
  }

  stepChanged(event: StepperSelectionEvent): void {
    this.selectedStep = event.selectedIndex;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.firstFormGroup.valid) {
      const coatingRevisionViewModel = new CoatingRevisionViewModel({
              name: this.firstFormGroup.value.title,
              description: this.firstFormGroup.value.description,
              technologyStandardId: this.firstFormGroup.value.technology,
              isMajor: this.firstFormGroup.value.importance,
              status: 0,
              publishDate: new Date(),
            });
      combineLatest([this.esraCoatingClient.getDefaultApprovers(), this.esraCoatingClient.getDefaultReviewers()])
            .pipe(indicate(this.adding$), 
                first())
            .subscribe(([approvers, reviewers]) => {
              coatingRevisionViewModel.approvals = [ ...approvers, ...reviewers];
              this.coatingRevisionViewModel = coatingRevisionViewModel;
              this.stepper.selected.completed = true;
              this.stepper.next();
            });
    } else {
      this.validateAllFormFields(this.firstFormGroup);
    }
  }

  saveUserEditAction = (): void => {
    this.esraCoatingClient
        .addCoatingRevision(
          this.coatingRevisionViewModel
        )
        .pipe(indicate(this.adding$))
        .subscribe({
          next: (coatingRevisionViewModel) => {
            this.coatingRevisionViewModel = coatingRevisionViewModel;
            //move to next step
            this.stepper.selected.completed = true;
            this.stepper.next();
          },
          error: (err) => {
            this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error');
            this.dialogRef.close();
          }
        });
  }

  cancelUserEditAction = (): void => {
    this.dialogRef.close();
  }

  editReviewersApprovers() {
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.next();
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
