// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coat-detail-container {
  display: flex;
  align-items: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: "Gotham", Arial, sans-serif;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 0px;
  overflow: auto;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 1%;
  overflow-y: auto;
}

.coat-detail-common-column {
  flex-shrink: none;
  width: 30%;
  min-width: 30%;
}
.coat-detail-common-column div {
  margin-bottom: 10px;
}

.coat-detial-products-column {
  width: 70%;
}

.coat-details-product-row {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
}

.product {
  width: 28.5%;
}

.voc {
  width: 14.3%;
}

.max-svc-temp {
  width: 14.3%;
}

.product-notes {
  width: 42.8%;
}

.coat-attribute {
  margin-bottom: 3%;
}

.coat-notes {
  display: flex;
  justify-content: flex-start;
  min-height: 30px;
  font-size: 12px;
  width: 100%;
  align-self: flex-end;
  margin-left: -3px;
  margin-bottom: 0px;
}

.coat-note-text {
  margin: auto 0px;
  padding-left: 10px;
}

.icon-file {
  margin: 0px;
  padding: 2px;
  border-left: solid 3px black;
  font-size: 26px;
}

.alternative-service-temp {
  font-weight: bold;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
