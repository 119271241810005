// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  padding: 2%;
}

.attribute-container {
  margin: 100%;
}

.attribute-input * {
  width: 100%;
  margin: auto;
}

h2 {
  margin-bottom: 3%;
}

.prop-label {
  font-family: "Gotham", Arial, sans-serif;
  font-weight: 100;
  font-size: 14px;
  text-align: left;
  font-weight: 100;
  letter-spacing: 0px;
  color: rgba(126, 126, 126, 0.8705882353);
  opacity: 1;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 3%;
}
.action-buttons .btn-wrapper {
  margin-left: 2%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
