import { Injectable } from '@angular/core';
import { EsraPipingAPIClient } from 'src/app/shared/models/autogenerated-piping';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class PipingAndValvesDocumentService {
  constructor(
    private esraPipingAPIClient: EsraPipingAPIClient,
    private snackBarService: SnackBarService
  ) {}

  public openPDF(name: string, businessUnit: string, newWindow = true): void {
    this.esraPipingAPIClient
      .getSummaryDocumentByName(name + '_' + businessUnit + '.pdf')
      .subscribe({
        next: (fileResponse) => {
          const blob = new Blob([fileResponse.data], {
            type: 'application/pdf',
          });
          const url = window.URL.createObjectURL(blob);
          if (newWindow) {
            window.open(url);
          } else {
            window.location.replace(url);
          }
        },
        error: () => {
          this.snackBarService.showError(
            `Unable to retrieve document: ${name} - ${businessUnit}`
          );
        },
      });
  }
}
