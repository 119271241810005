import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import {
  CommonNoteViewModel,
  ServiceViewModel,
  PipeClassViewModel,
  BranchTableViewModel,
  PressureTemperatureTableViewModel,
  EsraPipingAPIClient,
  ServiceDetailViewModel,
} from 'src/app/shared/models/autogenerated-piping';

@Component({
  selector: 'app-service-note-usage',
  templateUrl: './service-note-usage.component.html',
  styleUrls: ['./service-note-usage.component.scss'],
})
export class ServiceNoteUsageComponent implements OnInit {
  constructor(
    private esraApiClient: EsraPipingAPIClient,
    private snackBarService: SnackBarService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ServiceNoteUsageComponent>,
    @Inject(MAT_DIALOG_DATA) public parentObject: object
  ) {}

  objectType: string;
  referencesArray: PipeClassViewModel[];
  faWindowClose = faWindowClose;

  ngOnInit(): void {
    this.getAssociatedReferences();
  }

  getAssociatedReferences() {
    this.referencesArray = [];
    if (this.parentObject instanceof ServiceViewModel) {
      this.objectType = 'Service';
      this.esraApiClient
        .getPipeClassesByServiceId(this.parentObject['id'])
        .subscribe({
          next: (res) => {
            this.referencesArray = [...res];
          },
          error: (err) =>
            this.snackBarService.showSnackBar(
              true,
              err.response,
              'mat-snack-bar-error'
            )
          });
    }
    if (this.parentObject instanceof CommonNoteViewModel) {
      this.objectType = 'Common Note';
      this.esraApiClient
        .getPipeClassesByCommonNoteId(this.parentObject['noteId'])
        .subscribe({
          next: (res) => {
            this.referencesArray = [...res];
          },
          error: (err) =>
            this.snackBarService.showSnackBar(
              true,
              err.response,
              'mat-snack-bar-error'
            )
          });
    }

    if (this.parentObject instanceof ServiceDetailViewModel) {
      this.objectType = 'Service Detail';
      this.esraApiClient
        .getPipeClassesByServiceDetailId(this.parentObject['id'])
        .subscribe({
          next: (res) => {
            this.referencesArray = [...res];
          },
          error: (err) =>
            this.snackBarService.showSnackBar(
              true,
              err.response,
              'mat-snack-bar-error'
            ),
        });
    }

    if (this.parentObject instanceof BranchTableViewModel) {
      this.objectType = 'Branch Table';
      this.esraApiClient
        .getPipeClassesByBranchTableId(this.parentObject['branchTableId'])
        .subscribe({
          next: (res) => {
            this.referencesArray = [...res];
          },
          error: (err) =>
            this.snackBarService.showSnackBar(
              true,
              err.response,
              'mat-snack-bar-error'
            ),
        });
    }
    if (this.parentObject instanceof PressureTemperatureTableViewModel) {
      this.objectType = 'Pressure Temperature Table';
      this.esraApiClient
        .getPipeClassesByPressureTemperatureTableId(this.parentObject['id'])
        .subscribe({
          next: (res) => {
            this.referencesArray = [...res];
          },
          error: (err) =>
            this.snackBarService.showSnackBar(
              true,
              err.response,
              'mat-snack-bar-error'
            ),
        });
    }
  }
}
