import { Component, Input, OnInit } from '@angular/core';
import {
  CoatingsSearchFilterModel,
  EsraCoatingsAPIClient
} from 'src/app/shared/models/autogenerated-coating';
import { MatDialog } from '@angular/material/dialog';
import { NewCommentDialogComponent } from 'src/app/revisions/components/new-comment-dialog/new-comment-dialog.component';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { CoatingObjectEnum } from 'src/app/shared/models/coating-object-enum';
@Component({
  selector: 'app-properties-table',
  templateUrl: './properties-table.component.html',
  styleUrls: ['./properties-table.component.scss'],
})
export class PropertiesTableComponent implements OnInit {
  @Input()
  currentDataObject: any;
  originalCurrentDataObject: any;

  @Input()
  previousDataObject: any;

  @Input()
  customProperties?: CustomPropertyCharacteristic[];

  @Input()
  revisionId: string;

  @Input()
  authorOid: string;

  @Input()
  objectType: CoatingObjectEnum;

  @Input()
  readOnly = true;

  @Input()
  archive: boolean;

  propertyNames: string[];
  propertiesToHide: string[];
  columnNames: string[] = [
    'propertyName',
    'publishedInformation',
    'draftUpdate',
    'comment',
  ];
  
  propertiesCurrenttlyModified = new Set<string>();
  itemArchived = false;

  getPreviousData(propertyName: string) {
    return this.previousDataObject ? this.previousDataObject[propertyName] : '';
  }

  constructor(
    private esraCoatingApiClient: EsraCoatingsAPIClient,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setItemArchiveStatus();
    this.propertiesToHide = this.customProperties
      ?.filter((customProperty) => !customProperty.isVisible)
      .map((customProperty) => customProperty.propertyName);
    this.propertyNames = Object.getOwnPropertyNames(
      this.currentDataObject
    ).filter((propertyName) => this.shouldBeHidden(propertyName));

    //Deepy copy
    this.originalCurrentDataObject = JSON.parse(JSON.stringify(this.currentDataObject));
  }

  private getCustomProperty(
    propertyName: string
  ): CustomPropertyCharacteristic[] {
    if (this.customProperties) {
      return this.customProperties.filter(
        (customProperty) => customProperty.propertyName === propertyName
      );
    }
  }

  private setItemArchiveStatus(): void {
    this.itemArchived = this.archive;
  }

  getDisplayName(propertyName: string): Observable<string> {
    
    if (this.customProperties) {
      const customProperty = this.getCustomProperty(propertyName);
      if (
        customProperty &&
        customProperty.length > 0 &&
        customProperty[0].displayName
      ) {
        return of(customProperty[0].displayName);
      }
    }

    const result = propertyName.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return of(finalResult);
  }

  getValues(propertyName: string): Observable<string[]> {
    const customProperty = this.getCustomProperty(propertyName)[0];
    if (customProperty?.values) {
      return customProperty.values;
    }

    return of([]);
  }

  getValuesFromSearchAPI(
    propertyName: string
  ): Observable<CoatingsSearchFilterModel> {
    return this.esraCoatingApiClient
      .getOptionsForSingleProperty(propertyName)
      .pipe(first());
  }

  shouldByeDisplayedAsText(propertyName: string): boolean {
    return this.getPropertyTypeOrDefault(propertyName) === PropertyType.TEXT;
  }

  isDropdownProperty(propertyName: string): boolean {
    return (
      this.getPropertyTypeOrDefault(propertyName) === PropertyType.DROPDOWN
    );
  }

  getPropertyTypeOrDefault(propertyName: string): PropertyType {
    if (this.customProperties) {
      const customProperty = this.getCustomProperty(propertyName);
      if (customProperty?.[0]) {
        return customProperty[0].propertyType;
      }
    }

    return PropertyType.INPUT;
  }

  valueChanged(propertyName: string): boolean {
    return this.hasPropertyValueChanged(propertyName, this.currentDataObject, this.previousDataObject);
  }

  private hasPropertyValueChanged(propertyName: string, currentObject: any, previousObject: any): boolean {
    if (!previousObject) {
      return false;
    }

    const currentDataValue = currentObject[propertyName]
    ? currentObject[propertyName]
    : '';

    const previousDataValue = previousObject[propertyName]
    ? previousObject[propertyName]
    : '';

    if (Array.isArray(currentObject[propertyName])) {
      if (currentDataValue.lenght !== previousDataValue.length) {
        return true;        
      } else {
        return this.compareOneByOne(currentDataValue, previousDataValue);
      }
    }

    return currentDataValue !== previousDataValue;
  }

  private compareOneByOne(currentDataValue: any, previousDataValue: any) : boolean {
    const arrayForCurrentVals = currentDataValue.sort();
        const arrayForPrevVals = previousDataValue.sort();

        for (let i = 0; i < arrayForCurrentVals.lenght; i++) {
          if (arrayForCurrentVals[i] !== arrayForPrevVals[i]) {
            return true;            
          }
        }

        return false;
  }

  persistedChange(propertyName: string): boolean {
    return !this.hasPropertyValueChanged(propertyName, this.currentDataObject, this.originalCurrentDataObject);  
  }

  addComment(
    propertyName: string,
    currentPropertyValue: any,
    previousPropertyValue: any
  ) {
    this.getDisplayName(propertyName)
      .pipe(first())
      .subscribe((displayName) => {
        const dialogRef = this.dialog.open(NewCommentDialogComponent, {
          width: '500px',
          height: '340px',
          disableClose: false,
          data: {
            revisionId: this.revisionId,
            authorOid: this.authorOid,
            propertyName: displayName,
            currentPropertyValue: currentPropertyValue,
            previousPropertyValue: previousPropertyValue,
            linkedCoatingSystemGuid:
              this.objectType === CoatingObjectEnum.COAT
                ? this.currentDataObject.coatingSystemId
                : this.currentDataObject.id,
            linkedCoatingProductGuid: this.currentDataObject.coatingProductId,
            linkedCoatingSystemSolutionGuid: this.currentDataObject.solutionId,
          }, //through magic, only the linked GUID that corresponds to this data object will be populated
        });
        dialogRef.afterClosed().subscribe((result) => {});
      });
  }

  private shouldBeHidden(propertyName: string): boolean {
    if (this.propertiesToHide)
      return this.propertiesToHide.indexOf(propertyName) === -1;
  }
}

export class CustomPropertyCharacteristic {
  propertyName: string;
  displayName?: string;
  isVisible: boolean;
  propertyType?: PropertyType;
  values?: Observable<string[]>;
}

export enum PropertyType {
  INPUT,
  DROPDOWN,
  TEXT,
}
