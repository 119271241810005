import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, switchMap, tap, first } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import { CoatingSolutionDetailComponent } from 'src/app/shared/dialogs/Coating-Solution-Detail/coating-solution-detail.component';
import {
  EsraCoatingsAPIClient,
  EsraRecordLabel,
} from 'src/app/shared/models/autogenerated-coating';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-add-coating-product',
  templateUrl: './add-coating-product-dialog.component.html',
  styleUrls: ['./add-coating-product-dialog.component.scss'],
})
export class AddCoatingProductDialogueComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<CoatingSolutionDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private esraCoatingClient: EsraCoatingsAPIClient,
    private snackBarService: SnackBarService
  ) {}

  subscription = new Subscription();

  revisionId: number;

  selectAction = true;
  createNewCoatingProduct = false;
  selectExistingCoatingProduct = false;
  addToRevisionButtonEnabled = false;

  title = 'select a coating product';

  coatingProducts$: Observable<EsraRecordLabel[]> = this.getCoatingProducts();
  coatingProductOptions: string[];

  selectedProductLabelSubject = new BehaviorSubject<string>(
    'select a coating product'
  );

  isToggleSelected = false;

  addingToRevision$ = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    if (this.data) {
      this.revisionId = this.data.revisionId;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectFromExisting() {
      this.coatingProducts$
        .pipe(first(),
          indicate(this.addingToRevision$))
        .subscribe((_) => {
          this.selectAction = false;
          this.selectExistingCoatingProduct = true;
      });
  }

  createNew() {
    this.selectAction = false;
    this.createNewCoatingProduct = true;
  }

  getCoatingProducts(): Observable<EsraRecordLabel[]> {
    return this.esraCoatingClient
      .getAllCoatingProductLabels(this.data.techSpec)
      .pipe(
        tap(
          (records) =>
            (this.coatingProductOptions = records.map((x) => x.label))
        )
      );
  }

  addCoatingProductToRevision = (revisionId: number, label: string) => {
    this.coatingProducts$
          .pipe(first(),
            map((products) => {
              const match = products.filter(
                (x) => x.label === label
              );
              return match[0].recordId;
            }),
            switchMap((id) =>
              this.esraCoatingClient
                .addCoatingProductToRevision(revisionId, id)
            )
          )
          .subscribe({
            next: ()=>{
              this.dialogRef.close();
              this.snackBarService.showSnackBar(true, label + " added to revision");
            },
            error: (err) => {
              this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error');
            }
          });
  }

  addCoatingProductToBeArchived = (revisionId: number, label: string) => {
    this.coatingProducts$
          .pipe(first(),
            map((products) => {
              const match = products.filter(
                (x) => x.label === label
              );
              return match[0].recordId;
            }),
            switchMap((id) =>
              this.esraCoatingClient
                .addCoatingProductToBeDeleted(revisionId, id)
                .pipe(map(() => this.dialogRef.close()))
            )
          )
          .subscribe(() => {
            indicate(this.addingToRevision$);
            this.snackBarService.showSnackBar(true, label + " added to revision");
          });
  }
  cancel() {
    this.dialogRef.close();
  }
}
