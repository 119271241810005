import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommentThreadService } from '../comments-thread/comment-thread.service';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-new-comment-dialog',
  templateUrl: './new-comment-dialog.component.html',
  styleUrls: ['./new-comment-dialog.component.scss'],
})
export class NewCommentDialogComponent implements OnInit {
  revisionId: number;
  propertyName: string;
  currentPropertyValue: string;
  previousPropertyValue: string;
  authorOid: string;
  linkedCoatingSystemGuid?: string;
  linkedCoatingProductGuid?: string;
  linkedCoatingSystemSolutionGuid?: string;

  addFormGroup: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<NewCommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder,
    private commentsService: CommentThreadService
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.revisionId = this.data.revisionId;
      this.propertyName = this.data.propertyName;
      this.currentPropertyValue = this.data.currentPropertyValue;
      this.previousPropertyValue = this.data.previousPropertyValue;
      this.authorOid = this.data.authorOid;

      if (this.data.linkedCoatingSystemGuid) {
        this.linkedCoatingSystemGuid = this.data.linkedCoatingSystemGuid;
      }

      if (this.data.linkedCoatingProductGuid) {
        this.linkedCoatingProductGuid = this.data.linkedCoatingProductGuid;
      }

      if (this.data.linkedCoatingSystemSolutionGuid) {
        this.linkedCoatingSystemSolutionGuid =
          this.data.linkedCoatingSystemSolutionGuid;
      }
    }

    this.addFormGroup = this._formBuilder.group({
      comment: ['', Validators.required],
    });
  }

  save() {
    if (this.addFormGroup.valid) {
      const propertyComment =
        this.propertyName +
        '<br/>Published Data: ' +
        (this.previousPropertyValue ? this.previousPropertyValue : '') +
        '<br/>Draft Update: ' +
        this.currentPropertyValue +
        '<br/>' +
        this.addFormGroup.value.comment;
      combineLatest([
        this.commentsService.addNewComment(this.authorOid, propertyComment),
      ])
        .pipe(first())
        .subscribe(([comment]) => {
          comment.isAdd = false;
          comment.isEdit = false;

          if (this.linkedCoatingSystemGuid) {
            comment.linkedCoatingSystemId = this.linkedCoatingSystemGuid;
          }
          if (this.linkedCoatingProductGuid) {
            comment.linkedCoatingProductId = this.linkedCoatingProductGuid;
          }
          if (this.linkedCoatingSystemSolutionGuid) {
            comment.linkedCoatingSystemSolutionId =
              this.linkedCoatingSystemSolutionGuid;
          }
          this.commentsService
            .addParentCommentReplyOperation(this.revisionId, comment)
            .pipe(first())
            .subscribe((addedComment) => {
              comment.id = addedComment.id;
              this.commentsService.publishComment(comment);
              this.dialogRef.close();
            });
        });
    }
  }
}
