import { Injectable} from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})

export class SnackBarService {
private static defaultHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
private static defaultVerticalPosition: MatSnackBarVerticalPosition = 'top';

constructor(private snackBar: MatSnackBar){}

public showInfo(message: string) {
  this.showSnackBar(
    true,
    message,
    'mat-snack-bar-info'
  );
}

public showError(message: string) {
  this.showSnackBar(
    true,
    message,
    'mat-snack-bar-error',
    'OK'
  );
}

public showWarning(message: string) {
  this.showSnackBar(
    true,
    message,
    'mat-snack-bar-warning'
  );
}

public showSnackBar(display: boolean, message: string, className?: string | string[], buttonMessage?: string, duration: number = 5000, horizontalPosition = SnackBarService.defaultHorizontalPosition, verticalPosition = SnackBarService.defaultVerticalPosition) {
    if (buttonMessage && buttonMessage !== '') {
      duration = 0;
    }
    if (display) {
      const options = {
        horizontalPosition: horizontalPosition,
        verticalPosition: verticalPosition,
        duration: duration,
        panelClass: className
      }
      
      this.snackBar.open(message, buttonMessage, options);

    } else {
      this.snackBar.dismiss();
    }
  }
}