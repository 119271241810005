import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { Observable, ReplaySubject, combineLatest, filter, map } from 'rxjs';
import { Constants } from '../../shared/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  loggedIn: boolean;

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.init();
  }

  init(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.setCurrentUser(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.initSubjects();
      });
  }

  readonly currentUserProfileSubject$ = new ReplaySubject<AccountInfo>(1);
  readonly currentUserProfile$ = this.currentUserProfileSubject$.asObservable();
  readonly rolesSubject$ = new ReplaySubject<string[]>(1);
  readonly roles$ = this.rolesSubject$.asObservable();

  initSubjects(): void {
    const account = this.getCurrentUser();
    if (account) {
      this.currentUserProfileSubject$.next(account);
      this.rolesSubject$.next(account.idTokenClaims['roles']);
    }
  }

  getCurrentUser(): AccountInfo {
    return this.msalService.instance.getActiveAccount();
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  setCurrentUser(account: AccountInfo): void {
    this.msalService.instance.setActiveAccount(account);
    this.currentUserProfileSubject$.next(account);
    this.rolesSubject$.next(account.idTokenClaims['roles']);
  }

  isPipingAndValvesAdminObservable(): Observable<boolean> {
    return combineLatest<[boolean, boolean, boolean, boolean]>([
      this.isRoleExistsObservable(Constants.admin),
      this.isRoleExistsObservable(Constants.pipingRevisionWriter),
      this.isRoleExistsObservable(Constants.pipingRevisionEditor),
      this.isRoleExistsObservable(Constants.pipingRevisionReviewer),
    ]).pipe(
      map(
        ([isAdmin, isWriter, isEditor, isReviewer]) =>
          isAdmin || isWriter || isEditor || isReviewer
      )
    );
  }

  canPublishPipingAndValvesRevisions(): boolean {
    return (
      this.isRoleExists(Constants.admin) ||
      this.isRoleExists(Constants.pipingRevisionWriter) ||
      this.isRoleExists(Constants.pipingRevisionEditor)
    );
  }

  isRoleExistsObservable(roleName: string): Observable<boolean> {
    return this.rolesSubject$.pipe(map((roles) => roles.includes(roleName)));
  }

  isRoleExists(roleName: string): boolean {
    return this.getCurrentUser()?.idTokenClaims['roles']?.includes(roleName);
  }

  setLoginDisplay() {
    this.loggedIn = this.msalService.instance.getAllAccounts().length > 0;
  }
}
