import { OnDestroy, OnInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import {
  CoatingSystemSolutionViewModel,
  EsraCoatingsAPIClient,
} from '../../models/autogenerated-coating';
import { TechnologyEnum } from '../../models/technology-enum';

@Component({
  selector: 'app-coating-solution-detail',
  templateUrl: './coating-solution-detail.component.html',
  styleUrls: ['./coating-solution-detail.component.scss'],
})
export class CoatingSolutionDetailComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<CoatingSolutionDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private esraCoatingClient: EsraCoatingsAPIClient
  ) {}

  retVal: boolean = false;

  solution$: Observable<CoatingSystemSolutionViewModel>;

  technology = TechnologyEnum.COATINGS;

  private readonly subscription = new Subscription();

  downloading$ = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    if (this.data) {
      this.solution$ = this.esraCoatingClient
        .getCoatingSystemSolutionByName(this.data.body)
        .pipe(
          map((solution) => {
            solution.coats.sort((a, b) =>
              a.coatModel.coatRelativeOrder < b.coatModel.coatRelativeOrder
                ? -1
                : 1
            );
            return solution;
          })
        );
      }
    }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  download() {
    this.subscription.add(
      this.solution$.subscribe((solution) => {
        this.esraCoatingClient
          .getCoatingSolutionReportById(solution.solutionId)
          .pipe(
            indicate(this.downloading$),
            tap((response) => {
              const file = response.data;
              const fileUrl = URL.createObjectURL(file);
              window.open(fileUrl);
            }),
            map(() => {
              return;
            })
          )
          .subscribe();
      })
    );
  }

  cancel() {
    this.dialogRef.close(this.retVal);
  }

  getSortedAscending(arrayToSort: string[]): string[] {
    return arrayToSort.sort((a,b) => a.toLowerCase().localeCompare(b.toLowerCase(), 'en', {sensitivity: 'base'}));
  }
}
