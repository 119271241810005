import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'poundPerGallonToGramPerLiter',
})
export class PoundPerGallonToGramPerLiterPipe implements PipeTransform {
  transform(value: number): string {
    let gramPerLiter = value * 119.826;
    return gramPerLiter.toFixed(0);
  }
}
