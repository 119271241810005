import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { EsraPipingAPIClient, ServiceViewModel } from "src/app/shared/models/autogenerated-piping";
import { SnackBarService } from "src/app/shared/services/snack-bar.service";

@Component({
  selector: 'app-service-add-edit',
  templateUrl: './service-add-edit.component.html',
  styleUrls: ['./service-add-edit.component.scss']
})
export class ServiceAddEditComponent implements OnInit {
  inputFormGroup: UntypedFormGroup;
  serviceToUpdate: ServiceViewModel;

  title: string;
  genericMissingValueMessage = "This is a required field";

  isEditMode = false;
  isCreateMode = false;
  submitClicked = false;

  constructor(
    private esraApiClient: EsraPipingAPIClient,
    private snackBarService: SnackBarService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getStoredService();

    if (this.isCreateMode) {
      this.title = "Create a new service";
    }

    if (this.isEditMode) {
      this.title = `Edit ${this.serviceToUpdate.name}`
    }

    this.initializeFormControls();

    if (this.isEditMode) {
      this.setFormValues();
    }
  }

  getStoredService(): void {
    const storedService = localStorage.getItem('service');
    if (storedService) {
      this.serviceToUpdate = JSON.parse(storedService);
      localStorage.removeItem('service');
      this.isEditMode = true;
    } else {
      this.isCreateMode = true;
    }
  }

  initializeFormControls(): void {
    this.inputFormGroup = this.formBuilder.group({
      name:['', Validators.required]
    })
  }

  setFormValues() {
    this.inputFormGroup.setValue({
      name: this.serviceToUpdate.name
    });
  }

  save() {
    this.submitClicked = true;

    const newService = new ServiceViewModel({
      name: this.inputFormGroup.get('name').value,
      isActive: true
    });

    if (this.inputFormGroup.valid) {
      if (this.isCreateMode) {
        this.esraApiClient.createService(newService)
          .subscribe({
            next: () => {
              this.snackBarService.showSnackBar(true, `The new service, ${newService.name}, was successfully created.`);
              this.router.navigate(['/piping/dictionaries/services']);
            },
            error: (err) => {
              this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error');
            }
          });
      }

      if (this.isEditMode) {
        this.serviceToUpdate.name = newService.name;
        this.esraApiClient.updateService(this.serviceToUpdate)
          .subscribe({
            next: () => {
              this.snackBarService.showSnackBar(
                true,
                `Service name was changed to ${this.serviceToUpdate.name}`
              );
              this.router.navigate(['/piping/dictionaries/services']);
            },
            error: (err) => {
              this.snackBarService.showSnackBar(
                true, err.response, 'mat-snack-bar-error'
              );
            }
          });
      }
    }

    if (this.inputFormGroup.invalid) {
      this.inputFormGroup.markAllAsTouched();
    }
  }

  closeWindow() {
    window.close();
  }
}