import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fahrenheitToCelsius',
})
export class FahrenheitToCelsiusPipe implements PipeTransform {
  transform(value: number): string {
    const celcius = (value - 32) * (5 / 9);
    return celcius.toFixed(0);
  }
}
