// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-section {
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: flex-end;
  row-gap: 100px;
}
.buttons-section button {
  margin: 10px 15px;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2%;
  margin-bottom: 2%;
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0px;
  color: #0066b2;
}

.subtitle {
  font-family: "Gotham", Arial, sans-serif;
  font-size: 14px;
  color: #0066b2;
  font-weight: bold;
}

.subtitle-no-service {
  margin-top: 30px;
  font-family: "Gotham", Arial, sans-serif;
  font-size: 14px;
  color: #97002e;
  font-weight: bold;
}

.errorMessage {
  text-align: center;
  color: #97002e;
  margin-top: 2%;
  margin-bottom: 2%;
}

.main-pane {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2%;
}

app-add-service-temperature {
  margin-top: 100px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
