import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CoatOrderService {
  public readonly coatOrders = [
    { coat: 'PRIMER', order: 0 },
    { coat: 'TIECOAT', order: 1 },
    { coat: 'TOPCOAT', order: 2 },
    { coat: '1ST COAT', order: 3 },
    { coat: '2ND COAT', order: 8 },
    { coat: '2ND COAT IF REQUIRED', order: 9 },
    { coat: 'FIREPROOFING', order: 7 },
    { coat: 'INTERMEDIATE', order: 12 },
    { coat: 'LAMINATE & RESIN LAYER', order: 6 },
    { coat: "MANUFACTURER'S STANDARD", order: 0 },
    { coat: 'No coat specified.', order: 17 },
    { coat: 'PENETRATING PRIMER', order: 4 },
    { coat: 'PRIMER', order: 5 },
    { coat: 'SECONDARY TOPCOAT', order: 16 },
    { coat: 'SINGLE COAT', order: 2 },
    { coat: 'SPLASH ZONE', order: 1 },
    { coat: 'TEMPORARY TOPCOAT', order: 13 },
    { coat: 'TIECOAT', order: 10 },
    { coat: 'TIECOAT RESIN', order: 11 },
    { coat: 'TOPCOAT', order: 14 },
    { coat: 'TOPCOAT RESIN', order: 15 },
  ];
}
