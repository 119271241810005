import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatOptionSelectionChange, ThemePalette } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-add-existing',
  templateUrl: './add-existing.component.html',
  styleUrls: ['./add-existing.component.scss']
})
export class AddExistingComponent implements OnInit {

  createNewCoatingSystem = false;
  addToRevisionButtonEnabled = false;
  isToggleSelected = false;
  primary: ThemePalette = 'primary';

  @Input()
  revisionId: number;
  @Input()
  items: string[];
  itemsToDisplay: string[];
  @Input()
  title: string;

  @Output()
  addToReviseEvent = new EventEmitter<CoatingRevisionItem>();
  @Output()
  addToArchiveEvent = new EventEmitter<CoatingRevisionItem>();

  selectedCoatingSystemLabels = new Set<string>();
  addingToRevision$ = new BehaviorSubject<boolean>(false);

  @Input()
  archiveItemMethod: (revisionId: number, itemId: string) => Observable<any>;
  
  @Input()
  reviseItemMethod: (revisionId: number, itemId: string) => Observable<any>;

  itemsFormControl = new UntypedFormControl('');

  constructor( private _dialogRef: MatDialogRef<any>,
      private _snackBarService: SnackBarService) { }

  ngOnInit(): void {
    this.itemsToDisplay = this.items;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.itemsToDisplay = this.items.filter((system) =>
                system.toLowerCase().includes(filterValue.toLowerCase())
              );
  }

  toggle(event: MatSlideToggleChange) {
    if (event.checked) {
      this.isToggleSelected = true;
    } else {
      this.isToggleSelected = false;
    }
  }

  onSelection(selection: MatOptionSelectionChange) {
    if(selection.source.selected) {
      this.selectedCoatingSystemLabels.add(selection.source.value);
    } else {
      this.selectedCoatingSystemLabels.delete(selection.source.value);
    }

    if(this.selectedCoatingSystemLabels.size > 0) {
      this.addToRevisionButtonEnabled = true;
    } else {
      this.addToRevisionButtonEnabled = false;
    }
  }

  addToRevision() {
    const addCoatingSystemObservables = [];
    let labelHolder: string;
    if (this.isToggleSelected) {
      // add to be archived
      this.selectedCoatingSystemLabels.forEach(label => {
        addCoatingSystemObservables.push(this.archiveItemMethod(this.revisionId, label));
        labelHolder = label;
      })
    } else {
      // revise
        this.selectedCoatingSystemLabels.forEach(label => {
          addCoatingSystemObservables.push(this.reviseItemMethod(this.revisionId, label));
          labelHolder = label;
        });
    }

    combineLatest([addCoatingSystemObservables])
          .pipe(indicate(this.addingToRevision$), 
            first())
          .subscribe({
            next: (result) =>{
              this._snackBarService.showSnackBar(true,  labelHolder + "s added to revision");
              this._dialogRef.close();
            },
            error: (err) => {
              this._snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error');
            }
        });
  }

  cancel() {
    this._dialogRef.close();
  } 
}

export class CoatingRevisionItem {
  revisionId: number;
  label: string;
}
