// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-row {
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
  margin-right: 2%;
}

.sub-title {
  font-size: 16px;
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: 100;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.prop-title {
  font-size: 14px;
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: 100;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.prop-label {
  font-family: "Gotham", Arial, sans-serif;
  font-weight: 100;
  font-size: 14px;
  text-align: left;
  font-weight: 100;
  letter-spacing: 0px;
  color: rgba(126, 126, 126, 0.8705882353);
  opacity: 1;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.prop-item {
  white-space: pre-wrap;
}

.coating-system-details-container {
  display: flex;
  flex-direction: column;
  outline: 1px solid #e3e3e3;
  margin-top: 0.5%;
  margin-left: 2%;
  margin-right: 2%;
  min-height: 100px;
  padding-left: 1%;
  padding-right: 1%;
}
.coating-system-details-container .coating-system-properties {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.coating-system-details-container .coating-system-properties .coating-system-col {
  flex: 1 1 33.3333%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.coating-system-details-container .coating-system-note {
  flex: 0 0 100%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.coating-system-details-container .manufacturer-container {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.coating-system-details-container .manufacturer-name {
  padding-right: 40px;
}

.coat-detail-headers {
  display: flex;
  align-items: flex-start;
  margin-top: 1%;
  padding-top: 1%;
  margin-left: 20px;
  margin-right: 20px;
  border-top: 1px solid #e3e3e3;
}
.coat-detail-headers .coat-detail {
  width: 30%;
}
.coat-detail-headers .product {
  width: 20%;
}
.coat-detail-headers .voc {
  width: 10%;
}
.coat-detail-headers .max-svc-temp {
  width: 10%;
}
.coat-detail-headers .product-notes {
  width: 30%;
}

.dialog-buttons {
  display: flex;
  align-content: right;
  margin-right: 2%;
  gap: 10px;
  float: right;
}
.dialog-buttons .button {
  margin: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
