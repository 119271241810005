import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-slide-toggle',
    templateUrl: 'slide-toggle.component.html',
    styleUrl: 'slide-toggle.component.scss'
})
export class SlideToggleComponent {
    @Input() inputObject;
    @Input() checked: boolean;
    @Output() slideToggleEvent = new EventEmitter<boolean>();

    protected onSlide(inputObject) {
        inputObject.isActive = !inputObject.isActive;
        this.slideToggleEvent.emit();
    }
}