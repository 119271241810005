// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.navigation-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  flex-grow: 1;
}

.navigation-status-conatiner {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
}

.steps {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  padding: 27px 24px 27px 24px;
  min-width: 120px;
  overflow: auto;
}

.navigation-item:not(:last-child) .horizontal-line-right {
  border-top: 3px solid #0b2d71;
  margin-top: 10px;
  flex-grow: 1;
}

.navigation-item:last-child .horizontal-line-right {
  margin-top: 10px;
  flex-grow: 1;
}

.navigation-item:first-child .horizontal-line-left {
  margin-top: 10px;
  flex-grow: 1;
}

.navigation-item:not(:first-child) .horizontal-line-left {
  border-top: 3px solid #0b2d71;
  margin-top: 10px;
  flex-grow: 1;
}

.navigation-item {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.navigation-item .navigation-item-container span {
  font-weight: bold;
  color: #000000;
}
.navigation-item .navigation-item-container mat-icon {
  color: #0b2d71;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
