import { Component, Input, OnInit } from '@angular/core';
import { BranchTableViewModel, CommonNoteViewModel, BranchTableCodeViewModel } from '../../models/autogenerated-piping';

@Component({
  selector: 'app-branch-table-display-footer',
  templateUrl: './branch-table-display-footer.component.html',
  styleUrls: ['./branch-table-display-footer.component.scss']
})
export class BranchTableDisplayFooterComponent implements OnInit {
  @Input() branchTableVM: BranchTableViewModel;

  freeTextNotes: string[] = [];
  commonNotes: CommonNoteViewModel[] = [];
  codes: BranchTableCodeViewModel[] = [];

  ngOnInit() {
    if (this.branchTableVM.branchTablePipeClassViewModels !== null) {
      this.branchTableVM.branchTablePipeClassViewModels.forEach((btpcvm) => {
        if (btpcvm.freeTextNote !== null) {
          this.freeTextNotes.push(btpcvm.freeTextNote);
        }
      });
    }

    if (this.branchTableVM.commonNotes !== null) {
      this.branchTableVM.commonNotes.forEach((commonNote) => {
        if (commonNote !== null) {
          this.commonNotes.push(commonNote);
        }
      });
    }

    if (this.branchTableVM.codes !== null) {
      this.branchTableVM.codes.forEach((code) => {
        if (code !== null) {
          this.codes.push(code);
        }
      });
    }
  }
}
