import { Component, OnDestroy, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  combineLatest,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import {
  EsraCoatingsAPIClient,
  CoatingSystemViewModel,
  CoatViewModel,
} from 'src/app/shared/models/autogenerated-coating';
import { Location } from '@angular/common';
import {
  CustomPropertyCharacteristic,
  PropertyType,
} from '../../components/properties-table/properties-table.component';
import { AddCoatDialogComponent } from './add-coat-dialog/add-coat-dialog.component';
import { RemoveCoatDialogComponent } from './remove-coat-dialog/remove-coat-dialog.component';
import { first } from 'rxjs/operators';
import { indicateWithCounter } from 'src/app/operators';
import { CoatingObjectEnum } from 'src/app/shared/models/coating-object-enum';
import { UserService } from 'src/app/core/services/user.service';
import { EditCoatingComponent } from './edit-coating-component-abstract';

@Component({
  selector: 'app-edit-coating-system',
  templateUrl: './edit-coating-system.component.html',
  styleUrls: ['./edit-coating-system.component.scss'],
})
export class EditCoatingSystemComponent extends EditCoatingComponent implements OnDestroy{
  customCoatingSystemProperties: CustomPropertyCharacteristic[] = [
    { propertyName: 'id', isVisible: false },
    { propertyName: 'coatingSystemId', isVisible: true, propertyType: PropertyType.TEXT},
    { propertyName: 'technologyStandard', isVisible: false },
    { propertyName: 'coats', isVisible: false },
    { propertyName: 'preference', isVisible: false },
    { propertyName: 'prevRevisionSystemId', isVisible: false },
  ];

  customCoatProperties: CustomPropertyCharacteristic[] = [
    { propertyName: 'coatRelativeOrder', isVisible: false },
    { propertyName: 'id', isVisible: false },
    { propertyName: 'coatingSystemId', isVisible: false },
    {
      propertyName: 'coatName',
      isVisible: true,
      propertyType: PropertyType.TEXT,
    },
    { propertyName: 'dft', isVisible: true, propertyType: PropertyType.INPUT },
    {
      propertyName: 'genericClassification',
      isVisible: true,
      propertyType: PropertyType.DROPDOWN,
    },
    {
      propertyName: 'notes',
      isVisible: true,
      propertyType: PropertyType.INPUT,
    },
  ];

  private readonly subscription = new Subscription();

  //need to send object types to the Properties Table so that it knows where to look for the system GUID
  systemType = CoatingObjectEnum.SYSTEM;
  coatsType = CoatingObjectEnum.COAT;

  //TODO:Change name as there is current and updated object in array
  currentAndPrevious$ = new ReplaySubject<CoatingSystemViewModel[]>(1);

  objectToBeUpdated: CoatingSystemViewModel;
  hasRevisionPermission = true;
  currentCoatingSystemId: string;

  constructor(
    @Inject(Location) location: Location,
    @Inject(UserService)  userService: UserService,
    @Inject(EsraCoatingsAPIClient) esraCoatingClient: EsraCoatingsAPIClient,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    super(location, userService, esraCoatingClient);
    this.route.params.pipe(first()).subscribe((routerParams) => {
      this.currentCoatingSystemId = routerParams.coatingsystemid;
      this.getDraftCoatingSystem(
        routerParams.coatingsystemid,
        routerParams.revisionid
      );
    });
    this.fillCoatValues(this.customCoatProperties);
  }

  private getDraftCoatingSystem(
    draftCoatingSystemId: string,
    revisionId: number
  ) {
    combineLatest([
      this.esraCoatingClient
        .getCoatingSystemByUniqueId(draftCoatingSystemId)
        .pipe(indicateWithCounter(this.loadingData$, this.counter)),
      this.esraCoatingClient.getCoatingRevisionById(revisionId),
    ])
      .pipe(
        indicateWithCounter(this.loadingData$, this.counter),
        first()
      )
      .subscribe(([draftCoatingSystem, revision]) => {
        this.revision = revision;
        this.objectToBeUpdated = draftCoatingSystem;
        this.sortCoatsInEditedObject();
        this.getPublishedCoatingSystem();
        if (!this.isRevisionReadOnly()) {
          this.getPropertyValuesForDropdownsFromAzureCog(this.objectToBeUpdated.technologyStandard);
        }
      });
  }

  private getPublishedCoatingSystem() {
    if(this.objectToBeUpdated.prevRevisionSystemId == null){
      this.objectToBeUpdated.prevRevisionSystemId=this.objectToBeUpdated.id
    }
    
    this.esraCoatingClient
      .getCoatingSystemByUniqueId(this.objectToBeUpdated.prevRevisionSystemId)
      .pipe(
        indicateWithCounter(this.loadingData$, this.counter),
        first()
      )
      .subscribe((publishedCoatingSystem) =>
        this.currentAndPrevious$.next([
          this.objectToBeUpdated,
          publishedCoatingSystem,
        ])
      );
  }

  private fillCoatValues(
    customCharacteristics: CustomPropertyCharacteristic[]
  ) {
    customCharacteristics
      .filter((custom) => custom.propertyType === PropertyType.DROPDOWN)
      .forEach(
        (custom) =>
          (custom.values = this.esraCoatingClient
            .getCoatPropertyValues(custom.propertyName)
            .pipe(first()))
      );
  }

  private sortCoatsInEditedObject() {
    this.objectToBeUpdated.coats.sort((coat1, coat2) => {
      if (coat1.coatName > coat2.coatName) {
        return 1;
      }

      if (coat1.coatName < coat2.coatName) {
        return -1;
      }

      return 0;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addCoat() {
    const dialogRef = this.dialog.open(AddCoatDialogComponent, {
      width: '70%',
      height: '90%',
      disableClose: false,
      data: {
        coatingSystemGuid: this.objectToBeUpdated.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.objectToBeUpdated.coats.push(result);
        this.sortCoatsInEditedObject();
      }
    });
  }

  removeCoat(coatGuid: string) {
    const dialogRef = this.dialog.open(RemoveCoatDialogComponent, {
      width: '40%',
      height: '30%',
      disableClose: false,
      data: {
        title: 'Delete Coat from Coating System',
        content: 'The deletion of this information will not be reversible',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.esraCoatingClient
          .removeCoat(coatGuid, this.objectToBeUpdated.id)
          .pipe(
            indicateWithCounter(this.loadingData$, this.counter),
            first()
          )
          .subscribe((deletedCoat) =>
            this.objectToBeUpdated.coats.forEach((item, index) => {
              if (item.id === deletedCoat.id) {
                this.objectToBeUpdated.coats.splice(index, 1);
              }
            })
          );
      }
    });
  }

  save() {
    this.subscription.add(
      this.esraCoatingClient
        .updateCoatingSystemByUniqueId(
          this.objectToBeUpdated.id,
          this.objectToBeUpdated
        )
        .pipe(indicateWithCounter(this.loadingData$, this.counter), first())
        .subscribe({
          next: () => this.goBack(),
          error: (error) => {
            console.log('ERROR: ', error);
            this.cancel();
          }
        }
        )
    );
  }

  getCommentsForCoatingSystem() {
    return this.revision.comments.filter(
      (comment) => comment.linkedCoatingSystemId === this.currentCoatingSystemId
    );
  }

  getPreviousCoat(
    currentCoat: CoatViewModel,
    previousCoatingSystem: CoatingSystemViewModel
  ): CoatViewModel {
    const matchedPreviousCoat = previousCoatingSystem.coats.find(
      (previousCoat) => currentCoat.coatName === previousCoat.coatName
    );
    if (matchedPreviousCoat) {
      return matchedPreviousCoat;
    }

    return null;
  }

  getCustomProperties() {
    return this.customCoatingSystemProperties;
  }

  setCustomProperty(customProperty: CustomPropertyCharacteristic ){
    this.customCoatingSystemProperties.push(customProperty);
  }

  public isSystemBeingArchived(): boolean{
    for(const system of this.revision.coatingSystems){
      if(system.id === this.objectToBeUpdated.id){
        return system.toBeDeleted;
      }
    }
    return false;
  }
}