// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.temp-headings {
  color: red;
}

#container {
  flex-direction: column;
}

#table-container {
  width: auto;
}

#table-scroll {
  overflow: auto;
}

#pres-temp {
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

#row-header {
  background-color: #f7f7f7;
  color: black;
  font-weight: 1000;
  border: 1px solid silver;
  width: 10%;
  left: 0;
  position: sticky;
  text-align: center;
}

#bottom-row-header {
  background-color: silver;
  color: black;
  font-weight: 1000;
  background-color: #f7f7f7;
  border: 1px solid silver;
  width: 10%;
  left: 0;
  position: sticky;
  text-align: center;
}

#top-row-cells {
  border-right: solid 1px silver;
  border-top: 1px solid silver;
  border-bottom: solid 1px silver;
}

#bottom-row-cells {
  border-right: solid 1px silver;
  background-color: white;
}

#cell {
  border-right: solid 1px silver;
  border-bottom: solid 1px silver;
  background-color: white;
}

#spacer-cell {
  background-color: white;
}

#rev-cell {
  background-color: white;
  width: 10%;
}

#rev-head-cell {
  background-color: #f7f7f7;
  border-left: 1px solid silver;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  width: 10%;
  color: black;
  font-weight: 1000;
  text-align: center;
}

#source-notes #additional-notes {
  display: inline;
}

.additional-notes {
  padding: 2% 0;
}

.common-notes-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: bold;
  align-items: flex-start;
}
.common-notes-section .header {
  color: #707070;
}
.common-notes-section .comma {
  margin-right: 5px;
}
.common-notes-section .common-notes {
  display: flex;
  flex-direction: row;
  color: #0066b2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
