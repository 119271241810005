// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#top-container #mat-close-btn {
  height: 36px;
  width: 20px;
  border: none;
  float: right;
  background-color: white;
}
#top-container #mat-close-btn fa-icon {
  color: #707070;
}
#top-container .message {
  width: 100%;
}

#lower-container {
  margin-left: 5%;
}
#lower-container .no-pipes-message {
  color: #e21836;
  text-align: center;
  margin: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
