import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inchesTomillimetersPipe',
})
export class InchesTomillimetersPipe implements PipeTransform {
  transform(value: number): number {
    let millimeters = value * 25.4;
    return millimeters;
  }
}
