import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import {
  EsraCoatingsAPIClient,
  RevisionSummaryViewModel,
} from 'src/app/shared/models/autogenerated-coating';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GraphService } from 'src/app/core/services/graph.service';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { NewCoatingRevisionComponent } from 'src/app/shared/dialogs/coating-revisions/new-coating-revision.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants } from 'src/app/shared/constants/constants';
import { UserService } from 'src/app/core/services/user.service';
import { RevisionStatusPipe } from '../../pipes/revision-status.pipe';

@Component({
  selector: 'all-revisions',
  templateUrl: './all-revisions.component.html',
  styleUrls: ['./all-revisions.component.scss'],
  providers: [RevisionStatusPipe],
})
export class AllRevisionsComponent implements OnInit {
  constructor(
    private router: Router,
    private esraCoatingApiClient: EsraCoatingsAPIClient,
    private graphService: GraphService,
    private dialog: MatDialog,
    private userService: UserService,
    private revisionStatusPipe: RevisionStatusPipe
  ) {}

  loadingRevisions$ = new BehaviorSubject<boolean>(false);
  revisionsFromAPI$: Observable<RevisionSummaryViewModel[]> =
    this.esraCoatingApiClient.getAllRevisionsSummarazied();
  revisions$ = new BehaviorSubject<CoatingRevisionUserViewModel[]>(null);
  dataSource = null;
  columnsToDisplay: string[] = [
    'publishDate',
    'description',
    'authorOid',
    'status',
    'comments',
    'action',
  ];

  canCreateRevisions : boolean; 

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }

  ngOnInit() {
    this.getAllRevisions().subscribe((data) => this.revisions$.next(data));
    this.canCreateRevisions = this.userService.isRoleExists(Constants.coatingRevisionWriter);
  }

  getAuthorsFromGraph(): Observable<MicrosoftGraph.User[]> {
    return combineLatest([this.revisionsFromAPI$]).pipe(
      switchMap(([revisions]) => {
        //NOTE: currently, graph can only serve up 15 users at a time
        let unique = [...new Set(revisions.map((r) => r.authorOid))];
        return this.graphService.getUsersByOids(unique);
      })
    );
  }

  getAllRevisions(): Observable<CoatingRevisionUserViewModel[]> {
    return combineLatest([
      this.revisionsFromAPI$,
      this.getAuthorsFromGraph(),
    ]).pipe(
      indicate(this.loadingRevisions$),
      map(([revisions, users]) => {
        const revisonModels = new Array<CoatingRevisionUserViewModel>();
        revisions.forEach((revision) =>
          revisonModels.push(revision as CoatingRevisionUserViewModel)
        );

        revisonModels.forEach((revision) => {
          const match = users.filter((u) => u.id === revision.authorOid);
          if (match[0]) {
            revision.surname = match[0].surname;
            revision.givenName = match[0].givenName;
            revision.initials =
              match[0].givenName.charAt(0) + ' ' + match[0].surname.charAt(0);
          }

          if(revision.status !== undefined) {
            if(revision.isRejected) {
              revision.readableStatus = 'Rejected';
            } else {
              revision.readableStatus = this.revisionStatusPipe.transform(revision.status);
            }
          }
        });

        this.dataSource = new MatTableDataSource(revisonModels);
        this.dataSource.sort = this.matSort;

        return revisonModels;
      })
    );
  }

  getAuthorName(authorOid: string): Observable<string> {
    return this.graphService
      .getUserByOid(authorOid)
      .pipe(map((user) => `${user.givenName} ${user.surname}`));
  }

  createNewRevision(): void {
    const dialogRef = this.dialog.open(NewCoatingRevisionComponent, {
      width: '70%',
      height: '95%',
      disableClose: false,
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => 
      this.getAllRevisions().subscribe((data) => this.revisions$.next(data)));
  }

  viewDetail(id): void {
    this.router.navigate(['/coatingsrevision/' + id]);
  }

  getStatusClass(status: string): string {
    status = status.replace(/\s/g, '');
    return status.toLowerCase();
  }
}

export class CoatingRevisionUserViewModel extends RevisionSummaryViewModel {
  surname = '';
  givenName = '';
  initials = '';
  readableStatus = '';
}
