import { Component, EventEmitter, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EsraPipingAPIClient } from '../../models/autogenerated-piping';
import { BehaviorSubject, Subscription } from 'rxjs';
export const EnvironmentType = {
  PRODUCTION: '',
  TEST: 'test',
  STAGE: 'stage',
  DEV: 'dev'
};
@Component({
  selector: 'app-environmental-indicator',
  templateUrl: './environmental-indicator.component.html',
  styleUrls: ['./environmental-indicator.component.scss']
})

export class EnvironmentalIndicatorComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  environmentState: string;
  azureSearchPath = new BehaviorSubject<string>('');
  azureSearchPath$ = this.azureSearchPath.asObservable();
  version = new BehaviorSubject<string>('');
  version$ = this.version.asObservable();

  @Output()
  errConnectionRefused = new EventEmitter<boolean>();

  constructor(
    @Optional() private esraPipingAPIClient: EsraPipingAPIClient
  ) {}

  ngOnInit(): void {
    this.environmentState = environment.environmentType;
    if ((this.esraPipingAPIClient !== null || this.esraPipingAPIClient !== undefined) && !environment.production) {
      this.setAzureCognitiveSearchPathString();
    }
  }

  setAzureCognitiveSearchPathString() {
    if (window.navigator.onLine === false) {
      this.errConnectionRefused.emit(true);
    }

    this.esraPipingAPIClient.getEnvironmentalIndicatorData().subscribe({
        next: (response) => {
          this.errConnectionRefused.emit(false);
          this.version.next(response.version);
          this.azureSearchPath.next(this.stripAzureSearchPath(response.azureSearchPath));
        },
        error: () => {
          this.errConnectionRefused.emit(true);
        },
      });
  }

  stripAzureSearchPath(inputString: string): string {
    const backslashPos = inputString.indexOf("//");
    if (backslashPos !== -1) {
      const dotPos = inputString.indexOf(".", backslashPos);
      if (dotPos !== -1) {
        return inputString.substring(backslashPos + 2, dotPos);
      }
    }
    return '';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
