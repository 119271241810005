// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-pipe-component-details {
  display: contents;
}

th {
  border: 1px solid silver;
  background-color: #f7f7f7;
  font-weight: bold;
}

table {
  border: 1px solid silver;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
