import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { TechnologyEnum } from '../../../models/technology-enum';
import { EsraWeldingAPIClient } from 'src/app/shared/models/autogenerated-welding';
import { Subscription } from 'rxjs/internal/Subscription';
import { EsraCoatingsAPIClient } from 'src/app/shared/models/autogenerated-coating';
import { BehaviorSubject } from 'rxjs';
import { indicate } from 'src/app/operators';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { EsraPipingAPIClient, PipeClassViewModel } from 'src/app/shared/models/autogenerated-piping';

@Component({
  selector: 'app-add-to-workspace',
  templateUrl: './add-to-workspace.component.html',
  styleUrls: ['./add-to-workspace.component.scss']
})
export class AddToWorkspaceComponent implements OnDestroy {
  faPlusIcon = faPlus;

  @Input()
  record: string;

  @Input()
  pipeClass: PipeClassViewModel;

  @Input()
  technology: TechnologyEnum;

  adding$ = new BehaviorSubject<boolean>(false);

  private readonly subscription = new Subscription();

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  constructor(
    private esraCoatingApiClient: EsraCoatingsAPIClient,
    private esraWeldingApiClient: EsraWeldingAPIClient,
    private esraPipingApiClient: EsraPipingAPIClient,
    public dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {}

  addCardToWorkspace() {
    if (this.technology === TechnologyEnum.COATINGS) {
      this.subscription.add(
        this.esraCoatingApiClient
          .addCoatingSystemSolutionToWorkspaceById(this.record)
          .pipe(indicate(this.adding$))
          .subscribe(
            {
              next: () => this.snackBarService.showSnackBar(true, 'Coating solution added to workspace', ['mat-snack-bar-success']),
              error: (err) => this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error')
            }
          )
      );
    } else if (this.technology === TechnologyEnum.WELDING) {
      this.subscription.add(
        this.esraWeldingApiClient
          .addWeldingProcedureToWorkspace(this.record)
          .pipe(indicate(this.adding$))
          .subscribe(
            {
              next: () => this.snackBarService.showSnackBar(true, 'Record added successfully', 'mat-snack-bar-success'),
              error: (err) => this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error')
            }
          )
      );
    } else if (this.technology === TechnologyEnum.PIPES) { 
      const pipeClassId = [];
      pipeClassId.push(this.pipeClass.id);
      this.subscription.add(
        this.esraPipingApiClient
          .addPipeClassesToWorkspace(pipeClassId)
          .pipe(indicate(this.adding$))
          .subscribe(
            {
              next: () => this.snackBarService.showSnackBar(true, `Pipe class ${this.pipeClass.name} was added to your workspace`, 'mat-snack-bar-success'),
              error: (err) => this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error')
            }
          )
      );
    }
  }
}