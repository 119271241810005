import { Pipe, PipeTransform } from '@angular/core';
import { ApproverType } from 'src/app/shared/models/autogenerated-coating';

@Pipe({
  name: 'approverType',
})
export class ApproverTypePipe implements PipeTransform {
  transform(value: any): string {
    if (!isNaN(value)) {
      value = '_' + value.toString();
    }
    value = ApproverType[value];

    return value == ApproverType._0 ? 'reviewer' : 'approver';
  }
}
