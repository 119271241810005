import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  loginDisplay = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.checkHashRouting();
  }

  private checkHashRouting() {
    const postHashPath = window.location.hash.substring(1);
    if (postHashPath) {
      const postHashArray = postHashPath.split('/');
      const routingParam = postHashArray[1];
      if (routingParam == 'pipingsummary') {
        const pipeClassName = postHashArray[2];
        const businessUnit = postHashArray[3];
        if (businessUnit === undefined) {
          this.router.navigate(['/pipingsummary/', pipeClassName]);
        } else {
          this.router.navigate([
            '/pipingsummary/',
            pipeClassName,
            businessUnit,
          ]);
        }
      }
    }
  }
}
