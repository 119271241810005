// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-panel {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1607843137);
  opacity: 1;
  margin: 32px 25px;
  padding: 12px 0;
}
.product-panel .product-title {
  display: flex;
  justify-content: space-between;
  margin-left: 2%;
  width: 95%;
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0066b2;
}
.product-panel .add-to-workspace-container {
  margin-right: 30px;
}
.product-panel .prop-label {
  font-family: "Gotham", Arial, sans-serif;
  font-weight: 100;
  font-size: 14px;
  text-align: left;
  font-weight: 100;
  letter-spacing: 0px;
  color: rgba(126, 126, 126, 0.8705882353);
  opacity: 1;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
.product-panel .prop-value {
  font-family: "Gotham", Arial, sans-serif;
  font-size: 14px;
  font-weight: 100;
  text-align: left;
  color: #000000;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
.product-panel .chip-box {
  display: inline-block;
  border: 1px solid #ededee;
  opacity: 1;
  text-align: left;
  border-radius: 10px;
}
.product-panel .chip-box-inside {
  text-align: left;
  font-size: 13px;
  font: "Gotham", Arial, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.product-panel .box {
  width: 25%;
}

.rows-of-4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: 32px;
  margin-left: 32px;
}
.rows-of-4 .box {
  min-width: 180px;
  justify-content: space-evenly;
}

.rows-of-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 32px;
  margin-left: 32px;
}
.rows-of-3 .box {
  flex: 1 1 25%;
  justify-content: space-evenly;
}

.text-button {
  display: inline-block;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: #0066b2;
  font-family: "GothamBold", Arial, sans-serif;
}
.text-button:hover {
  color: #0b2d71;
  box-shadow: inset 0 -2px 0 0 #0b2d71;
}

.highlighted {
  background-color: #faab18;
  font-weight: bold;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
