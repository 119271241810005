// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  margin: 2%;
}

.lower-container {
  margin-top: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.lower-container button {
  display: inline-block;
  width: 45%;
}

fa-icon {
  margin-left: 3%;
}

#title {
  text-align: left;
}

#warning {
  color: red;
}

.mat-mdc-header-cell {
  max-width: 30%;
}

.mat-mdc-cell {
  max-width: 30%;
}

.header {
  font-weight: bolder;
}

.revision-created-container {
  display: flex;
  margin: 15px;
}

.table-container {
  max-height: 50vh;
  overflow: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
