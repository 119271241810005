// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-checkbox {
  display: flex;
  align-content: flex-start;
  margin: 10px 8px auto 0;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
}
.mat-mdc-checkbox .mat-checkbox-layout {
  white-space: normal !important;
}
.mat-mdc-checkbox .mat-checkbox-inner-container {
  margin-top: 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-wrap-label {
  white-space: normal;
}

.mat-expansion-panel-header {
  justify-content: space-between;
}

.outline-panel {
  border: 1px solid #ededee;
}

.muti-select-title {
  text-align: left;
  font: normal normal medium "Gotham", Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0066b2;
  opacity: 1;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mat-expansion-panel-header {
  justify-content: space-between;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
