import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NextpageModule } from '../nextpage/nextpage.module';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { SharedModule } from '../shared/shared.module';
import { WeldingApiModule } from '../shared/welding-api.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MultiSelectFilterComponent } from './filters/multi-select/multi-select-filter/multi-select-filter.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { RangeFilterComponent } from './filters/range-filter/range-filter.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WeldingSearchComponent } from './pages/welding-search/welding-search.component';
import { CoatingSolutionSearchComponent } from './pages/coating-solution-search/coating-solution-search.component';
import { CoatingApiModule } from '../shared/coating-api.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { TextFilterComponent } from './filters/text-filter/text-filter.component';
import { PipingSearchComponent } from './pages/piping-search/piping-search.component';
import { ValvePanelComponent } from '../shared/components/valve-panel/valve-panel.component';
import { PipingAdminSearchComponent } from './pages/piping-admin-search/piping-admin-search.component';

@NgModule({
  declarations: [
    MultiSelectFilterComponent,
    RangeFilterComponent,
    TextFilterComponent,
    WeldingSearchComponent,
    CoatingSolutionSearchComponent,
    PipingSearchComponent,
    PipingAdminSearchComponent,
    ValvePanelComponent,
  ],
  imports: [
    CommonModule,
    NextpageModule,
    FormsModule,
    FontAwesomeModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatGridListModule,
    SharedModule,
    WeldingApiModule,
    CoatingApiModule,
    MatDialogModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    NgxSliderModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
  ],
  exports: [MultiSelectFilterComponent],
  providers: [],
})
export class SearchModule {}
