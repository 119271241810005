import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BranchTableViewModel } from 'src/app/shared/models/autogenerated-piping';

@Component({
  selector: 'app-branch-preview',
  templateUrl: './branch-preview.component.html',
  styleUrls: ['./branch-preview.component.scss']
})
export class BranchPreviewComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public branchTable: BranchTableViewModel,
  ) { }

  branchTableVM: BranchTableViewModel;

  ngOnInit(): void {
    this.branchTableVM = this.branchTable;
  }


}
