// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  flex: 1;
}

h3 {
  color: black;
  text-align: left;
}

.search-members {
  display: flex;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
mat-list .mat-mdc-list-item {
  height: auto;
  padding: 5px 0;
}

.email {
  color: #8c8f93;
}

.members-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  min-height: 360px;
  overflow: auto;
  flex: 1 1 0;
}

app-project-member {
  width: 100%;
  margin: 5px 0;
}

.input {
  width: 100%;
}

.clear {
  position: absolute;
  right: 0;
  margin: 3px;
}

.input-wrapper {
  position: relative;
  flex: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
