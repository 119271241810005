import { Injectable } from '@angular/core';
import { RangeFilterCategory } from '../../filters/range-filter/range-filter.component';

@Injectable({
  providedIn: 'root',
})
export class RangeFilterHelper {
  rangeFiltersTypeMappings = new Map<string, RangeFilterCategory>();

  getRangeFilterCategory(propertyName: string): RangeFilterCategory {
    return this.rangeFiltersTypeMappings.get(propertyName);
  }

  addRangeFilterCategory(propertyName: string, category: RangeFilterCategory) {
    this.rangeFiltersTypeMappings.set(propertyName, category);
  }
}
