import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, ReplaySubject, combineLatest, of, BehaviorSubject, Subscription} from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import {
  CoatingRevisionViewModel,
  RevisionStatus,
  EsraCoatingsAPIClient,
  ApproverType,
  RevisionApprovalStatus,
  RevisionApprovalViewModel,
} from 'src/app/shared/models/autogenerated-coating';
import { MatDialog } from '@angular/material/dialog';
import { AddCoatingSystemDialogueComponent } from '../../dialogues/add-coating-system/add-coating-system-dialogue.component';
import { AddCoatingProductDialogueComponent } from '../../dialogues/add-coating-product/add-coating-product-dialog.component';
import { AddCoatingSolutionDialogueComponent } from '../../dialogues/add-coating-solution/add-coating-solution-dialog.component';
import { GraphService } from 'src/app/core/services/graph.service';
import { UserService } from 'src/app/core/services/user.service';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { Constants } from 'src/app/shared/constants/constants';
import { EditApprovalsComponent } from '../../../shared/components/edit-approvals/edit-approvals.component';
import { RevisionApprovalUserViewModel } from '../../components/comments-thread/comment-thread.service';
import { WorkflowStatusBarComponent } from 'src/app/shared/components/workflow-status-bar/workflow-status-bar.component';
import { RejectCommentComponent } from '../../dialogues/reject-comment/reject-comment.component';
import { notNullOrEmpty } from 'src/app/shared/utils';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { AccountInfo } from '@azure/msal-browser';

@Component({
  selector: 'app-coatings-revision-summary',
  templateUrl: './coatings-revision-summary.component.html',
  styleUrls: ['./coatings-revision-summary.component.scss'],
})
export class CoatingsRevisionSummaryComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChildren(WorkflowStatusBarComponent)
  statusBars: QueryList<WorkflowStatusBarComponent>;

  constructor(
    private route: ActivatedRoute,
    private esraCoatingApiClient: EsraCoatingsAPIClient,
    private graphService: GraphService,
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private location: Location,
    private snackBarService: SnackBarService,
  ) {
    this.currentUser = userService.getCurrentUser();
  }

  private readonly subscription = new Subscription();
  private readonly currentUser:AccountInfo ;

  // subjects for displaying loading progress indicators.
  loadingRevision$ = new BehaviorSubject<boolean>(false);
  loadingRoles$ = new BehaviorSubject<boolean>(false);
  actionButtonIndicator$ = new BehaviorSubject<boolean>(false);

  // displayed cols for tables.
  readonly approverCols: string[] = ['name', 'approverType', 'status'];

  // drop-down toggles
  displayCoatingSystems = true;
  displayCoatingProducts = true;
  displayCoatingSystemSolutions = true;

  revisionId: number;
  revision$ = new ReplaySubject<CoatingRevisionViewModel>(1);
  approvals: RevisionApprovalUserViewModel[] = [];

  justUpdatedCommentIndex = -1;
  hasRevisionPermission = false;
  isPublishedRevision = false;

  ngOnInit() {
    combineLatest([this.route.params]).pipe(
      tap((result) => (this.revisionId = result[0].id))
    );
    
    this.getRevision();
    this.getApprovals();   
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    combineLatest([this.revision$, this.statusBars.changes])
      .pipe(first())
      .subscribe(([revision, statusBars]) => {
        this.refreshWorkflowStatusBasedOnApprovals(revision.approvals);
      });      
  }

  private getRevision() {
    combineLatest([this.route.params])
      .pipe(
        first(),
        tap((result) => (this.revisionId = result[0].id)),
        map((result) => result[0].id),
        switchMap((id) =>
          this.esraCoatingApiClient.getCoatingRevisionById(id).pipe(
            indicate(this.loadingRevision$),
            tap((revision) => {
              this.revision$.next(revision);
            })
          )
        )
      )
      .subscribe();
  }

  private getApprovals() {    
    return combineLatest([this.revision$, this.getApproversFromGraph()])
      .pipe(
        first(),
        map(([revision, users]) => {
          const approvers = new Array<RevisionApprovalUserViewModel>();
          revision.approvals.forEach((approval) =>
            approvers.push(<RevisionApprovalUserViewModel>approval)
          );

          approvers.forEach((approver) => {
            const match = users.filter((u) => u.id === approver.approverOid);
            if (match.length > 0 && match[0] !== null) {
              approver.surname = match[0].surname;
              approver.givenName = match[0].givenName;
            } else {
              approver.givenName = "Unknown";
            }
          });

          this.approvals = approvers;
          this.sortApprovalsByApproverTypeAndName();
        })        
      )      
      .subscribe();
  }

  private getApproversFromGraph(): Observable<MicrosoftGraph.User[]> {
    return combineLatest([this.revision$]).pipe(
      switchMap(([revision]) => of(revision.approvals)),
      switchMap((approvals) =>
        this.graphService.getUsersByOids([
          ...new Set(approvals.map((a) => a.approverOid)),
        ])
      )
    );
  }


  private sortApprovalsByApproverTypeAndName(): void {
    this.approvals.sort((a, b) => 
    {
      if(a.approverType > b.approverType) {
        return 1;
      }
      if(a.approverType < b.approverType) {
        return -1;
      }
      if(a.approverType == b.approverType) {
        if(a.surname.localeCompare(b.surname) < 0)
        {
          return -1;
        }
        if(a.surname.localeCompare(b.surname) > 0) {
          return 1;
        }
        return 0;
      }
    });      
  }

  private refreshWorkflowStatusBasedOnApprovals(
    approvals: RevisionApprovalViewModel[]
  ) {
    approvals.forEach((approval) => {
      //if 'REJECTED'
      if (approval.status && approval.status === RevisionApprovalStatus._1) {
        //if 'APPROVER'
        if (
          approval.approverType &&
          approval.approverType === ApproverType._1
        ) {
          this.statusBars.first.setSingleStatus(RevisionStatus._2, true);
        } else {
          this.statusBars.first.setSingleStatus(RevisionStatus._1, true);
        }
      } 
      else {
        //if 'APPROVER'
        if (
          approval.approverType &&
          approval.approverType === ApproverType._1
        ) {
          this.statusBars.first.setSingleStatus(RevisionStatus._2, false);
        } else {
          this.statusBars.first.setSingleStatus(RevisionStatus._1, false);
        }
      }
    });
  }  

  // open detail methods
  openCoatingSystemDetail(id: string) {
    this.revision$
      .pipe(first(),
        switchMap(revision => this.router.navigate(['/' + Constants.coatingSystemURL, id, revision.id])))
      .subscribe();
  }

  openCoatingProductDetail(id: string) {
    this.revision$
      .pipe(first(),
        switchMap(revision => this.router.navigate(['/' + Constants.coatingProductURL, id, revision.id])))
      .subscribe();
  }

  openCoatingSystemSolutionDetail(id: string) {
    this.revision$
    .pipe(first(),
      switchMap(revision => this.router.navigate(['/' + Constants.updateCoatingSolutionURL, id, revision.id])))
    .subscribe();
  }

  private redirect(url, componentId, revisionId) {
    this.router.navigate([url, componentId, revisionId])
  }

  // add to revision methods
  addCoatingSystem() {
    this.revision$
      .pipe(
        first(),
        map((revision) => revision.technologyStandardId)
      )
      .subscribe((techSpec) => {
        const dialogRef = this.dialog.open(AddCoatingSystemDialogueComponent, {
          width: '75%',
          height: '88%',
          disableClose: false,
          data: {
            revisionId: this.revisionId,
            techSpec: techSpec,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          this.getRevision();
        });
      });
  }

  addCoatingProduct() {
    this.revision$
      .pipe(
        first(),
        map((revision) => revision.technologyStandardId)
      )
      .subscribe((techSpec) => {
        const dialogRef = this.dialog.open(AddCoatingProductDialogueComponent, {
          width: '75%',
          height: '80%',
          disableClose: false,
          data: {
            revisionId: this.revisionId,
            techSpec,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          this.getRevision();
        });
      });
  }

  addCoatingSolution() {
    this.revision$
      .pipe(
        first(),
        map((revision) => revision.technologyStandardId)
      )
      .subscribe((techSpec) => {
        const dialogRef = this.dialog.open(
          AddCoatingSolutionDialogueComponent,
          {
            width: '75%',
            height: '60%',
            disableClose: false,
            data: {
              revisionId: this.revisionId,
              techSpec,
            },
          }
        );

        dialogRef.afterClosed().subscribe((result) => {
          this.getRevision();
        });
      });
  }

  removeCoatingSystem(name: string) {
    const remove = confirm(
      `Are you sure you want to remove the coating system from the revision?\n\n${name}`
    );
    if (remove) {
      const id = name.split(' ')[0];
      this.subscription.add(
        this.esraCoatingApiClient
          .removeCoatingSystemFromRevision(this.revisionId, id, true)
          .subscribe(() => this.getRevision())
      );
    }
  }

  removeCoatingProduct(id: string, name: string) {
    const remove = confirm(
      `Are you sure you want to remove the coating product from the revision?\n\n${name}`
    );
    if (remove) {
      this.subscription.add(
        this.esraCoatingApiClient
          .removeCoatingProductFromRevision(this.revisionId, id)
          .subscribe(() => this.getRevision())
      );
    }
  }

  removeCoatingSolution(id: string, name: string) {
    const remove = confirm(
      `Are you sure you want to remove the coating solution from the revision?\n\n${name}`
    );
    if (remove) {
      this.subscription.add(
        this.esraCoatingApiClient
          .removeCoatingSolutionFromRevision(this.revisionId, name)
          .subscribe(() => this.getRevision())
      );
    }
  }

  approveRevision(revision: CoatingRevisionViewModel) {
   let approveObservable :  Observable<CoatingRevisionViewModel> = null;

    if(revision.status === RevisionStatus._1) {
      approveObservable = this.esraCoatingApiClient
      .approveRevisionAsReviewer(revision.id);
    } else if( revision.status === RevisionStatus._2) {
      approveObservable = this.esraCoatingApiClient
      .approveRevision(revision.id);
    }

    approveObservable.pipe(first(), indicate(this.loadingRevision$))
    .subscribe({
      next: (result) => {
          if( notNullOrEmpty(result.errorMessage) ) {
            alert('Revision has been published. However, ' + result.errorMessage);
          }
          this.revision$.next(result);
          this.getApprovals();
        },
      error: (error) => {
          alert(error.message);
          window.location.reload();
        }
      });
  }

  rejectRevision(revision: CoatingRevisionViewModel) {
    const rejectFunction = revision.status === RevisionStatus._1 ? (revisionId: number) =>
        this.esraCoatingApiClient.rejectRevisionAsReviewer(revisionId) : 
      (revisionId: number) =>
        this.esraCoatingApiClient.rejectRevision(revisionId);

    const dialogRef = this.dialog.open(RejectCommentComponent, {
      width: '500px',
      height: '340px',
      disableClose: false,
      data: {
        revisionId: this.revisionId,
        title: 'Reject revision',
        authorOid: this.currentUser.localAccountId,
        rejectFunction: rejectFunction,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
      if (result) {
        this.statusBars.first.setSingleStatus(RevisionStatus._2, true, false);
        this.revision$.next(result);
        this.getApprovals();
        this.refreshWorkflowStatusBasedOnApprovals(result.approvals);
      }
    },
    error: error => console.log(error.response)});
  }

  canEdit(revision: CoatingRevisionViewModel): boolean {
    // we can add or remove items to the revision only in Draft or Review (0 or 1)
    return revision.status === RevisionStatus._0 || revision.status === RevisionStatus._1;
  }

  approveSectionVisible(revision: CoatingRevisionViewModel): boolean {
    const revisionInReviewUserReviewer = revision.status === RevisionStatus._1 &&
        revision.approvals &&
        revision.approvals.filter(
          (approval) =>
            approval.approverOid === this.currentUser.localAccountId &&
            approval.approverType === ApproverType._0 &&
            approval.status === RevisionApprovalStatus._0
        ).length > 0;

    const revisionInApproveUserApprover = revision.status === RevisionStatus._2 &&
        revision.approvals &&
        revision.approvals.filter(
          (approval) =>
            approval.approverOid === this.currentUser.localAccountId &&
            approval.approverType === ApproverType._1 &&
            approval.status === RevisionApprovalStatus._0
        ).length > 0;

    if (revisionInReviewUserReviewer || revisionInApproveUserApprover) {
      return true;
    }

    return false;
  }

  authorActionBtnsVisible(): boolean {
    let result = false;
    this.revision$.subscribe(
      r => {
        result = r.authorOid === this.currentUser.localAccountId;
      }
    );

    return result;
  }

  resendNotificationButtonVisible(revision: CoatingRevisionViewModel): boolean {
    const revisionInDraftAndRejected = revision.status == RevisionStatus._0 && revision.approvals.filter(approval => approval.approverType == ApproverType._1 && approval.status == RevisionApprovalStatus._1).length > 0
    if(revisionInDraftAndRejected ||
      revision.status == RevisionStatus._1 || 
      revision.status == RevisionStatus._2 || 
      revision.status == RevisionStatus._3) {
        return true; 
    }

    return false;
  }

  resendNotifications(revision: CoatingRevisionViewModel): void {
    if ( confirm('Are you sure you want to send notifications?') ) {
      this.esraCoatingApiClient.resendNotifications(revision.id)
      .pipe(first(),
            indicate(this.actionButtonIndicator$),
            tap(response => {

              if(!response){
                alert('Notifications sent');
              } else {
                alert(response);
              }
           })
          ).subscribe();
    }
  }

  sendForReview(): void {
    this.esraCoatingApiClient.sendRevisionforReview(this.revisionId).pipe(
      indicate(this.actionButtonIndicator$),
      first(),
    ).subscribe({
      next: (response) => { 
        if( notNullOrEmpty(response.errorMessage)) {
          alert('Revision sent for review. However, ' + response.errorMessage);
        } else {
          this.snackBarService.showSnackBar(true, 'Sent for review');
        }
        this.revision$.next(response);
      },
      error: (error) => {
        alert(error.response);
        window.location.reload();
      }}
    );   
  }

  sendForApproval(): void {
    this.esraCoatingApiClient.sendRevisionforApproval(this.revisionId).pipe(
      indicate(this.actionButtonIndicator$),
      first(),
    ).subscribe({
      next: (response) => {
        if( notNullOrEmpty(response.errorMessage) ) {
          alert('Revision sent for approval. However, ' + response.errorMessage);
        }
        this.revision$.next(response);
        this.getApprovals();
        this.refreshWorkflowStatusBasedOnApprovals(response.approvals);
      },
      error: (error) => {
        alert(error.response);
        window.location.reload();
      }
    })
    this.snackBarService.showSnackBar(true, 'Sent for approval');
  }

  editRoles() {
    this.revision$.pipe(first(),
        tap(coatingRevisionViewModel => this.openEditRolesDialog(coatingRevisionViewModel)))
      .subscribe();
  }

  private openEditRolesDialog(coatingRevisionViewModel: CoatingRevisionViewModel) {
    console.log('CoatingRevisionViewModel:   ', coatingRevisionViewModel);
    const dialogRef = this.dialog.open(EditApprovalsComponent, {
      width: '60%',
      height: '90%',
      disableClose: false,
      data: {
        coatingRevisionViewModel: coatingRevisionViewModel,
        title: 'Edit Roles',
        saveAction: () => {
          dialogRef.close();
          this.saveAction(coatingRevisionViewModel);
        },
        cancelAction: () => {
          dialogRef.close();
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.revision$.next(result);
        this.getApprovals();
      }
    });
  }

  private saveAction(coatingRevisionViewModel: CoatingRevisionViewModel): void {
    this.esraCoatingApiClient
      .updateRevisionMembers(coatingRevisionViewModel.id, coatingRevisionViewModel.approvals)
      .pipe(indicate(this.loadingRoles$),
        first())
      .subscribe({
        next: () => {
          this.revision$.next(coatingRevisionViewModel);
          this.getApprovals()},
        error: (err) => {
          alert(err.response);
          console.log(err.response);
        }
      });
    ;
  }

  cancel() {
    this.location.back();
  }
}
