// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outline-panel {
  border: 1px solid #ededee;
}

.squared-border {
  border-radius: 0 !important;
}

.range-filter-title {
  text-align: left;
  font: normal normal medium "Gotham", Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0066b2;
  opacity: 1;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  width: 65px;
  border: 1px solid #6b6d6f;
  border-radius: 6px;
  opacity: 1;
  outline: 0;
  -webkit-appearance: none;
  font-size: 11px;
  padding: 2px 10px;
  color: #6b6d6f;
  box-shadow: inset 0px 0px 1px #6b6d6f;
}

div.distanceGap {
  text-align: center;
}

.type-name {
  padding-bottom: 10px;
  font: normal normal medium "Gotham", Arial, sans-serif;
  font-size: 11px;
}

.temp-changer {
  margin-bottom: 10px;
}

.mat-expansion-panel-header {
  justify-content: space-between;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
