import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  faChevronLeft,
  faChevronRight,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() totalCount: number;
  @Input() pageSize = 10;
  @Output() changePaginationEvent = new EventEmitter<{
    pageSize: number;
    pageNumber: number;
  }>(true);

  faSearch = faSearch;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  pageSizes = [2, 5, 10, 25, 50, 100];
  currentPage = 0;
  lastPage: number;

  ngOnInit() {
    // intentionally empty
  }

  ngOnChanges() {
    this.lastPage = Math.ceil(this.totalCount / this.pageSize);
  }

  public updateCurrentPage(nextPage: number) {
    this.currentPage = nextPage;
    this.changePaginationEvent.emit({
      pageSize: this.pageSize,
      pageNumber: this.currentPage,
    });
  }

  public updatePageSize(pageSize: number) {
    this.currentPage = 0;
    this.pageSize = pageSize;
    this.changePaginationEvent.emit({
      pageSize: this.pageSize,
      pageNumber: this.currentPage,
    });
  }
}
