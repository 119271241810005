import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { API_BASE_URL, EsraWeldingAPIClient } from './models/autogenerated-welding';

@NgModule({
  declarations: [],
  providers: [
    { provide: API_BASE_URL, useValue: environment.weldingApiEndpoint },
    EsraWeldingAPIClient,
  ],
  imports: [],
  exports: [],
})
export class WeldingApiModule {}
