import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, first, map } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import { 
  EsraPipingAPIClient, 
  ComponentViewModel, 
  SearchRequest, 
  EsraSearchFilterModel, 
  ComponentCatalogSearchViewModel,
  FilterType,
  FilterableProperty
} from 'src/app/shared/models/autogenerated-piping';
import { faSearch, faPen, faPlusCircle, faArchive } from '@fortawesome/free-solid-svg-icons';
import { RangeFilterHelper } from 'src/app/search/pages/common/range-filter.helper';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { FilterDataService } from 'src/app/search/services/filter.service';
import { ProductPanelHelper } from 'src/app/shared/components/product-panel/product-panel-helper';
import { MatDialog } from '@angular/material/dialog';
import { ComponentAddEditComponent } from '../dialogs/component-add-edit/component-add-edit.component';
import { UserService } from 'src/app/core/services/user.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ConfirmDialogComponent } from '../../../../shared/confirm-dialog/confirm-dialog.component';
import { FilterablePropertyFactory } from 'src/app/shared/components/factory/filterable-property-factory.service';
import { productionGuard } from 'src/app/core/guards/guards';


@Component({
  selector: 'app-component-catalog',
  templateUrl: './component-catalog.component.html',
  styleUrls: ['./component-catalog.component.scss']
})
export class ComponentCatalogComponent implements OnInit, OnDestroy, AfterViewInit {
  orderBy = '';
  currentPage = 0;
  pageSize = 25;
  totalCount: number;
  wasChecked = false;
  showResult = true;
  searchText = '';
  currentSearchRequest: SearchRequest;
  columnNamesSorted: string[] = [];
  referenceColumnNamesSortedStart: string[] =
    [
      "group",
      "type",
      "scH1RAT",
      "scH2",
      "ends",
      "description",
      "componentCode",
      //BELOW ARE UNORDERED per Haitham's request for all attributes to be ordered by their use in creating description attribute"
      "material",
      "astm",
      "const",
      "designSTD",
      "specialRequirements",
      "usedIn",
      "active"
    ];

  referenceColumnNamesSortedEnd: string[] = ["fromRevisionId", "toRevisionId"];

  displayedColumns: string[] = [
    'group',
    'componentCode', 
    'type', 
    'scH1RAT', 
    'scH2', 
    'ends', 
    'description', 
    'material', 
    'astm', 
    'specialRequirements', 
    'construction'
  ];

  componentTypes$: Observable<Map<string, ComponentViewModel[]>>;

  searching$ = new BehaviorSubject<boolean>(false);
  loadingFilters$ = new BehaviorSubject<boolean>(false);

  componentGroupsArray: string[];
  nonRenderColumnNames: string[] = ["componentId", "componentClassificationId", "category"];
  componentsArrayFiltered: ComponentViewModel[] = [];
  dataSource = new MatTableDataSource(this.componentsArrayFiltered);
  currentAvailableFilters: EsraSearchFilterModel[];

  selectedType: string = null;
  searchInputString = "";
  allSelected = false;
  groupSelectionMade = false;
  isUserAdmin = false;
  filterableProperties: FilterableProperty[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(MatTable) matTable: MatTable<ComponentViewModel>;

  constructor(
    public rangeFilterHelper: RangeFilterHelper,
    private esraApiClient: EsraPipingAPIClient,
    private dialog: MatDialog,
    private userService: UserService,
    private snackbarService: SnackBarService,
    private filterDataService: FilterDataService,
    private productPanelHelper: ProductPanelHelper
  ) {}

  ngOnDestroy(): void {
    this.filterDataService.removeAllFilters();
  }

  faEdit = faPen;
  faArchive = faArchive;
  faSearch = faSearch;
  faCreate = faPlusCircle;
  pageSizes = [2, 5, 10, 25, 50, 100];

  ngOnInit(): void {
    if (productionGuard()) {
      this.displayedColumns.push('editAndArchive');
    }
    this.isUserAdmin = this.isUserInRoleToCreate();
    this.filterDataService.currentFiltersSelection$
      .pipe(
        map((filterSelection) => {
          this.filterableProperties = Array.from(filterSelection.values());
          this.currentPage = 0;
          this.orderBy = "";
          this.search();
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public search(isSortSearch = false): void {
    const request: SearchRequest = new SearchRequest({
      perPage: this.pageSize,
      page: this.currentPage,
      mainSearch: this.searchInputString,
      filters: this.filterableProperties,
      orderBy: this.orderBy
    });

    this.esraApiClient.componentCatalogSearch(request).pipe(
      indicate(this.searching$),
      first(),
      tap( res => {
          this.componentsArrayFiltered = res.results;
          this.parseHighlightedData(this.componentsArrayFiltered);
          this.dataSource = new MatTableDataSource(this.componentsArrayFiltered);
          this.totalCount = res.totalResults;
          if (!this.currentAvailableFilters || this.currentAvailableFilters.length == 0) this.currentAvailableFilters = res.searchFilters;
          this.setInitialTypeFilterOptions();
          this.getDisplayColumns();

          if (isSortSearch) {
            this.retrySearchWithNoFilters();
          }
        }
      )).subscribe({error: err => console.log("Error: " + err)});
  }

  sortData(sort: Sort) {
    switch(sort.direction) {
      case ('asc'):
        this.orderBy = sort.active;
        break;
      case('desc'):
        this.orderBy = `${sort.active} desc`;
        break;
      default:
        this.orderBy = '';
    }

    const filterableProperty = new FilterableProperty({
      displayName: sort.active,
      propertyName: sort.active,
      filterOptions: ['null'],
      filterType: FilterType._8
    });
      
    this.filterableProperties.push(filterableProperty);

    this.search(true);

    const index = this.filterableProperties.indexOf(filterableProperty);
    if (index > -1) {
      this.filterableProperties.splice(index, 1);
    }
  }

  retrySearchWithNoFilters() {
    if (this.dataSource.data.length < 1 && !this.wasChecked) {
      this.filterableProperties = [];
      this.wasChecked = true;
      this.search(true);
    } else {
      this.wasChecked = false;
    }
  }

  public updatePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.search();
  }

  private setInitialTypeFilterOptions(): void {
    if (this.componentGroupsArray !== null) {
      this.componentGroupsArray = this.currentAvailableFilters.find(x => x.filterPropertyName == "Group")?.options ?? [];
    }
  }

  public selectGroup(event): void {
    this.searchInputString = "";

    this.selectedType = event.value;
    this.showResult = true;
    if (this.selectedType == "All Types") {
      this.groupSelectionMade = false;
      this.allSelected = true;
      this.showResult = true;
      this.filterableProperties = [];
      this.search();
    }
    else {
      this.groupSelectionMade = true;
      this.allSelected = false;
      this.filterDataService.setFilterValue(this.getGroupFilter());
    }
  }

  private getGroupFilter(): FilterableProperty {
    const newSearchRequestFilter: FilterableProperty = FilterablePropertyFactory.createFilterbleProperty(FilterType._1, "", "");
    if (this.groupSelectionMade) {
      newSearchRequestFilter.displayName = "";
      newSearchRequestFilter.propertyName = "Group";
      newSearchRequestFilter.filterType = FilterType._1;
      newSearchRequestFilter.filterOptions = [];
      newSearchRequestFilter.filterOptions.push(this.selectedType);
    } else {
      newSearchRequestFilter.filterOptions = [];
    }
    return newSearchRequestFilter;
  }

  getDisplayColumns(): void {
    //cleares existing column names
    const columnNamesRaw = [];
    if (this.componentsArrayFiltered !== undefined && this.componentsArrayFiltered.length > 0 && this.componentsArrayFiltered[0] !== undefined && this.componentsArrayFiltered[0] !== null) {
      const firstComponent = this.componentsArrayFiltered[0];
      let property: keyof typeof firstComponent;
      for (property in firstComponent) {
        if (!this.nonRenderColumnNames.includes(property)) {
          columnNamesRaw.push(property);
        }
      }
    }
    this.columnNamesSorted = this.getColumnNamesCustomSorted(columnNamesRaw);
  }

  private getColumnNamesCustomSorted(columnNames: string[]): string[] {
    const sortedColumnNames: string[] = [];
    //populates sortedColumnNames with attributes that should be rendered first (left side of table)
    for (const refColName of this.referenceColumnNamesSortedStart) {
      for (const columnName of columnNames) {
        if (columnName == refColName) {
          sortedColumnNames.push(columnName);
          columnNames.splice(columnNames.indexOf(columnName), 1);
          break;
        }
      }
    }
    //populates sortedColumnNames with any attribute that is not explicitly defined
    for (const columnName of columnNames) {
      if (this.referenceColumnNamesSortedEnd.indexOf(columnName) == -1) {
        sortedColumnNames.push(columnName);
        columnNames.splice(columnNames.indexOf(columnName), 1);
      }
    }
    //populates sortedColumnNames with attributes that shoudl be rendered last (right side of table)
    for (const refColName of this.referenceColumnNamesSortedEnd) {
      for (const columnName of columnNames) {
        if (columnName == refColName) {
          sortedColumnNames.push(columnName);
          columnNames.splice(columnNames.indexOf(columnName), 1);
          break;
        }
      }
    }

    sortedColumnNames.push("actions");
    return sortedColumnNames;
  }

  clearButton(): void {
    this.searchInputString = "";
    this.search();
  }
  searchByButton(): void {
    this.orderBy = "";
    this.showResult = true;
    this.search();
  }

  searchInputKeyupEnter(): void {
    this.searchByButton();
  }

  public clearAll(): void {
    this.selectedType = undefined;
    this.groupSelectionMade = false;
    this.searchInputString = "";
    this.currentPage = 0;
    this.orderBy = "";
    this.showResult = false;
    this.getAllSearch();
  }

  public getAllSearch(): void {
    this.filterableProperties = [];
    this.searchInputString = "";
    this.search();
  }

  public updateSearchInputString(event): void {
    this.searchInputString = event.target.value;
  }

  public isUserInRoleToCreate(): boolean {
    return this.userService.isRoleExists('Admin');
  }

  public createNew() {
    const dialogRef = this.dialog.open(ComponentAddEditComponent, {
      width: '60%',
      height: '95%'
    });
    dialogRef.afterClosed().subscribe(newComponent => {
      if (newComponent) {
        this.esraApiClient.createComponent(newComponent)
          .pipe(indicate(this.searching$), first())
          .subscribe(() => {
            this.snackbarService.showSnackBar(
              true,
              'The component was created. It may take a few minutes for the '
              + 'table to reflect your changes.'
            );
            this.search();
          })
      }
    })
  }

  protected editItem(item: object) {
    const dialogRef = this.dialog.open(ComponentAddEditComponent, {
      width: '40%',
      height: '90%',
      data: item
    });
    dialogRef.afterClosed().subscribe(updateComponent => {
      if (updateComponent) {
        this.esraApiClient.updateComponent(updateComponent)
          .pipe(indicate(this.searching$),
            first())
          .subscribe({
            next:() => {
              this.snackbarService.showSnackBar(true, 'Component modified');
                if (this.currentSearchRequest !== undefined) {
                this.search();
                }
            }, 
            error: (err) => {
              this.snackbarService.showSnackBar(true, err);
           },
          })
      }
    })
  } 

  onArchiveClick(item: object) {
    const componentId = item["componentId"];

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '30%',
      height: '30%',
      data: {
        data: item,
        dialogMessage: "Are you sure you want to archive this item?"
      },

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result === true) {
        this.archiveComponent(componentId);
      }
    })
  }

  private archiveComponent(componentId: string) {
    this.esraApiClient.archiveComponent(componentId)
      .pipe(indicate(this.searching$),
        first())
      .subscribe({
        next:() => {
          this.snackbarService.showSnackBar(true, 'Component archived successfully');
            if (this.currentSearchRequest !== undefined) {
              this.search();
            }
        },
        error: (err) => {
        this.snackbarService.showSnackBar(true, err.response);
        },
      });
  }

  public toggleAllSelection(): void {
    this.getAllSearch();
  }

  public sortColumn(event): void {
    const columName: string = event.active;
    const pascalCasing = columName.charAt(0).toUpperCase() + columName.substring(1, columName.length);
    this.orderBy = `${pascalCasing} ${event.direction}`;
    this.search();
  }

  latestFilterSelection$ = new BehaviorSubject<string>('');

  updateCheckboxFilterSelection(
    event: Set<string>,
    filterablePropertyName: string,
    filterDiplayName: string
  ) {
    this.latestFilterSelection$.next(filterablePropertyName);
    this.showResult = true;
    if (event.size === 0) {
      this.filterDataService.removeFilterValue(filterablePropertyName);
    } else {
      const filterableProperty = FilterablePropertyFactory.createFilterbleProperty(
        1,
        filterablePropertyName,
        filterDiplayName
      );

      filterableProperty.filterOptions = Array.from(event);
      this.filterDataService.setFilterValue(filterableProperty);
    }
  }

  popClearedFilter($event: any, filterPropertyName: string) {
    if (this.latestFilterSelection$.getValue() === filterPropertyName) {
      this.latestFilterSelection$.next('');
    }
  }

  parseHighlightedData(data: ComponentCatalogSearchViewModel[]) {
    if (data && data.length > 0) {
      data.forEach(element => {
        for (const key in element) {
          element[key] = this.highlightText(element[key]);
        }
      });
    }
  }

  highlightText(value: unknown) {
    if (typeof value === 'string') {
      return this.productPanelHelper.strReplaceHighlightHtml(value.toString());
    } else {
      return value;
    }
  }

  public stripEmphasisClassFromString(string: string): string {
    return ProductPanelHelper.RemoveHTML(string);
  }
}
