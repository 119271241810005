import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { first, tap } from 'rxjs';
import { BranchTableCodeViewModel, EsraPipingAPIClient } from 'src/app/shared/models/autogenerated-piping';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-branch-table-code-add-edit',
  templateUrl: './branch-table-code-add-edit.component.html',
  styleUrls: ['./branch-table-code-add-edit.component.scss']
})
export class BranchTableCodeAddEditComponent implements OnInit {
  faWindowClose = faWindowClose;
  inputFormGroup: FormGroup;
  branchTableCodeViewModel = new BranchTableCodeViewModel();
  isEditMode = false;
  title: string;
  modalStringReply: string;
  genericMissingValueMessage = "This is a required field";
  submitClicked = false;
  businessUnits = ['All'];

  constructor(
    private esraApiClient: EsraPipingAPIClient, 
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getBranchTableCodeFromLocalStorage();
    this.initializeFormControls();
    this.title = this.getTitle();
    this.esraApiClient.getAllBusinessUnits()
      .pipe(
        first(),
        tap(businessUnits => 
          this.businessUnits = this.businessUnits.concat(businessUnits)
        )
      ).subscribe();
  }

  getTitle(): string {
    if (this.isEditMode) {
      return 'Edit branch table code';
    }
    return 'Create branch table code';
  }

  getBranchTableCodeFromLocalStorage(): void {
    const storedBranchTableCode = localStorage.getItem('branchTableCode');
    if (storedBranchTableCode) {
      this.branchTableCodeViewModel = JSON.parse(storedBranchTableCode);
      localStorage.removeItem('branchTableCode');
      this.isEditMode = true;
    }
  }

  initializeFormControls(): void {
    if (this.isEditMode) {
      this.inputFormGroup = this.formBuilder.group({
        code: new FormControl({ value: this.branchTableCodeViewModel.code, disabled: true }, [Validators.required]),
        description: new FormControl(this.branchTableCodeViewModel.description, [Validators.required]),
      });
    } else {
      this.inputFormGroup = this.formBuilder.group({
        code: new FormControl(this.branchTableCodeViewModel.code, [Validators.required]),
        description: new FormControl(this.branchTableCodeViewModel.description, [Validators.required]),
      });
    }
  }

  save() {
    this.submitClicked = true;
    if (this.inputFormGroup.valid) {
      this.updateModelFromForm();

      if (this.isEditMode === false) {
        this.createBranchTableCode();
      }
      else if (this.isEditMode) {
        this.updateBranchTableCode();
      }
    } else {
      this.inputFormGroup.markAllAsTouched();
    }
  }

  updateModelFromForm() {
    this.branchTableCodeViewModel.code = this.inputFormGroup?.get('code').value;
    this.branchTableCodeViewModel.description = this.inputFormGroup?.get('description').value;
    this.branchTableCodeViewModel.description = this.inputFormGroup?.get('description').value;
  }

  private createBranchTableCode() {
    this.esraApiClient.createBranchTableCode(this.branchTableCodeViewModel).subscribe({
      next: () => {
        this.snackBarService.showInfo(
          `Branch table code ${this.branchTableCodeViewModel.code} updated successfully`
        );
        this.router.navigate(['piping/dictionaries/branchTableCodes']);
      },
      error: (err) => {
        this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error')
      }
    })
  }

  private updateBranchTableCode() {
    this.esraApiClient.updateBranchTableCode(this.branchTableCodeViewModel).subscribe({
      next: () => {
        this.snackBarService.showInfo(
          `Branch table code ${this.branchTableCodeViewModel.code} updated successfully`
        );
        this.router.navigate(['piping/dictionaries/branchTableCodes']);
      },
      error: (err) => {
        this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error')
      }
    });
  }

  protected closeWindow() {
    window.close();
  }
}