// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-checkbox {
  display: flex;
  align-content: flex-start;
  margin: 10px 8px auto 0;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
}
.mat-mdc-checkbox .mat-checkbox-layout {
  white-space: normal !important;
}
.mat-mdc-checkbox .mat-checkbox-inner-container {
  margin-top: 5px;
}

.action-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10% 0;
}

.action-selection-buttons {
  display: flex;
  padding: 2% 0 10% 0;
}

.modified-mc-content {
  height: 48px;
  font-family: "Gotham", Arial, sans-serif;
  margin-left: 10%;
  margin-right: 10%;
}

.archive-container {
  display: flex;
  margin-top: 3%;
}

.progress-bar-container {
  min-height: 6px;
  margin: 2% 3%;
}
.progress-bar-container .searching-progress-bar {
  height: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
