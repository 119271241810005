import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilterableProperty } from 'src/app/shared/models/autogenerated-coating';

@Injectable({
  providedIn: 'root',
})
export class FilterDataService implements OnDestroy {
  private technologySelection: string;
  private filtersSelection: Map<string, FilterableProperty>;
  private technologySelectionSubject = new BehaviorSubject<string>('coatings');
  private filtersSubject = new BehaviorSubject<Map<string, FilterableProperty>>(
    new Map<string, FilterableProperty>()
  );

  currentTechnologySelection$ = this.technologySelectionSubject.asObservable();
  currentFiltersSelection$ = this.filtersSubject.asObservable();

  constructor() {
    this.filtersSelection = new Map<string, FilterableProperty>();
  }

  ngOnDestroy() {
    this.technologySelectionSubject.unsubscribe();
    this.filtersSubject.unsubscribe();
  }

  changeTechnologySelection(newTechnologySelection: string) {
    if (newTechnologySelection !== this.technologySelection) {
      this.technologySelection = newTechnologySelection;
      this.technologySelectionSubject.next(newTechnologySelection);
    }
  }

  setFilterValue(filterableProperty: FilterableProperty) {
    this.filtersSelection.set(
      filterableProperty.propertyName,
      filterableProperty
    );
    
    this.filtersSubject.next(this.filtersSelection);
  }

  removeAllFilters() {
    this.filtersSelection.clear();
    this.filtersSubject.next(this.filtersSelection);
  }

  removeAllFiltersDoNotEmit() {
    this.filtersSelection.clear();
  }

  removeFilterValue(filterablePropertyName: string) {
    this.filtersSelection.delete(filterablePropertyName);
    this.filtersSubject.next(this.filtersSelection);
  }

  removeSetOfFilters(filterablePropertyNames: string[]) {
    filterablePropertyNames.forEach(name => this.filtersSelection.delete(name));
    this.filtersSubject.next(this.filtersSelection);
  }
}
