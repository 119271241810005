import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { API_BASE_URL} from './models/autogenerated-piping';
import { environment } from 'src/environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CelsiusToFahrenheitPipe } from './pipes/celcius-to-fahrenheit.pipe';
import { AddToWorkspaceComponent } from './components/buttons/add-to-workspace/add-to-workspace.component';
import { WeldingProductPanelComponent } from './components/product-panel/welding-product-panel/welding-product-panel.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { GramPerLiterToPoundPerGallonPipe } from './pipes/gramperliter-to-poundpergallon.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { CoatDetailComponent } from './components/coat-detail/Coat-Detail.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressSpinnerDialogComponent } from './components/progress-spinner-dialog/progress-spinner-dialog.component';
import { CoatingSolutionPanelComponent } from './components/coating-solution-panel/coating-solution-panel.component';
import { CoatingSolutionDetailComponent } from './dialogs/Coating-Solution-Detail/coating-solution-detail.component';
import { FahrenheitToCelsiusPipe } from './pipes/fahrenheit-to-celcius.pipe';
import { CoatOrderService } from './services/coat-order.service';
import { NewCoatingRevisionComponent } from './dialogs/coating-revisions/new-coating-revision.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { GraphService } from '../core/services/graph.service';
import { AzureAdSearchComponent } from './components/azure-ad-search/azure-ad-search.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatListModule } from '@angular/material/list';
import { EditRevisionApprovalComponent } from './components/edit-approvals/edit-revision-approval/edit-revision-approval.component';
import { RevisionApprovalComponent } from './components/edit-approvals/revision-approval/revision-approval.component';
import { CapitalizeFirstLetter } from './pipes/capitalize-first-letter.pipe';
import { ApproverTypePipe } from '../revisions/pipes/approver-type.pipe';
import { EditApprovalsComponent } from './components/edit-approvals/edit-approvals.component';
import { WorkflowStatusBarComponent } from './components/workflow-status-bar/workflow-status-bar.component';
import { AutoFocus } from './directives/auto-focus.directive';
import { BranchTableDisplayComponent } from './components/branch-table-display/branch-table-display.component';
import { BranchTableDisplayFooterComponent } from './components/branch-table-display-footer/branch-table-display-footer.component';
import { EnvironmentalIndicatorComponent } from './components/environmental-indicator/environmental-indicator.component';
import { SnackBarService } from './services/snack-bar.service';
import { PsigToKpaPipe } from './pipes/psig-to-kpa.pipe';
import { KpaToPsigPipe } from './pipes/kpa-to-psig.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PipeClassPanelComponent } from './components/pipeclass-panel/pipeclass-panel.component';
import { ValueOrDashDirective } from './directives/value-or-dash.directive';
import { MatPaginatorGoToComponent } from './components/mat-paginator-go-to/mat-paginator-go-to.component';
import { InsightsService } from './services/insights.service';
import { DecimalToRationalPipe } from './pipes/decimal-to-rational.pipe';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';

export const COATINGS = 'Coatings';
export const WELDING = 'Welding';
export const PIPING = 'Piping';
@NgModule({
  declarations: [
    PaginatorComponent,
    CelsiusToFahrenheitPipe,
    FahrenheitToCelsiusPipe,
    GramPerLiterToPoundPerGallonPipe,
    YesNoPipe,
    WeldingProductPanelComponent,
    AddToWorkspaceComponent,
    CoatDetailComponent,
    ProgressSpinnerDialogComponent,
    CoatingSolutionPanelComponent,
    CoatingSolutionDetailComponent,
    NewCoatingRevisionComponent,
    AzureAdSearchComponent,
    EditRevisionApprovalComponent,
    RevisionApprovalComponent,
    CapitalizeFirstLetter,
    ApproverTypePipe,
    EditApprovalsComponent,
    WorkflowStatusBarComponent,
    AutoFocus,
    PsigToKpaPipe,
    KpaToPsigPipe,
    BranchTableDisplayComponent,
    BranchTableDisplayFooterComponent,
    PipeClassPanelComponent,
    EnvironmentalIndicatorComponent,
    ValueOrDashDirective,
    MatPaginatorGoToComponent,
    DecimalToRationalPipe,
    SlideToggleComponent
  ],
  providers: [
    { provide: API_BASE_URL, useValue: environment.coatingsApiEndpoint },
    CoatOrderService,
    GraphService,
    SnackBarService,
    InsightsService
  ],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatSliderModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTabsModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTableModule,
    MatInputModule,
    OverlayModule,
    MatListModule,
    MatPaginatorModule,
  ],
  exports: [
    PaginatorComponent,
    CelsiusToFahrenheitPipe,
    FahrenheitToCelsiusPipe,
    GramPerLiterToPoundPerGallonPipe,
    YesNoPipe,
    WeldingProductPanelComponent,
    AddToWorkspaceComponent,
    ProgressSpinnerDialogComponent,
    CoatingSolutionPanelComponent,
    ApproverTypePipe,
    AzureAdSearchComponent,
    EditRevisionApprovalComponent,
    CapitalizeFirstLetter,
    EditApprovalsComponent,
    WorkflowStatusBarComponent,
    AutoFocus,
    BranchTableDisplayComponent,
    BranchTableDisplayFooterComponent,
    PsigToKpaPipe,
    PipeClassPanelComponent,
    EnvironmentalIndicatorComponent,
    ValueOrDashDirective,
    MatPaginatorGoToComponent,
    DecimalToRationalPipe,
    SlideToggleComponent
  ],
})
export class SharedModule {}
