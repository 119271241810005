import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Observable } from 'rxjs';
import {
  CoatingProductViewModel,
  EsraCoatingsAPIClient,
  ProductServiceTemperatureViewModel,
} from 'src/app/shared/models/autogenerated-coating';
import { FahrenheitToCelsiusPipe } from 'src/app/shared/pipes/fahrenheit-to-celcius.pipe';
import { PoundPerGallonToGramPerLiterPipe } from 'src/app/shared/pipes/poundpergallon-to-gramperliter.pipe';
import { AddCoatingProductDialogueComponent } from '../add-coating-product/add-coating-product-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-create-coating-product',
  templateUrl: './create-coating-product.component.html',
  styleUrls: ['./create-coating-product.component.scss'],
})
export class CreateCoatingProductComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddCoatingProductDialogueComponent>,
    private esraCoatingClient: EsraCoatingsAPIClient,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder
  ) {}

  addFormGroup: UntypedFormGroup;
  primary: ThemePalette = 'primary';
  revisionId: number;
  receivedForm: UntypedFormGroup;

  // selection list observables
  manufacturers$: Observable<string[]> =
    this.esraCoatingClient.getAllManufacturers();

  newCoatingProduct: CoatingProductViewModel;

  // farenheit, celcius control.
  tempUnitOptions = ['fahrenheit', 'celcius'];
  tempUnit = '';
  celciusLabel = '°C';
  fahrenheitLabel = '°F';
  tempLabel = '';
  vocMetric: boolean;
  temperatureMetric: boolean;

  ngOnInit(): void {
    this.newCoatingProduct = new CoatingProductViewModel();
    if (this.data) {
      this.revisionId = this.data.revisionId ? this.data.revisionId : null;
    }

    this.addFormGroup = this._formBuilder.group({
      tempUnitOption: ['', Validators.required],
      name: ['', Validators.required],
      manufacturer: ['', Validators.required],
      serviceTempMaxC: [0, Validators.required],
      minGramsPerLiterVOC: [0],
      maxGramsPerLiterVOC: [0],
      dft: ['', Validators.required],
      steamOutC: ['', Validators.required],
      cureToImmersion: ['', Validators.required],
      liningType: ['', Validators.required],
      productNotes: ['', Validators.required],
      productServiceTemperatures: this._formBuilder.array([])
    });
  }

  updateReceivedFormGroup(receivedForm: UntypedFormGroup) {
    this.receivedForm = receivedForm;
  }

  temperatureToggleChange(event: MatSelectChange) {
    if (this.tempLabel === '') {
      this.vocMetric = event.value === 'celcius' ? true : false;
    }

    this.temperatureMetric = event.value === 'celcius' ? true : false;
    this.tempUnit = event.value;

    this.tempLabel =
      event.value === 'celcius' ? this.celciusLabel : this.fahrenheitLabel;
  }

  vocToggleChange(event: MatSlideToggleChange) {
    this.vocMetric = event.checked;
  }

  addProductServiceTemperature(service, temperature, productServiceTemperatureVMs) {
    let serviceId;

    const newProductServiceTempVM = new ProductServiceTemperatureViewModel({
      serviceId: serviceId,
      service: service,
      coatingProductId: this.newCoatingProduct.coatingProductId,
      serviceTempMaxC: temperature
    });

    productServiceTemperatureVMs.push(newProductServiceTempVM);
  }
  

  createProduct(): void {
    if (!this.addFormGroup.valid) {
      alert('Required fields missing values.');
      return;
    }

    if (this.receivedForm) {
      const newProductServiceTempVMs: ProductServiceTemperatureViewModel[] = [];

      let numOfServiceNames = 0;

      Object.keys(this.receivedForm.controls).forEach(key => {
        if (key.includes('temperature')) {
          numOfServiceNames++;
        }
      })

      for (let i=0; i < numOfServiceNames; i++) {
        const serviceToAdd = this.receivedForm.get('service' + i.toString()).value;
        const tempToAdd = this.receivedForm.get('temperature' + i.toString()).value;

        if (serviceToAdd !== null && tempToAdd !== null) {
          this.addProductServiceTemperature(serviceToAdd, tempToAdd, newProductServiceTempVMs);
        }
      }

      this.newCoatingProduct.productServiceTemperatures = newProductServiceTempVMs;
    }

    this.newCoatingProduct = Object.assign({},this.addFormGroup.value);

    if (this.tempLabel === this.fahrenheitLabel) {
      const fahrenheitToCelcius = new FahrenheitToCelsiusPipe();
      this.newCoatingProduct.serviceTempMaxC = fahrenheitToCelcius.transform(
        +this.newCoatingProduct.serviceTempMaxC
      );

      this.newCoatingProduct.steamOutC = fahrenheitToCelcius.transform(
        +this.newCoatingProduct.steamOutC
      );

      if (this.newCoatingProduct.productServiceTemperatures) {
        this.newCoatingProduct.productServiceTemperatures.forEach(
          (x) =>
            (x.serviceTempMaxC = fahrenheitToCelcius.transform(
              +x.serviceTempMaxC
            ))
        );
      }
    }

    if (!this.vocMetric) {
      const vocPipe = new PoundPerGallonToGramPerLiterPipe();

      this.newCoatingProduct.minGramsPerLiterVOC = vocPipe.transform(
        +this.newCoatingProduct.minGramsPerLiterVOC
      );

      this.newCoatingProduct.maxGramsPerLiterVOC = vocPipe.transform(
        +this.newCoatingProduct.maxGramsPerLiterVOC
      );
    }
    
    this.esraCoatingClient
      .addNewCoatingProductToRevision(this.revisionId, this.newCoatingProduct)
      .subscribe(
        {
          next: () => {
            this.dialogRef.close();
            this.snackBarService.showSnackBar(true, this.newCoatingProduct.name + " added to revision");
          },
          error: error => {
            alert(error.response);
          }
        }
      );
      
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
