import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss'],
})
export class TextFilterComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  numeric: boolean;
  @Input()
  options: Array<string>;
  @Input()
  inputPlaceholder: string;
  @Input()
  selectedOptionString: string;
  @Output()
  public thickness = new EventEmitter<string>();
  @Output()
  public selectedOption = new EventEmitter<string>();

  inputText: string;
  warning: string;

  _defaultPlaceholder = 'thickness in inches';
  _defaultSelectedOptionString = '=';

  ngOnInit() {
    if (this.inputPlaceholder == null) {
      this.inputPlaceholder = this._defaultPlaceholder;
    }
    if (this.selectedOptionString == null) {
      this.selectedOptionString = this._defaultSelectedOptionString;
    }
  }

  applyFilter() {
    let strInputText = this.inputText.toString();

    this.selectedOption.emit(this.selectedOptionString);
    if (strInputText.trim().startsWith('.')) {
      strInputText = '0' + strInputText;
    }
    
    const numeric = Number(strInputText);

    if (isNaN(numeric)) {
      this.warning = 'value must be a number';
    } else {
      this.warning = null;
      this.thickness.emit(strInputText);
    }
  }
  
  onSelection(eventValue: string) {
    this.selectedOptionString = eventValue;
  }
}