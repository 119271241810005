// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-conatiner {
  display: flex;
  flex-direction: column;
  gap: 10%;
  justify-content: center;
}

.title {
  margin-top: 5%;
  text-align: center;
  font-weight: bold;
  font-size: 20pt;
}

.subtitle {
  text-align: center;
  font-size: 10pt;
}

.comment-conatiner {
  display: flex;
  flex-direction: column;
  margin: 5% 5%;
}
.comment-conatiner textarea {
  height: 30%;
}

.buttons-container {
  display: flex;
  margin-top: 2%;
  justify-content: flex-end;
}

.progress-bar-container {
  min-height: 6px;
  margin: 2% 3%;
}
.progress-bar-container .saving-progress-bar {
  height: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
