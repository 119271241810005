import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { first } from 'rxjs/operators';
import { WeldingProcedureViewModel, EsraWeldingAPIClient } from 'src/app/shared/models/autogenerated-welding';
import { InchesTomillimetersPipe } from 'src/app/shared/pipes/inches-to-millimeters.pipe';
import { MillimetersToInchesPipe } from 'src/app/shared/pipes/millimeters-to-inches.pipe';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { notNullOrEmpty } from 'src/app/shared/utils';
import { WeldingRevisionSummaryComponent } from '../../pages/welding-revision-summary/welding-revision-summary.component';

@Component({
  selector: 'app-update-welding-procedure',
  templateUrl: './update-welding-procedure.component.html',
  styleUrls: ['./update-welding-procedure.component.scss'],
  providers: [InchesTomillimetersPipe, MillimetersToInchesPipe],
})
export class UpdateWeldingProcedureComponent implements OnInit {
  constructor(
    private esraWeldingApiClient: EsraWeldingAPIClient,
    public dialogRef: MatDialogRef<WeldingRevisionSummaryComponent>,
    private inchesTomillimetersPipe: InchesTomillimetersPipe,
    private millimetersToInchesPipe: MillimetersToInchesPipe,
    private snackBarService: SnackBarService
  ) {}

  @Input()
  weldingProcedure: WeldingProcedureViewModel;

  @Input()
  closeDialogOnSave= true;

  @Input()
  cancelButtonVisible = false;

  @Output() updated = new EventEmitter();

  impactTemperatureFahrenheit: UntypedFormArray;
  weldProcesses: UntypedFormArray;
  electrodes: UntypedFormArray;

  jointDesigns: string[];
  jointDesignOptions = [];

  isMinimumThicknessMetric = false;
  isMaximumThicknessMetric = false;

  weldPropertiesForm: UntypedFormGroup;

  regexThickness = "^-?\\d*(\\.\\d+)?$";
  regexImpactTemperature = "^-?[0-9]*$";

  ngOnInit(): void {
    this.weldPropertiesForm = new UntypedFormGroup({
      baseMetalPNo1: new UntypedFormControl(this.weldingProcedure.baseMetalPNo1, [Validators.nullValidator]),
      baseMetalPNo2: new UntypedFormControl(this.weldingProcedure.baseMetalPNo2, [Validators.nullValidator]),
      minThickness: new UntypedFormControl(this.weldingProcedure.thicknessMinInch, [Validators.pattern(this.regexThickness)]),
      maxThickness: new UntypedFormControl(this.weldingProcedure.thicknessMaxInch, [Validators.pattern(this.regexThickness)]),
      impactTemperatureFahrenheit: new UntypedFormArray([]),
      weldProcesses: new UntypedFormArray([]),
      electrodes: new UntypedFormArray([])
    },
    { validators: [this.validateMinThicknessRelations(), this.validateMaxThicknessRelations()]}
    );

    this.weldingProcedure.impactTemperatureFahrenheit
      .forEach(impactTemperature => (this.weldPropertiesForm.get('impactTemperatureFahrenheit') as UntypedFormArray)
      .push(new UntypedFormControl(impactTemperature, [Validators.pattern(this.regexImpactTemperature)])));

    this.weldingProcedure.weldProcess
      .forEach(impactTemperature => (this.weldPropertiesForm.get('weldProcesses') as UntypedFormArray)
      .push(new UntypedFormControl(impactTemperature, [Validators.nullValidator])));

    this.weldingProcedure.electrodes
      .forEach(impactTemperature => (this.weldPropertiesForm.get('electrodes') as UntypedFormArray)
      .push(new UntypedFormControl(impactTemperature, [Validators.nullValidator])));

    this.impactTemperatureFahrenheit = this.weldPropertiesForm.get('impactTemperatureFahrenheit') as UntypedFormArray;
    this.weldProcesses = this.weldPropertiesForm.get('weldProcesses') as UntypedFormArray;
    this.electrodes = this.weldPropertiesForm.get('electrodes') as UntypedFormArray;
    
    this.jointDesigns = this.weldingProcedure.jointDesigns;
    this.esraWeldingApiClient.getJointDesignsOptions()
      .pipe(first())
      .subscribe(response => this.jointDesignOptions = response);    

    if(!this.weldPropertiesForm.valid) {
      this.weldPropertiesForm.markAllAsTouched();
    }
  }

  check() {
    console.log(this.weldPropertiesForm.controls['maxThickness'].errors);
  }

  updateWeldingProcedure() {

    this.weldingProcedure.baseMetalPNo1 = this.weldPropertiesForm.get('baseMetalPNo1').value;
    this.weldingProcedure.baseMetalPNo2 = this.weldPropertiesForm.get('baseMetalPNo2').value;

    if(this.impactTemperatureFahrenheit) {
      this.weldingProcedure.impactTemperatureFahrenheit = this.impactTemperatureFahrenheit.controls
          .map(tempControl => tempControl.value)
          .filter(temperature => notNullOrEmpty(temperature));
    }

    if(this.weldProcesses) {
      this.weldingProcedure.weldProcess = this.weldProcesses.controls
          .map(weldControl => weldControl.value)
          .filter(weldProcess => notNullOrEmpty(weldProcess));
    }

    if(this.electrodes) {
      this.weldingProcedure.electrodes = this.electrodes.controls
          .map(electrodeControl => electrodeControl.value)
          .filter(electrode => notNullOrEmpty(electrode));
    }

    if(this.jointDesigns) {
      this.weldingProcedure.jointDesigns = this.jointDesigns;
    }

    this.esraWeldingApiClient
      .updateWpsInRevision(
        this.weldingProcedure.weldingProcedureId,
        this.weldingProcedure
      )
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.updated.emit(response);
          this.snackBarService.showSnackBar(true, 'Welding Procedure Updated Successfully', 'mat-snack-bar-info');
          if (this.closeDialogOnSave) {
            this.dialogRef.close();
          }
        },
        error: (err) => {
          this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error');
        }
    });
  }

  getThicknessInSelectedUnits(isMetric: boolean, thickness: string): string {
    if(notNullOrEmpty(thickness)) {
      const numericValue = Number(thickness);
      if(numericValue === undefined || Number.isNaN(numericValue)) {
        return thickness;
      }

      if (isMetric) {
        return this.inchesTomillimetersPipe.transform(numericValue).toFixed(0);
      } else {
        return thickness;
      }
    }
    
    if(thickness !== undefined){
      return thickness;
    } else {
      return '';
    }
  }

  maxThickness() {
    return this.weldPropertiesForm.get('maxThickness');
  }

  toggleElementById(event: MatSlideToggleChange) {
    if(this[event.source.id] !== undefined){
      this[event.source.id] = event.checked;
    }
  }

  updateModel(weldingProcedure: WeldingProcedureViewModel, propertyName: string, value: string, isMetric: boolean): void {
      if(!notNullOrEmpty(value)) {
        weldingProcedure[propertyName] = undefined;
        return ;
      }

      weldingProcedure[propertyName] = this.getImperialValue(value, isMetric);
  }

  private getImperialValue( value: string, isMetric: boolean): string {
    const numericValue = Number(value);
    if(numericValue !== undefined && !Number.isNaN(numericValue)) {
      if(isMetric) {
          const imperial = this.millimetersToInchesPipe.transform(numericValue);
          return parseFloat(imperial.toFixed(3)).toString();
      } 
      else {
        return parseFloat(numericValue.toFixed(3)).toString();
      }
    } else {
     return value;
    }
  }

  addWeldProcess(): void {
    this.weldProcesses.push(new UntypedFormControl(''));
  }

  addImpactTemp(): void {
    this.impactTemperatureFahrenheit.push(new UntypedFormControl('', [Validators.pattern(this.regexImpactTemperature)]));
  }

  addElectrode(): void {
    this.electrodes.push(new UntypedFormControl(''));
  }

  removeItem(componentToRemoveFrom: UntypedFormArray, index: number) {
    componentToRemoveFrom.removeAt(index);
  }

  validateMinThicknessRelations() {
    return (form: UntypedFormGroup) => {
      const minThickness = this.getImperialValue(form.get('minThickness').value, this.isMinimumThicknessMetric);
      const maxThickness = this.getImperialValue(form.get('maxThickness').value, this.isMaximumThicknessMetric);
      
      const maxThicknessNumber = Number(maxThickness);

      let minThicknessErrors: ValidationErrors = form.get('minThickness').errors;

      if(!minThicknessErrors) {
        minThicknessErrors = {};
      }

      if(!minThickness && maxThickness && !Number.isNaN(maxThicknessNumber)) {
        minThicknessErrors['minThickness'] = true;
      } else {
        delete minThicknessErrors['minThickness'];
      }
      
      if(Object.keys(minThicknessErrors).length === 0) {
        minThicknessErrors = undefined;
      }

      form.get('minThickness').setErrors(minThicknessErrors);

      return minThicknessErrors;
    };
  }

  validateMaxThicknessRelations() {
    return (form: UntypedFormGroup) => {
      const minThickness = this.getImperialValue(form.get('minThickness').value, this.isMinimumThicknessMetric);
      const maxThickness = this.getImperialValue(form.get('maxThickness').value, this.isMaximumThicknessMetric);
      
      const maxThicknessNumber = Number(maxThickness);
      const minThicknessNumber = Number(minThickness);

      let maxThicknessErrors = form.get('maxThickness').errors

      if(!maxThicknessErrors) {
        maxThicknessErrors = {};
      }

      if(maxThicknessNumber) {
        if( minThickness && !Number.isNaN(minThicknessNumber) && minThicknessNumber >= maxThicknessNumber) {
          maxThicknessErrors['less'] = true;
        } else {
          delete maxThicknessErrors['less'];
        }
      }

      if(Object.keys(maxThicknessErrors).length === 0) {
        maxThicknessErrors = undefined;
      }

      form.get('maxThickness').setErrors(maxThicknessErrors);

      return maxThicknessErrors;
    };
  }

  cancelAction() {
    this.dialogRef.close();
  }
}