import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NextpageModule } from '../nextpage/nextpage.module';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { SharedModule } from '../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MyWorkspaceComponent } from './pages/my-workspace/my-workspace.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    MyWorkspaceComponent
  ],
  imports: [
    CommonModule,
    NextpageModule,
    FormsModule,
    FontAwesomeModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatGridListModule,
    SharedModule,
    MatDialogModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    NgxSliderModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTabsModule,
    MatTableModule
  ],
  exports: [],
  providers: [],
})
export class MyWorkspaceModule {}
