import { Component } from '@angular/core';

@Component({
  selector: 'app-dictionaries',
  templateUrl: './dictionaries.component.html',
  styleUrls: ['./dictionaries.component.scss']
})
export class DictionariesComponent {
  navigationLinks = [
    {
      label: 'common notes',
      path: '/piping/dictionaries/commonnotes',
      index: 0
    },
    {
      label: 'services',
      path: '/piping/dictionaries/services',
      index: 1
    },
    {
      label: 'service details',
      path: '/piping/dictionaries/servicedetails',
      index: 2
    },
    {
      label: 'pressure and temp tables',
      path: '/piping/dictionaries/pressureandtemperature',
      index: 3
    },
    {
      label: 'branch tables',
      path: '/piping/dictionaries/branch',
      index: 4
    },
    {
      label: 'branch table codes',
      path: '/piping/dictionaries/branchTableCodes',
      index: 5
    },
    {
      label: 'component catalog',
      path: '/piping/dictionaries/componentcatalog',
      index: 6
    }
  ];
  activeLink = this.navigationLinks[0].path; 
}
