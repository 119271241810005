import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import {
  CoatingsSearchFilterModel,
  CoatingSystemSolutionViewModel,
  EsraCoatingsAPIClient,
  SearchRequest,
  TechnologyStandardViewModel,
  FilterableProperty,
  FilterType,
} from 'src/app/shared/models/autogenerated-coating';
import { EsraSearchFilterModel } from 'src/app/shared/models/autogenerated-welding';
import { RangeFilterCategory } from '../../filters/range-filter/range-filter.component';
import { FilterDataService } from '../../services/filter.service';
import { RangeFilterHelper } from '../common/range-filter.helper';

@Component({
  selector: 'app-coating-solution-search',
  templateUrl: './coating-solution-search.component.html',
  styleUrls: ['./coating-solution-search.component.scss'],
})
export class CoatingSolutionSearchComponent implements OnInit, OnDestroy {
  constructor(
    private esraCoatingClient: EsraCoatingsAPIClient,
    private filterDataService: FilterDataService,
    public rangeFilterHelper: RangeFilterHelper
  ) {
    rangeFilterHelper.addRangeFilterCategory(
      'ServiceTempMaxC',
      RangeFilterCategory.TEMPERATURE
    );
    rangeFilterHelper.addRangeFilterCategory(
      'MinGramsPerLiterVOC',
      RangeFilterCategory.DENSITY
    );
  }

  faSearch = faSearch;
  searchText = '';
  currentPage = 0;
  lastPage: number;
  totalCount: number;
  pageSize = 10;
  _all = 'all';

  technologySpecifications$: Observable<TechnologyStandardViewModel[]> =
    this.getTechnologyStandards();
  techSpecSubject = new BehaviorSubject<string>(this._all);
  techSpec$ = this.techSpecSubject.asObservable();
  selectedTechSpec: string = this._all;

  filtersForTechSpec$: Observable<Array<CoatingsSearchFilterModel>> =
    this.getTechnologySpecFilters();

  facetValues = new BehaviorSubject<Array<EsraSearchFilterModel>>(
    new Array<EsraSearchFilterModel>()
  );
  facetValues$ = this.facetValues.asObservable();

  latestFilterSelection$ = new BehaviorSubject<string>('');

  filters$: Observable<Array<CoatingsSearchFilterModel>> =
    this.getCoatingFilters();

  coatingSolutions$: Observable<CoatingSystemSolutionViewModel[]>;

  filterableProperties: FilterableProperty[];
  private readonly subscription = new Subscription();

  // indicator subjects
  searching$ = new BehaviorSubject<boolean>(false);
  loadingFilters$ = new BehaviorSubject<boolean>(false);
  exportingPdf$ = new BehaviorSubject<boolean>(false);

  pageSizes = [2, 5, 10, 25, 50, 100];
  @ViewChild('paginator') paginator: MatPaginator;

  ngOnInit(): void {
    this.filterDataService.removeAllFilters();
    this.subscription.add(
      this.filterDataService.currentFiltersSelection$
        .pipe(
          map((filterSelection) => {
            this.filterableProperties = Array.from(filterSelection.values());
            this.currentPage = 0;
            this.search();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getTechnologyStandards(): Observable<TechnologyStandardViewModel[]> {
    return this.esraCoatingClient.getTechnologyStandards();
  }

  search(): void {
    const request: SearchRequest = new SearchRequest();
    request.perPage = this.pageSize;
    request.page = this.currentPage;
    request.mainSearch = this.searchText;
    request.filters = this.filterableProperties;
    this.subscription.add(
      this.esraCoatingClient
        .searchCoatingSolutions(request)
        .pipe(
          indicate(this.searching$),
          tap((response) => {
            this.coatingSolutions$ = of(response.results);
            this.facetValues.next(response.searchFilters);
            this.totalCount = response.totalResults;
            this.lastPage = Math.ceil(this.totalCount / this.pageSize);
          })
        )
        .subscribe()
    );
  }

  callSearch(e) {
    if (e.keyCode == 13) this.search();
  }

  updateTechSpecSelection(): void {
    this.currentPage = 0;
    this.techSpecSubject.next(this.selectedTechSpec);

    if (this.selectedTechSpec != this._all) {
      this.filterDataService.removeAllFiltersDoNotEmit();
      const filterableProperty = this.createFilterbleProperty(
        0,
        'TechnologyStandard',
        'Technology Standard'
      );
      filterableProperty.filterOptions = [this.selectedTechSpec];
      this.filterDataService.setFilterValue(filterableProperty);
    } else {
      this.filterDataService.removeAllFilters();
    }
  }

  resetFilters(): void {
    this.selectedTechSpec = this._all;
    this.updateTechSpecSelection();
  }

  private createFilterbleProperty(
    filterType: FilterType,
    propertyName: string,
    filterDisplayName: string
  ): FilterableProperty {
    const filterableProperty = new FilterableProperty();
    filterableProperty.filterType = filterType;
    filterableProperty.propertyName = propertyName;
    filterableProperty.displayName = filterDisplayName;
    return filterableProperty;
  }

  private getTechnologySpecFilters(): Observable<
    Array<CoatingsSearchFilterModel>
  > {
    return combineLatest([this.techSpec$]).pipe(
      switchMap(([spec]) => {
        if (spec == this._all) {
          spec = '';
        }
        return this.esraCoatingClient
          .getSearchFilters(spec)
          .pipe(indicate(this.loadingFilters$));
      })
    );
  }

  private getCoatingFilters(): Observable<Array<CoatingsSearchFilterModel>> {
    return combineLatest([
      this.facetValues$,
      this.filtersForTechSpec$,
      this.latestFilterSelection$,
    ]).pipe(
      map((values) => {
        const facets = values[0];
        const filters = values[1];

        filters.forEach((f) => {
          if (f.filterPropertyName != values[2]) {
            const matchingFacets = facets.filter(
              (x) => x.filterPropertyName === f.filterPropertyName
            );
            if (matchingFacets[0] != null) {
              f.options = matchingFacets[0].options;
              f.minValue = matchingFacets[0].minValue;
              f.maxValue = matchingFacets[0].maxValue;

              f.options.sort((a, b) =>
                a.localeCompare(b, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                })
              );
            }
          }
        });
        return filters;
      })
    );
  }

  popClearedFilter($event, filterPropertyName: string) {
    if (this.latestFilterSelection$.getValue() === filterPropertyName) {
      this.latestFilterSelection$.next('');
    }
  }

  updateCheckboxFilterSelection(
    event: Set<string>,
    filterablePropertyName: string,
    filterDiplayName: string
  ) {
    this.latestFilterSelection$.next(filterablePropertyName);
    this.currentPage = 0;
    if (event.size === 0) {
      this.filterDataService.removeFilterValue(filterablePropertyName);
    } else {
      const filterableProperty = this.createFilterbleProperty(
        1,
        filterablePropertyName,
        filterDiplayName
      );
      filterableProperty.filterOptions = Array.from(event);
      this.paginator.firstPage();
      this.filterDataService.setFilterValue(filterableProperty);
    }
  }

  updateSingleSliderFilterSelection(
    event: string,
    filterablePropertyName: string,
    filterType: string
  ) {
    this.latestFilterSelection$.next(filterablePropertyName);
    this.currentPage = 0;
    let filterTypeNum = 2; // default value for minimum

    if (filterType === 'MAXIMUM') {
      filterTypeNum = 3;
    }

    const filterableProperty = this.createFilterbleProperty(
      filterTypeNum,
      filterablePropertyName,
      filterablePropertyName
    );
    filterableProperty.filterOptions = [event];
    this.filterDataService.setFilterValue(filterableProperty);
  }

  public exportAllToPdf() {
    const request: SearchRequest = new SearchRequest();
    request.perPage = this.pageSize;
    request.page = this.currentPage;
    request.mainSearch = this.searchText;
    request.filters = this.filterableProperties;

    this.subscription.add(
      this.esraCoatingClient
        .generateApplicationReportPDF(request)
        .pipe(
          indicate(this.exportingPdf$),
          tap((response) => {
            const file = response.data;
            const fileUrl = URL.createObjectURL(file);
            window.open(fileUrl);
          }),
          map(() => {
            return;
          })
        )
        .subscribe()
    );
  }

  public updatePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.search();
  }

  public clearSearchText() {
    this.searchText = '';
    this.search();
  }
}
