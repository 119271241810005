import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Client, GraphError } from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { from, Observable, of, combineLatest, firstValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  private graphClient: Client;

  constructor(private msalService: MsalService) {
    // Initialize the Graph client
    this.graphClient = Client.init({
      authProvider: async (done) => {
        // Get the token from the auth service
        const token = await this.getAccessToken().catch((reason) => {
          done(reason, null);
        });

        if (token) {
          done(null, token);
        } else {
          done('Could not get an access token', null);
        }
      },
    });
  }

  // Silently request an access token
  private async getAccessToken(): Promise<string> {
    const account = this.msalService.instance.getAllAccounts()[0];
    const result = await firstValueFrom(this.msalService
      .acquireTokenSilent({
        account: account ?? undefined,
        scopes: ['user.Read'],
      }))
      .catch((reason) => {
        console.log('Get token failed', JSON.stringify(reason, null, 2));
      });

    if (result) {
      return result.accessToken;
    }

    return '';
  }

  searchUsers(searchString: string): Observable<MicrosoftGraph.User[]> {
    if (searchString.length === 0) {
      return of([]);
    }
    const filters = [
      `startsWith(displayName, '${searchString}')`,
      `startsWith(userPrincipalName, '${searchString}')`,
      `startsWith(givenName, '${searchString}')`,
      `startsWith(surname, '${searchString}')`,
    ];
    let filterString = filters.join(' or ');
    if (searchString.includes(' ')) {
      const [name0, name1] = searchString.split(' ').filter((x) => x !== '');
      filterString += ` or (startsWith(givenName, '${name0}') and startsWith(surname, '${name1}'))`;
      filterString += ` or (startsWith(givenName, '${name1}') and startsWith(surname, '${name0}'))`;
    }
    return from(
      this.graphClient
        .api('users')
        .select('displayName,userPrincipalName,jobTitle,id,chevron_worker')
        .filter(filterString)
        .top(10)
        .get()
    ).pipe(
      map((res) => res.value),
      map((users) => users.filter((u) => u.chevron_worker))
    );
  }

  getUsersByOids(oids: string[]): Observable<MicrosoftGraph.User[]> {
    if (oids.length === 0) {
      return of([]);
    }
    //Maximum number of Oids that Graph API can serve at once
    const bulkSize = 15;
    const listOfBulks: Array<string[]> = [];

    for (let i = 0; i < oids.length; i += bulkSize) {
      const bulkOids = oids.slice(i, i + bulkSize);
      listOfBulks.push(bulkOids);
    }

    return combineLatest(
      listOfBulks.map((bulkOids) => {
        const oidListString = bulkOids.map((oid) => `'${oid}'`).join(', ');
        const filterString = `id in (${oidListString})`;
        return from(
          this.graphClient
            .api('users')
            .select(
              'displayName,givenName,surname,mail,id,userPrincipalName,department,companyName,jobTitle'
            )
            .filter(filterString)
            .get()
        ).pipe(
          map((res) => res.value),
          catchError(() => of([]))
        );
      })
    ).pipe(map(arrayOfResultArrays => arrayOfResultArrays.flat()));
  }

  getProfile(): Observable<MicrosoftGraph.User> {
    return from(
      this.graphClient.api('me').select('displayName, jobTitle').get()
    );
  }

  getCurrentUserPhoto(): Observable<Blob> {
    return from(this.graphClient.api('me/photo/$value').get()).pipe(
      catchError(this.handleGraphError)
    );
  }

  getUserPhotoByOid(oid): Observable<Blob> {
    return from(this.graphClient.api(`users/${oid}/photo/$value`).get()).pipe(
      catchError(this.handleGraphError)
    );
  }

  getUserByOid(oid): Observable<MicrosoftGraph.User> {
    return from(
      this.graphClient
        .api('users/' + oid)
        .select(
          'displayName,givenName,surname,mail,id,userPrincipalName,department,companyName, jobTitle'
        )
        .get()
    ).pipe(catchError(this.handleGraphError));
  }

  private handleGraphError(error: GraphError) {
    // Return null if user has no info/photo else throw error
    if (error.statusCode === 404) {
      return of(null);
    }
    throw error;
  }
}
