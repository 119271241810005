import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommentThreadService } from 'src/app/revisions/components/comments-thread/comment-thread.service';
import { first, switchMap, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { indicate } from 'src/app/operators';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-reject-comment',
  templateUrl: './reject-comment.component.html',
  styleUrls: ['./reject-comment.component.scss'],
})
export class RejectCommentComponent implements OnInit {
  revisionId: number;
  title: string;
  subTitle: string;
  authorOid: string;
  saveButtonText = "reject";
  rejectFunction: (revisionId: number) => Observable<any>;
  saving$ = new BehaviorSubject<boolean>(false);

  addFormGroup: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<RejectCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder,
    private _commentsService: CommentThreadService,
    private _snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.revisionId = this.data.revisionId;
      this.title = this.data.title;
      this.subTitle = this.data.subTitle;
      this.authorOid = this.data.authorOid;
      this.rejectFunction = this.data.rejectFunction;
      if (this.data.saveButtonText && this.data.saveButtonText !== "" ) {
        this.saveButtonText = this.data.saveButtonText;
      } 
    }

    this.addFormGroup = this._formBuilder.group({
      comment: ['', Validators.required],
    });
  }

  save() {
    if (this.addFormGroup.valid) {
      combineLatest([this.rejectFunction(this.revisionId), 
                    this._commentsService.addNewComment(this.authorOid, this.addFormGroup.value.comment)])
              .pipe(
                indicate(this.saving$),
                first(),
                switchMap(([revision, comment]) => {
                  if(revision.errorMessage && revision.errorMessage.trim().length > 0) {
                    this._snackBarService.showInfo('Revision rejected. However, ' + revision.errorMessage);
                  }
                  return this._commentsService
                  .addParentCommentReplyOperation(this.revisionId, comment)
                  .pipe(
                    map(addedComment => {
                      comment.id = addedComment.id;
                      comment.isAdd = false;
                      comment.isEdit = false;

                      this._commentsService.publishComment(comment);
                      // This is to update revision with new comment to reduce need of 
                      // calling API to get updated with Comment revision
                      revision.comments.unshift(comment);
                      this.dialogRef.close(revision);

                      return comment;
                    }));
                })
              ).subscribe({
                next: result=> result, 
                error: error => {
                  this._snackBarService.showError(error.response);
                  window.location.reload();
                }
              });
    }
  }
}
