import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { EsraPipingAPIClient, CommonNoteViewModel } from 'src/app/shared/models/autogenerated-piping';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-notes-add-edit',
  templateUrl: './common-notes-add-edit.component.html',
  styleUrls: ['./common-notes-add-edit.component.scss']
})
export class CommonNotesAddEditComponent implements OnInit, AfterViewChecked {
  codeInput: string;
  noteInput: string;
  inputFormGroup: UntypedFormGroup;
  commonNoteViewModelToUpdate: CommonNoteViewModel;
  updateNote: CommonNoteViewModel;
  title: string;
  modalStringReply: string;
  genericMissingValueMessage = "This is a required field";
  
  submitClicked = false;
  isCreateMode = false;
  isEditMode = false;

  constructor(
    private esraApiClient: EsraPipingAPIClient, 
    private snackBarService: SnackBarService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getStoredCommonNote();

    if (this.isCreateMode) {
      this.title = "Create a new common note";
    } else {
      this.title = `Edit common note ${this.commonNoteViewModelToUpdate.codeId}`;
    }

    this.initializeFormControls();
    if (this.isEditMode) {
      this.setFormValues();
    }
  }

  getStoredCommonNote() {
    const storedCommonNote = localStorage.getItem('commonNote');
    if (storedCommonNote) {
      this.commonNoteViewModelToUpdate = JSON.parse(storedCommonNote);
      localStorage.removeItem('commonNote');
      this.isEditMode = true;
    } else {
      this.isCreateMode = true;
    }
  }
  
  ngAfterViewChecked(): void {    
    this.changeDetectorRef.detectChanges();
  }

  initializeFormControls(): void {
    if (this.isEditMode) {
      this.inputFormGroup = new UntypedFormGroup({
        codeControl: new UntypedFormControl({value: this.codeInput, disabled: true}, [Validators.required]),
        noteControl: new UntypedFormControl(this.noteInput, [Validators.required])
      });
    } else {
      this.inputFormGroup = new UntypedFormGroup({
        codeControl: new UntypedFormControl(this.codeInput, [Validators.required]),
        noteControl: new UntypedFormControl(this.noteInput, [Validators.required])
      });
    }
  }

  setFormValues() {
    this.inputFormGroup.setValue({
      codeControl: this.commonNoteViewModelToUpdate.codeId,
      noteControl: this.commonNoteViewModelToUpdate.note
    });
  }

  submitNote() {
    this.submitClicked = true;

    if (this.inputFormGroup.invalid) {
      this.inputFormGroup.markAllAsTouched();
    }

    if (this.inputFormGroup.valid && this.isCreateMode) {
      const commonNoteVM = new CommonNoteViewModel({
        codeId: this.inputFormGroup.get('codeControl').value,
        note: this.inputFormGroup.get('noteControl').value,
        isActive: true
      });

      this.esraApiClient.createCommonNote(commonNoteVM)
        .subscribe({
          next: () => {
            this.snackBarService.showSnackBar(
              true, 
              `Common note ${commonNoteVM.codeId} was created successfully`
            );

            this.router.navigate(['/piping/dictionaries/commonnotes']);
          },
          error: (err) => {
            this.snackBarService.showSnackBar(
              true, 
              err.response, 
              'mat-snack-bar-error'
            );
          }
        });
    }

    if (this.inputFormGroup.valid && this.isEditMode) {
      this.commonNoteViewModelToUpdate.codeId = 
        this.inputFormGroup.get('codeControl').value;

      this.commonNoteViewModelToUpdate.note = 
        this.inputFormGroup.get('noteControl').value;

      this.esraApiClient.createNewCommonNoteVersion(this.commonNoteViewModelToUpdate)
        .subscribe({
          next: () => {
            this.snackBarService
              .showSnackBar(
                true,
                `Common note ${this.commonNoteViewModelToUpdate.codeId} updated successfully`
              );
              this.router.navigate(['/piping/dictionaries/commonnotes']);
          },
          error: (err) => {
            this.snackBarService
              .showSnackBar(true, err.response, 'mat-snack-bar-error')
          }
        });
    }
  }

  closeWindow() {
    window.close();
  }
}
