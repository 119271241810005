import { Component, Input } from '@angular/core';
import { PipeClassViewModel } from 'src/app/shared/models/autogenerated-piping';

@Component({
    selector: 'app-piping-components',
    styleUrls: ['piping-components.component.scss'],
    templateUrl: './piping-components.component.html',
})
export class PipingComponentsComponent {
    @Input() pipeClassViewModelComponents: PipeClassViewModel["componentsInPipeClass"];
    
    getComponentsByGroup(group: string) {
        if (this.pipeClassViewModelComponents && this.pipeClassViewModelComponents.length > 0 ) {
            return this.pipeClassViewModelComponents.filter(componentInPipeClass => componentInPipeClass.component.group == group);
        }
        return [];
    }
}
