// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email {
  color: #8c8f93;
}

.inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
  flex-grow: 1;
}

.input {
  width: 100%;
  min-height: 100%;
  border-radius: 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-selection-list {
  background: white;
}

.cdk-overlay-wrapper {
  display: flex;
  flex: 1;
  position: relative;
  align-items: stretch;
}

.mat-mdc-button {
  background-color: #0066b2;
  color: white;
  border-radius: 0;
}

.mat-mdc-button:disabled {
  background-color: lightgrey;
}

.clear {
  position: absolute;
  right: 0;
  margin: 15px;
}

.display-name {
  margin-right: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
