import { tap, first } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import { Component } from '@angular/core';
import { DictionaryItem, DictionaryItemDefinition, DictionarySimplePageComponent } from 'src/app/shared/components/dictionary-simple-page/dictionary-simple-page.component';
import { ServiceDetailViewModel, EsraPipingAPIClient } from 'src/app/shared/models/autogenerated-piping';
import { MatDialog } from '@angular/material/dialog';
import { ServiceNoteUsageComponent } from '../dialogs/service-note-usage/service-note-usage.component';
import { DictionaryDeleteComponent } from '../dialogs/dictionary-delete/dictionary-delete.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { InsightsService } from 'src/app/shared/services/insights.service';

@Component({
  selector: 'app-service-details',
  templateUrl: '../../../../shared/components/dictionary-simple-page/dictionary-simple-page.component.html',
  styleUrls: ['../../../../shared/components/dictionary-simple-page/dictionary-simple-page.component.scss']
})
export class ServiceDetailsComponent extends DictionarySimplePageComponent<ServiceDetailViewModel> {
  url = 'path/serviceDetailAddEdit';

  constructor(
    private esraApiClient: EsraPipingAPIClient,
    private snackBarService: SnackBarService,
    private insightsService: InsightsService,
    private dialog: MatDialog,
  ) { super(); }

  searchAll() {
    this.esraApiClient.getAllCurrentServiceDetails()
      .pipe(
        indicate(this.searching$),
        first(),
        tap(details => { 
          this.dataSource.data = details;
          this.totalCount = details.length;
        }))
        .subscribe();
  }

  protected initializeDictionaryDefinition(): DictionaryItemDefinition<ServiceDetailViewModel>{
    return {
      displayStatusColumn: true,
      dictionaryItems: [ new DictionaryItem('Last Updated', 'updated', 'standard', (item: ServiceDetailViewModel) =>  this.getDate(item) ),
        new DictionaryItem('Service Detail',  'name', 'extended')
      ]
    };
  }

  protected getDate(item: ServiceDetailViewModel): string {
    return String(item.updated.getMonth() + 1).padStart(2, '0') + '/' + String(item.updated.getDate()).padStart(2, '0') + '/' + item.updated.getFullYear();
  }

  protected getFilterPredicate(record: ServiceDetailViewModel, parametersMap: Map<string, string>): boolean {
    const searchQuery = parametersMap.get(DictionarySimplePageComponent.SEARCH_QUERY_NAME)?.toLowerCase() || '';
    const isActiveFilter = parametersMap.get(DictionarySimplePageComponent.IS_ACTIVE_NAME);

    const matchesSearchQuery = record.updated.toString().toLowerCase().includes(searchQuery) || record.name.toLowerCase().includes(searchQuery);

    if (isActiveFilter == null) {
        return matchesSearchQuery;
    } else {
        return matchesSearchQuery && record.isActive === JSON.parse(isActiveFilter);
    }

  }

  protected getNoMatchMessage(): string {
    return "No service details match provided search criteria";    
  }

  protected createNew(): void {
    window.open(this.url);
  }

  protected editItem(serviceDetail) {
    localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
    window.open(this.url);
  }

  protected editItemReferenced(serviceDetail) {
    localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
    window.open(this.url);
  }

  protected deleteItem(item) {
    this.dialog.open(DictionaryDeleteComponent, {
      width: '40%',
      height: '23%',
      data: {
        item: item,
        objectName: item.name,
        objectType: 'service detail'
      }
    }).afterClosed().subscribe( (result) => {
      if(result) {
        this.esraApiClient.deleteServiceDetail(item.id).subscribe({
          next: () => {
            this.snackBarService.showSnackBar(
              true,
              `Service detail "${item.name}" was successfully deleted.`,
              'mat-snack-bar-info'
            );
            this.searchAll();
          },
          error: (error) => {
            this.snackBarService.showSnackBar(
              true,
              `Sorry, something went wrong. Error trying to delete service detail "${item.name}".`,
              'mat-snack-bar-error'
            );
            this.insightsService.logException(error);
          },
        });
      }      
    })
  }

  protected getUsageInformation(item): void{
    (this.dialog.open(ServiceNoteUsageComponent, {
      width: '40%',
      height: '40%',
      data: item
    })).afterClosed().subscribe( () => {
      this.searchAll();
    })
  }

  protected updateStatus(item: ServiceDetailViewModel) {
    this.esraApiClient.toggleIsActiveServiceDetail(item.isActive, [item.id]).pipe(indicate(this.searching$), first(),
    tap(() => this.search())).subscribe({error: () => this.searchAll()});
  }
}
