import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { RevisionApprovalViewModel } from 'src/app/shared/models/autogenerated-coating';

@Component({
  selector: 'app-revision-approval',
  templateUrl: './revision-approval.component.html',
  styleUrls: ['./revision-approval.component.scss'],
})
export class RevisionApprovalComponent implements OnInit {
  @Input() member: RevisionApprovalViewModel;
  @Input() roleTitles: string[];
  @Input() isReadOnly = false;

  @Output() delete = new EventEmitter();
  @Output() updated = new EventEmitter();

  public CONTROL_NAME = 'memberRole';
  memberFormGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.memberFormGroup = this.formBuilder.group({
      memberRole: ['', Validators.required],
    });
    this.memberFormGroup.controls.memberRole.setValue(
      this.member.approverType.toString()
    );
  }

  updateApproverType(event: MatSelectChange) {
    this.member.approverType = event.value;
    this.updated.emit([
      event.value,
      this.member,
      this.memberFormGroup.controls[this.CONTROL_NAME],
    ]);
  }

  onDelete() {
    this.delete.emit(this.member);
  }
}
