// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 2%;
}
.section-container .section-header {
  color: #0066b2;
  font-family: "GothamBold", Arial, sans-serif;
  text-align: left;
}
.section-container .section-header .text-button {
  margin-left: 3%;
}

.main-pane {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2%;
}

.main-comment {
  padding-top: 1%;
}

.comment {
  width: 61%;
  background-color: #faab18;
  font-family: "Gotham", Arial, sans-serif;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
