import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NextpageModule } from '../nextpage/nextpage.module';
import { MatListModule } from '@angular/material/list';
import { SearchModule } from '../search/search.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSidenavModule } from '@angular/material/sidenav';
import { API_BASE_URL, EsraPipingAPIClient } from '../shared/models/autogenerated-piping';
import { environment } from 'src/environments/environment';
import { PipingSummaryComponent } from './piping-summary/piping-summary.component';
import { PressureTempTableComponent } from './pressure-temp-table/pressure-temp-table.component';
import { PipingNotesComponent } from './piping-notes/piping-notes.component';
import { PipingComponentsComponent } from './piping-components/piping-components.component';
import { PipingTabsParentComponent } from './piping-tabs-parent/piping-tabs-parent.component';
import { ValveTabComponent } from './valve-tab/valve-tab.component';
import { PipeComponentDetailsComponent } from './piping-components/pipe-component-details/pipe-component-details.component';
import { ValveSummaryPageComponent } from './valve-summary-page/valve-summary-page.component';
import { CreateRevisionComponent } from './revisions/create-revision/create-revision.component';
import { TechModCreateComponent } from './techmod-create/techmod-create.component';
import { ReleasesAndRevisionsComponent } from './piping-releases-and-revisions/piping-releases-and-revisions.component';
import { ValveSizeDisplayPipe } from '../revisions/pipes/valve-size-display.pipe';
import { RemoveCharactersPipe } from '../revisions/pipes/remove-characters.pipe';
import { PipingRevisionStatusPipe } from '../revisions/pipes/piping-revision-status.pipe';
import { Esra1CompatibilityComponent } from './esra1-compatibility/esra1-compatibility.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    PipingSummaryComponent,
    PressureTempTableComponent,
    PipingNotesComponent,
    PipingComponentsComponent,
    PipingTabsParentComponent,
    PipeComponentDetailsComponent,
    ReleasesAndRevisionsComponent,
    ValveTabComponent,
    ValveSummaryPageComponent,
    CreateRevisionComponent, 
    TechModCreateComponent,
    ValveSizeDisplayPipe,
    RemoveCharactersPipe,
    PipingRevisionStatusPipe,
    Esra1CompatibilityComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    NextpageModule,
    OverlayModule,
    RouterModule,
    SearchModule,
    SharedModule,
    MatSortModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCardModule,
    MatChipsModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatExpansionModule,
    MatTableModule,
    MatCardModule
  ],
  providers:[
    { provide: API_BASE_URL, useValue: environment.pipingApiEndpoint },
    EsraPipingAPIClient 
  ]
})
export class PipingValvesModule {}
