import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import {
  EsraWeldingAPIClient,
  ICmidUser,
} from 'src/app/shared/models/autogenerated-welding';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'add-welding-approver',
  templateUrl: './add-welding-approver.component.html',
  styleUrls: ['./add-welding-approver.component.scss'],
})
export class AddWeldingApproverComponent {
  constructor(
    private esraWeldingApiClient: EsraWeldingAPIClient,
    private snackBarService: SnackBarService
  ) {}

  @Input()
  revisionId: number;

  @Output()
  approver = new EventEmitter<ICmidUser>();

  @Output()
  indicator$ = new BehaviorSubject<boolean>(false); 

  approverOptions$: Observable<ICmidUser[]> =
    this.getWeldingRevisionApprovers();

  revisionApprover: ICmidUser;

  addApproverButtonEnabled = false;
  approverListEnabled = true;

  getWeldingRevisionApprovers(): Observable<ICmidUser[]> {
    return this.esraWeldingApiClient.getWeldingRevisionApprovers().pipe(
      indicate(this.indicator$),
      first()
    );
  }

  enableAddApproverButton() {
    this.addApproverButtonEnabled = true;
  }

  setApprover(): void {
    this.esraWeldingApiClient
      .addApproval(this.revisionId, this.revisionApprover.objectId)
      .pipe(indicate(this.indicator$), 
        first())
      .subscribe({
        next: (response) => {
          this.approver.next(this.revisionApprover);
          this.snackBarService.showSnackBar(true, 'Approver added to revision', 'mat-snack-bar-info');
          this.addApproverButtonEnabled = false;
          this.approverListEnabled = false;
        },
        error: (error) => {
          this.snackBarService.showSnackBar(true, error.response, 'mat-snack-bar-error');
        }
      }
      );
  }
}
