import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gramPerLiterToPoundPerGallon',
})
export class GramPerLiterToPoundPerGallonPipe implements PipeTransform {
  transform(value: number): string {
    let poundPerGallon = value * 0.0083454;
    return poundPerGallon.toFixed(3);
  }
}
