import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-coat-dialog',
  templateUrl: './remove-coat-dialog.component.html',
  styleUrls: ['./remove-coat-dialog.component.scss']
})
export class RemoveCoatDialogComponent implements OnInit {

  title: string;
  content: string;

  constructor(    
    public dialogRef: MatDialogRef<RemoveCoatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.title;
      this.content = this.data.content;
    }            
  }

  ok() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
