import { EventEmitter, OnDestroy, Output, Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RevisionApprovalViewModel } from 'src/app/shared/models/autogenerated-coating';

@Component({
  selector: 'app-edit-revision-approval',
  templateUrl: './edit-revision-approval.component.html',
  styleUrls: ['./edit-revision-approval.component.scss'],
})
export class EditRevisionApprovalComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();
  @Input() teamMembers: RevisionApprovalViewModel[];
  @Input() roleTitles: string[];
  @Output() delete: EventEmitter<RevisionApprovalViewModel> =
    new EventEmitter();
  @Output() updated = new EventEmitter();
  @Input() isReadOnly = false;

  searchText: string;
  currentSearch: string;
  newProject = true;

  onKeyup(code: string) {
    if (code === 'Enter') {
      this.currentSearch = this.searchText;
    }
  }
  clearSearch() {
    this.currentSearch = '';
    this.searchText = '';
  }
  ngOnInit(): void {
    this.sortTeamMembersByApproverTypeAndName();
  }

  ngOnChanges(): void {
    this.sortTeamMembersByApproverTypeAndName();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private sortTeamMembersByApproverTypeAndName(): void {
    this.teamMembers.sort((a, b) => 
    {
      if(a.approverType > b.approverType) {
        return 1;
      }
      if(a.approverType < b.approverType) {
        return -1;
      }
      if(a.approverType == b.approverType) {
        if(a.name.localeCompare(b.name) < 0)
        {
          return -1;
        }
        if(a.name.localeCompare(b.name) > 0) {
          return 1;
        }
        return 0;
      }
    });      
  }




}
