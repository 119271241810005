import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EsraCoatingsAPIClient,
  RevisionCommentStatus,
  RevisionCommentViewModel,
  RevisionApprovalViewModel,
} from 'src/app/shared/models/autogenerated-coating';

import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { GraphService } from 'src/app/core/services/graph.service';

@Injectable({
  providedIn: 'root',
})
export class CommentThreadService {
  private publishedComments = new ReplaySubject<RevisionCommentUserViewModel>(
    1
  );
  private approversFromGraph$ = new ReplaySubject<
    Map<string, MicrosoftGraph.User>
  >(1);

  constructor(
    private esraCoatingApiClient: EsraCoatingsAPIClient,
    private graphService: GraphService
  ) {}

  private getUserDisplayName(graphUser: MicrosoftGraph.User): string {
    return graphUser.givenName + ' ' + graphUser.surname;
  }

  private getGraphUserDetails(
    userOid: string
  ): Observable<MicrosoftGraph.User> {
    return this.graphService.getUserByOid(userOid);
  }

  public publishComment(comment: RevisionCommentUserViewModel) {
    this.publishedComments.next(comment);
  }

  public getPublishedCommentsSubject(): ReplaySubject<RevisionCommentUserViewModel> {
    return this.publishedComments;
  }

  public addNewComment(
    authorOid: string,
    commentText?: string
  ): Observable<RevisionCommentUserViewModel> {
    return combineLatest([this.getGraphUserDetails(authorOid)]).pipe(
      map(([author]) => {
        const comment = this.fillCommentCommonProperties(author, commentText);
        comment.replies = new Array<RevisionCommentUserViewModel>();
        comment.status = RevisionCommentStatus._0;
        if (commentText) {
          comment.comment = commentText;
        }
        return comment;
      })
    );
  }

  public addNewReply(
    author: MicrosoftGraph.User,
    parentCommentId: number,
    commentText?: string
  ): RevisionCommentUserViewModel {
    const reply = this.fillCommentCommonProperties(author, commentText);
    reply.parentCommentId = parentCommentId;

    return reply;
  }

  private fillCommentCommonProperties(
    author: MicrosoftGraph.User,
    commentText?: string
  ): RevisionCommentUserViewModel {
    const comment = new RevisionCommentUserViewModel();
    comment.id = 0;
    comment.authorName = this.getUserDisplayName(author);
    comment.comment = commentText ? commentText : '';
    comment.createdAt = new Date();
    comment.createdByOid = author.id;
    comment.initials =
      comment.authorName.split(' ')[0].charAt(0) +
      ' ' +
      comment.authorName.split(' ')[1].charAt(0);
    comment.isAdd = true;
    return comment;
  }

  public addParentCommentReplyOperation(
    revisionId: number,
    comment: RevisionCommentViewModel
  ): Observable<RevisionCommentViewModel> {
    return this.esraCoatingApiClient.addComment(revisionId, comment);
  }

  public updateParentCommentReplyOperation(
    revisionId: number,
    comment: RevisionCommentViewModel
  ): Observable<RevisionCommentViewModel> {
    return this.esraCoatingApiClient.updateComment(revisionId, comment);
  }

  public deleteParentCommentReplyOperation(
    revisionId: number,
    commentId: number
  ): Observable<RevisionCommentViewModel> {
    return this.esraCoatingApiClient.deleteComment(revisionId, commentId);
  }
}

export class RevisionApprovalUserViewModel extends RevisionApprovalViewModel {
  surname = '';
  givenName = '';
  initials = '';
  isAdd = false;
  isEdit = false;
}

export class RevisionCommentUserViewModel extends RevisionCommentViewModel {
  authorName = '';
  initials = '';
  isAdd = false;
  isEdit = false;
  replies: RevisionCommentUserViewModel[];
}
