import { Component } from '@angular/core';
import {
  NavigationLocation,
  PageLayout,
  SearchType,
  SliderType,
  NextPageService,
} from '@cvx/nextpage';
import { UserService } from './core/services/user.service';
import { Constants } from './shared/constants/constants';
import { EsraPipingAPIClient } from './shared/models/autogenerated-piping';
import { combineLatest, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [EsraPipingAPIClient],
})
export class AppComponent {
  title = 'ESRA';
  name = '';
  loggedin: boolean;
  loginDisplay = false;
  errConnectionRefused: boolean;

  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  SearchType = SearchType;
  SliderType = SliderType;

  constructor(
    private NP: NextPageService,
    private userService: UserService,
  ) {
    this.NP.options.sitename =
      'Engineering Standards Repository Application (ESRA)';
    this.prepareNavigationMenuItems();
  }

  handleErrConnectionRefused(event: boolean) {
    this.errConnectionRefused = event;
  }

  prepareNavigationMenuItems() {
    this.prepareHomeMenuItems();
    this.prepareCoatingMenuItems();
    this.prepareWeldingProcedureMenuItems();
    this.preparePipingAndValvesMenuItems();
  }

  prepareHomeMenuItems() {
    const homeMenuItems = {
      title: 'home',
      url: '/',
    };
    this.NP.options.navigationItems.push(homeMenuItems);
  }

  prepareCoatingMenuItems() {
    const coatingMenuChidItems = [
      {
        title: 'search',
        url: '/coatingsolutionsearch',
      },
      {
        title: 'my workspace',
        url: '/myworkspace?technology=coatings',
      },
      {
        title: 'revisions',
        url: '/allrevisions',
      },
    ];

    combineLatest<[boolean, boolean]>([
      this.userService.isRoleExistsObservable(
        Constants.coatingSpecificationExport
      ),
      this.userService.isRoleExistsObservable(Constants.scanner),
    ])
      .pipe(
        map(([allowSpecExport, isScanner]) => {
          if (allowSpecExport || isScanner) {
            coatingMenuChidItems.push({
              title: 'spec export',
              url: '/spec-export',
            });
          }
        })
      )
      .subscribe();

    const coatingMenuItems = {
      title: 'coatings',
      url: '/coatingsolutionsearch',
      children: coatingMenuChidItems,
    };
    this.NP.options.navigationItems.push(coatingMenuItems);
  }

  prepareWeldingProcedureMenuItems() {
    const weldingProcedureMenus = {
      title: 'welding procedures',
      url: '/weldingsearch',
      children: [
        {
          title: 'search',
          url: '/weldingsearch',
        },
        {
          title: 'my workspace',
          url: '/myworkspace?technology=welding',
        },
        {
          title: 'revisions',
          url: '/weldingrevision/all',
        },
      ],
    };
    this.NP.options.navigationItems.push(weldingProcedureMenus);
  }

  preparePipingAndValvesMenuItems() {
    const pipingNavbarChildren = [
      {
        title: 'search',
        url: '/piping/search',
      },
      {
        title: 'my workspace',
        url: '/myworkspace?technology=piping',
      },
    ];

    const nonProductionNavbarChildren = [
      {
        title: 'revisions and releases',
        url: '/piping/revisionsAndReleases',
      },
    ];

    if (environment.production === false) {
      combineLatest<[boolean, boolean]>([
        this.userService.isPipingAndValvesAdminObservable(),
        this.userService.isRoleExistsObservable(Constants.scanner),
      ])
        .pipe(
          map(([enableAdminMenu, isScanner]) => {
            if (enableAdminMenu || isScanner) {
              pipingNavbarChildren.push(
                {
                  title: 'admin search',
                  url: '/piping/adminSearch',
                },
                {
                  title: 'dictionaries',
                  url: '/piping/dictionaries',
                }
              );
            }
          })
        )
        .subscribe();

      nonProductionNavbarChildren.forEach((child) => {
        pipingNavbarChildren.push(child);
      });
    }

    const pipingAndValvesMenuItems = {
      title: 'piping and valves',
      url: '/piping/search',
      children: pipingNavbarChildren,
    };
    this.NP.options.navigationItems.push(pipingAndValvesMenuItems);
  }

  readonly currentUserProfile$ = this.userService.currentUserProfile$;
}
