import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GraphService } from '../core/services/graph.service';
import {
  API_BASE_URL,
  EsraCoatingsAPIClient,
} from './models/autogenerated-coating';

@NgModule({
  declarations: [],
  providers: [
    { provide: API_BASE_URL, useValue: environment.coatingsApiEndpoint },
    EsraCoatingsAPIClient,
    GraphService,
  ],
  imports: [],
  exports: [],
})
export class CoatingApiModule {}
