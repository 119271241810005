// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  display: flex;
  flex-direction: column;
}

.close-button-container {
  display: flex;
  justify-content: right;
  padding: 2% 3%;
}

#mat-close-btn {
  cursor: pointer;
}
#mat-close-btn fa-icon {
  color: #707070;
}

#mat-close-btn:hover fa-icon {
  color: #0b2d71;
}

.text-container {
  height: 20%;
  display: flex;
  justify-content: space-between;
  padding: 5% 3%;
}
.text-container #title {
  font-size: large;
  font-weight: bold;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 3%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
