import { Component, ContentChild, TemplateRef} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-closable-dialog',
  templateUrl: './closable-dialog.component.html',
  styleUrls: ['./closable-dialog.component.scss']
})
export class ClosableDialogComponent {
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  progressBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  publishOnProgressBar(event: boolean) {
    this.progressBar$.next(event);
  }
}
