import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PipingAndValvesNavigationService {

  constructor(private router: Router) { }

  openPipingSummaryPage(pipeId: string) {
    this.router.navigate(['/pipingsummary', pipeId]);
  }

  openValveSummaryPage(valveId: string) {
    this.router.navigate(['/valveSummary', valveId]);
  }
}
