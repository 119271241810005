import { Component, OnDestroy, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import {
  combineLatest,
  ReplaySubject,
  Subscription,
  Observable,
} from 'rxjs';
import { first } from 'rxjs/operators';
import {
  EsraCoatingsAPIClient,
  CoatingProductViewModel,
  ProductServiceTemperatureViewModel,
} from 'src/app/shared/models/autogenerated-coating';
import { indicateWithCounter } from 'src/app/operators';
import { EditCoatingComponent } from "src/app/revisions/dialogues/edit-coating-system/edit-coating-component-abstract";
import { UserService } from 'src/app/core/services/user.service';
import {
  CustomPropertyCharacteristic,
  PropertyType,
} from '../../components/properties-table/properties-table.component';
import { FormGroup, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-coating-product',
  templateUrl: './edit-coating-product.component.html',
  styleUrls: ['./edit-coating-product.component.scss'],
})
export class EditCoatingProductComponent extends EditCoatingComponent implements OnDestroy {
  private readonly subscription = new Subscription();

  // selection list observables
  manufacturers$: Observable<string[]> =
    this.esraCoatingClient.getAllManufacturers();

  services$: Observable<string[]> =
    this.esraCoatingClient.getAllCoatingSystemServices();

  currentAndPrevious$ = new ReplaySubject<CoatingProductViewModel[]>(1);

  objectToBeUpdated: CoatingProductViewModel;
  hasRevisionPermission = true;
  currentCoatingProductId: string;
  errorMessage: string;
  receivedForm: UntypedFormGroup;

  customCoatingProductProperties: CustomPropertyCharacteristic[] = [
    { propertyName: 'id', isVisible: false },
    { propertyName: 'prevRevisionProdId', isVisible: false },
    { propertyName: 'coatingProductId', isVisible: false },
    { propertyName: 'name', isVisible: true, propertyType: PropertyType.INPUT },
    {
      propertyName: 'manufacturer',
      propertyType: PropertyType.DROPDOWN,
      isVisible: true,
      values: this.manufacturers$,
    },
    {
      propertyName: 'serviceTempMaxC',
      isVisible: true,
      propertyType: PropertyType.INPUT,
    },
    {
      propertyName: 'serviceTempMinC',
      isVisible: true,
      propertyType: PropertyType.INPUT,
    },
    {
      propertyName: 'minGramsPerLiterVOC',
      isVisible: true,
      propertyType: PropertyType.INPUT,
    },
    {
      propertyName: 'maxGramsPerLiterVOC',
      isVisible: true,
      propertyType: PropertyType.INPUT,
    },
    {
      propertyName: 'steamOutC',
      isVisible: true,
      propertyType: PropertyType.INPUT,
    },
    { propertyName: 'dft', isVisible: true, propertyType: PropertyType.INPUT },
    {
      propertyName: 'liningType',
      isVisible: true,
      propertyType: PropertyType.INPUT,
    },
    {
      propertyName: 'cureToImmersion',
      isVisible: true,
      propertyType: PropertyType.INPUT,
    },
    {
      propertyName: 'productNotes',
      isVisible: true,
      propertyType: PropertyType.INPUT,
    },
    //service temperatures will be populated in a separate table
    { propertyName: 'productServiceTemperatures', isVisible: false },
  ];

  constructor(
    @Inject(Location) location: Location,
    @Inject(UserService) userService: UserService,
    @Inject(EsraCoatingsAPIClient) esraCoatingClient: EsraCoatingsAPIClient,
    private route: ActivatedRoute,
  ) {
    super(location, userService, esraCoatingClient);
    
    this.route.params.pipe(first()).subscribe((routerParams) => {
      this.currentCoatingProductId = routerParams.coatingproductid;
      this.getDraftCoatingProduct(
        routerParams.coatingproductid,
        routerParams.revisionid
      );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateReceivedFormGroup(receivedForm: FormGroup) {
    this.receivedForm = receivedForm;
  }

  private getDraftCoatingProduct(
    draftCoatingProductId: string,
    revisionId: number
  ) {
    if (draftCoatingProductId) {
      combineLatest([
        this.esraCoatingClient
          .getCoatingProductById(draftCoatingProductId)
          .pipe(indicateWithCounter(this.loadingData$, this.counter)),
        this.esraCoatingClient.getCoatingRevisionById(revisionId),
      ])
        .pipe(
          indicateWithCounter(this.loadingData$, this.counter),
          first()
        )
        .subscribe(([draftCoatingProduct, revision]) => {
          this.revision = revision;
          this.objectToBeUpdated = draftCoatingProduct;
          this.sortServiceTemperatures();
          this.getPublishedCoatingProduct();
          
          if(!this.isRevisionReadOnly()) {
            this.getPropertyValuesForDropdownsFromAzureCog(this.objectToBeUpdated.coatingProductId);
          }
        });
    }
  }

  private sortServiceTemperatures() {
    this.objectToBeUpdated.productServiceTemperatures.sort(
      (service1, service2) => {
        if (service1.service > service2.service) {
          return 1;
        }

        if (service1.service < service2.service) {
          return -1;
        }

        return 0;
      }
    );
  }

  private getPublishedCoatingProduct() {
    if (this.objectToBeUpdated.prevRevisionProdId) {
      this.esraCoatingClient
        .getCoatingProductById(this.objectToBeUpdated.prevRevisionProdId)
        .pipe(
          indicateWithCounter(this.loadingData$, this.counter),
          first()
        )
        .subscribe((publishedCoatingProduct) =>
          this.currentAndPrevious$.next([
            this.objectToBeUpdated,
            publishedCoatingProduct,
          ])
        );
    } else {
      this.currentAndPrevious$.next([
        this.objectToBeUpdated,
        new CoatingProductViewModel(),
      ]);
    }
  }

  addProductServiceTemperature(service, temperature, productServiceTemperatureVMs) {
    let serviceId;

    const newProductServiceTempVM = new ProductServiceTemperatureViewModel({
      serviceId: serviceId,
      service: service,
      coatingProductId: this.objectToBeUpdated.coatingProductId,
      serviceTempMaxC: temperature
    });

    productServiceTemperatureVMs.push(newProductServiceTempVM);
  }

  save() {
    if (this.receivedForm) {
      const receivedFormLength = Object.keys(this.receivedForm.controls).length;
      const newProductServiceTempVMs: ProductServiceTemperatureViewModel[] = [];

      for (let i=0; i < receivedFormLength / 2; i++) {
        const serviceToAdd = this.receivedForm.get('service' + i.toString()).value;
        const tempToAdd = this.receivedForm.get('temperature' + i.toString()).value;
        this.addProductServiceTemperature(serviceToAdd, tempToAdd, newProductServiceTempVMs);
      }

      this.objectToBeUpdated.productServiceTemperatures = newProductServiceTempVMs;
    }
    
    this.subscription.add(
      this.esraCoatingClient
        .updateCoatingProduct(
          this.objectToBeUpdated.coatingProductId,
          this.objectToBeUpdated
        )
        .pipe(
          indicateWithCounter(this.loadingData$, this.counter),
          first()
        )
        .subscribe(
          {
            next: () => {
              this.location.back();
            },
            error: (err: HttpErrorResponse) => {
              console.error(err);
              this.errorMessage = err.message + 
                ' Please contact technical support.' +
                ' [' +
                err.status +
                ': ' +
                err.statusText +
                ']';
            }
          }
        )
    );
  }

  getCommentsForCoatingProduct() {
    return this.revision.comments.filter(
      (comment) =>
        comment.linkedCoatingProductId === this.currentCoatingProductId
    );
  }

  getCustomProperties() {
    return this.customCoatingProductProperties;
  }

  setCustomProperty(customProperty: CustomPropertyCharacteristic ){
    this.customCoatingProductProperties.push(customProperty);
  }

  isProductBeingArchived(){
    for(const product of this.revision.coatingProducts){
      if(product.id === this.objectToBeUpdated.coatingProductId){
        return product.toBeDeleted;
      }
    }
    return false;
  }
}
