import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'KpaToPsig',
})
export class KpaToPsigPipe implements PipeTransform {
    transform(value: number): string {
        const convConst = 6.894757;
        const psi = Math.round((value / convConst)/5)*5;
        return psi.toFixed(0);
    }

}