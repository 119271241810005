import {
  BehaviorSubject,
  of
} from 'rxjs';
import {
  EsraCoatingsAPIClient,
  CoatingRevisionViewModel, RevisionStatus
} from 'src/app/shared/models/autogenerated-coating';
import { Location } from '@angular/common';
import {
  CustomPropertyCharacteristic,
  PropertyType
} from '../../components/properties-table/properties-table.component';
import { first } from 'rxjs/operators';
import { indicateWithCounter, Counter } from 'src/app/operators';
import { UserService } from 'src/app/core/services/user.service';


export abstract class EditCoatingComponent {

  revision: CoatingRevisionViewModel;
  loadingData$ = new BehaviorSubject<boolean>(false);
  protected counter = new Counter();

  constructor(
    protected location: Location,
    protected userService: UserService,
    protected esraCoatingClient: EsraCoatingsAPIClient
  ) {
  }

  cancel() {
    this.goBack()
  }

  goBack() {
    this.location.back();
  }

  isRevisionReadOnly() {
    if (this.revision) {
      return this.revision.status !== RevisionStatus._0 &&
        !(this.revision.status === RevisionStatus._1 && this.revision.authorOid === this.userService.getCurrentUser().localAccountId);
    } else {
      console.log('ERROR: Revision not set!');
    }
  }

  protected getPropertyValuesForDropdownsFromAzureCog(searchPhrase: string) {
    this.esraCoatingClient
      .getSearchFilters(searchPhrase)
      .pipe(
        indicateWithCounter(this.loadingData$, this.counter),
        first()
      )
      .subscribe((coatingsSearchFilterModel) => {
        coatingsSearchFilterModel.forEach((filterModel) => {
          const matchedProperties = this.getCustomProperties().filter(
            (customProperty) => customProperty.propertyName.toLowerCase() ===
              filterModel.filterPropertyName.toLowerCase()
          );

          if (matchedProperties && matchedProperties.length > 0) {
            matchedProperties[0].displayName = filterModel.filterDisplayName;
            matchedProperties[0].values = of(filterModel.options);
          } else {
            this.setCustomProperty({
              //To make first letter small
              propertyName: filterModel.filterPropertyName.charAt(0).toLowerCase() +
                filterModel.filterPropertyName.slice(1),
              displayName: filterModel.filterDisplayName,
              isVisible: true,
              propertyType: PropertyType.DROPDOWN,
              values: of(filterModel.options),
            });
          }
        });
      });
  }

  abstract getCustomProperties(): CustomPropertyCharacteristic[];
  abstract setCustomProperty(customProperty: CustomPropertyCharacteristic): void;
}
