import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NextpageModule } from '../nextpage/nextpage.module';
import { CommentComponent } from './components/comment/comment.component';
import { AddCoatingSystemDialogueComponent } from './dialogues/add-coating-system/add-coating-system-dialogue.component';
import { CoatingsRevisionSummaryComponent } from './pages/coatings-revision-summary/coatings-revision-summary.component';
import { ApprovalStatusPipe } from './pipes/approval-status.pipe';
import { MatListModule } from '@angular/material/list';
import { SearchModule } from '../search/search.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AllRevisionsComponent } from './pages/all-revisions/all-revisions.component';
import { MatSortModule } from '@angular/material/sort';
import { RevisionStatusPipe } from './pipes/revision-status.pipe';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AddCoatingProductDialogueComponent } from './dialogues/add-coating-product/add-coating-product-dialog.component';
import { AddCoatingSolutionDialogueComponent } from './dialogues/add-coating-solution/add-coating-solution-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RevisionCommentStatusPipe } from './pipes/revision-comment-status.pipe';
import { EditCoatingSystemComponent } from './dialogues/edit-coating-system/edit-coating-system.component';
import { CommentsThreadComponent } from './components/comments-thread/comments-thread.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { NewCommentDialogComponent } from './components/new-comment-dialog/new-comment-dialog.component';
import { SplitByCasePipe } from './pipes/name-split.pipe';
import { AddCoatDialogComponent } from './dialogues/edit-coating-system/add-coat-dialog/add-coat-dialog.component';
import { RemoveCoatDialogComponent } from './dialogues/edit-coating-system/remove-coat-dialog/remove-coat-dialog.component';
import { AllWeldingRevisionsComponent } from './pages/all-welding-revisions/all-welding-revisions.component';
import { WeldingRevisionSummaryComponent } from './pages/welding-revision-summary/welding-revision-summary.component';
import { CreateWeldingRevisionComponent } from './dialogues/create-welding-revision/create-welding-revision.component';
import { SpecExportComponent } from './spec-export/spec-export.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UpdateCoatingSolutionComponent } from './pages/update-coating-solution/update-coating-solution.component';
import { NullTypePipe } from './pipes/null-type.pipe';
import { CreateCoatingProductComponent } from './dialogues/create-coating-product/create-coating-product.component';
import { EditCoatingProductComponent } from './dialogues/edit-coating-product/edit-coating-product.component';
import { UpdateWeldingProcedureComponent } from './components/update-welding-procedure/update-welding-procedure.component';
import { RejectCommentComponent } from './dialogues/reject-comment/reject-comment.component';
import { PropertiesTableComponent } from './components/properties-table/properties-table.component';
import { AddServiceTemperatureComponent } from './components/add-service-temperature/add-service-temperature.component';
import { AddWeldingApproverComponent } from './components/add-welding-approver/add-welding-approver.component';
import { ClosableDialogComponent } from './dialogues/closable-dialog/closable-dialog.component';
import { AddExistingComponent } from './dialogues/add-existing/add-existing.component';
import { EditWeldingApproverComponent } from './components/edit-welding-approver/edit-welding-approver.component';

@NgModule({
  declarations: [
    AddCoatingProductDialogueComponent,
    AddCoatingSolutionDialogueComponent,
    AddCoatingSystemDialogueComponent,
    AddWeldingApproverComponent,
    EditWeldingApproverComponent,
    AllRevisionsComponent,
    AllWeldingRevisionsComponent,
    ApprovalStatusPipe,
    NullTypePipe,
    CoatingsRevisionSummaryComponent,
    CommentComponent,
    CreateCoatingProductComponent,
    CreateWeldingRevisionComponent,
    RevisionStatusPipe,
    RevisionCommentStatusPipe,
    EditCoatingSystemComponent,
    CommentsThreadComponent,
    NewCommentDialogComponent,
    SplitByCasePipe,
    AddCoatDialogComponent,
    RemoveCoatDialogComponent,
    WeldingRevisionSummaryComponent,
    SpecExportComponent,
    EditCoatingProductComponent,
    UpdateCoatingSolutionComponent,
    UpdateWeldingProcedureComponent,
    RejectCommentComponent,
    PropertiesTableComponent,
    AddServiceTemperatureComponent,
    ClosableDialogComponent,
    AddExistingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    NextpageModule,
    OverlayModule,
    RouterModule,
    SearchModule,
    SharedModule,
    MatSortModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCardModule,
    MatChipsModule,
    MatPaginatorModule,
    FontAwesomeModule,
  ],
})
export class RevisionsModule {}
