import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap, first } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import { CoatingSolutionDetailComponent } from 'src/app/shared/dialogs/Coating-Solution-Detail/coating-solution-detail.component';
import {
  EsraCoatingsAPIClient,
  EsraRecordLabel,
  SimpleObjectViewModel,
} from 'src/app/shared/models/autogenerated-coating';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-add-coating-solution',
  templateUrl: './add-coating-solution-dialog.component.html',
  styleUrls: ['./add-coating-solution-dialog.component.scss'],
})
export class AddCoatingSolutionDialogueComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<CoatingSolutionDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private esraCoatingClient: EsraCoatingsAPIClient,
    private _formBuilder: UntypedFormBuilder,
    private _snackBarService: SnackBarService
  ) {}

  addFormGroup: UntypedFormGroup;
  subscription = new Subscription();

  revisionId: number;

  selectAction = true;
  createNewCoatingSolution = false;
  selectExistingCoatingSolution = false;
  addToRevisionButtonEnabled = false;

  title = 'select a coating solution';

  solutions$: Observable<EsraRecordLabel[]> = this.getSolutions();
  solutionOptions: string[];

  systemIds$: Observable<SimpleObjectViewModel[]> = this.getCoatingSystemIds();

  manufacturers$: Observable<string[]> = this.getManufacturers();

  selectedSolutionLabelSubject = new BehaviorSubject<string>(
    'select a coating solution'
  );
  addingToRevision$ = new BehaviorSubject<boolean>(false);

  newCoatingSystemSolution: CoatingSystemSolutionSimpleModel =
    new CoatingSystemSolutionSimpleModel();
  newCoatingSystemSolutionName: string;

  ngOnInit(): void {
    if (this.data) {
      this.revisionId = this.data.revisionId;
    }

    this.addFormGroup = this._formBuilder.group({
      coatingSystemId: ['', Validators.required],
      solutionName: ['', Validators.required],
      manufacturer: ['', Validators.required],
      solutionNotes: [''],
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getCoatingSystemIds(): Observable<SimpleObjectViewModel[]> {
    return this.esraCoatingClient.getAllCoatingSystemIds(this.data.revisionId);
  }

  getManufacturers(): Observable<string[]> {
    return this.esraCoatingClient.getAllManufacturers();
  }

  selectFromExisting() {
    this.solutions$
      .pipe(first(),
        indicate(this.addingToRevision$))
      .subscribe((_) => {
        this.selectAction = false;
    this.selectExistingCoatingSolution = true;
      });
  }

  createNew() {
    this.selectAction = false;
    this.createNewCoatingSolution = true;
  }

  getSolutions(): Observable<EsraRecordLabel[]> {
    return this.esraCoatingClient
      .getAllCoatingSolutionLabels(this.data.techSpec)
      .pipe(
        tap(
          (solutions) => (this.solutionOptions = solutions.map((x) => x.label))
        )
      );
  }

  checkCanCreate() {
    if (
      !this.newCoatingSystemSolution.coatingSystem ||
      !this.newCoatingSystemSolution.manufacturer
    ) {
      this.newCoatingSystemSolution.solutionName = '';
      this.addToRevisionButtonEnabled = false;
      return;
    }

    const systemId = this.newCoatingSystemSolution.coatingSystem.trim();
    const manufacturer = this.newCoatingSystemSolution.manufacturer.trim();

    this.subscription.add(
      this.systemIds$
        .pipe(
          map(
            (systemIds) =>
              systemIds.find((x) => x.id == systemId).name +
              ' | ' +
              manufacturer
          ),
          tap((solutionName) => {
            this.newCoatingSystemSolution.solutionName = solutionName;
          }),
          tap(() => (this.addToRevisionButtonEnabled = true))
        )
        .subscribe()
    );
  }

  addToRevision() {
    if (this.createNewCoatingSolution) {
      this.subscription.add(
        this.systemIds$
          .pipe(
            map(
              (systemIds) =>
                systemIds.find(
                  (x) =>
                    x.id === this.newCoatingSystemSolution.coatingSystem.trim()
                ).name
            ),
            tap((coatingSystemName) =>
              this.esraCoatingClient
                .addNewCoatingSolutionToRevision(
                  this.revisionId,
                  this.newCoatingSystemSolution.solutionName,
                  this.newCoatingSystemSolution.solutionNotes,
                  coatingSystemName
                )
                .pipe(
                  tap((response) => {
                    if(response){
                      this._snackBarService.showSnackBar(true, this.newCoatingSystemSolution.solutionName + " added to revision");
                    }
                  }),
                  map(() => this.dialogRef.close())
                  )
                .subscribe()
            )
          )
          .subscribe()
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  addCoatingSolutionToRevision = (revisionId: number, label: string) => {
    return this.esraCoatingClient
    .addCoatingSolutionToRevision(
      revisionId,
      label
    )
  }

  addCoatingSolutionToBeArchived = (revisionId: number, label: string) => {
    return this.esraCoatingClient
    .addCoatingSolutionToBeDeleted(
      revisionId,
      label
    );
  }
}

export class CoatingSystemSolutionSimpleModel {
  coatingSystem: string;
  solutionName: string;
  manufacturer: string;
  solutionNotes: string;
}
