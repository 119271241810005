import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'millimetersToInchesPipe',
})
export class MillimetersToInchesPipe implements PipeTransform {
  transform(value: number): number {
    let inches = value / 25.4;
    return inches;
  }
}
