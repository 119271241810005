import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CommonNoteViewModel, EsraPipingAPIClient, PipeClassViewModel } from 'src/app/shared/models/autogenerated-piping';
import { first } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-piping-notes',
  templateUrl: './piping-notes.component.html',
  styleUrls: ['./piping-notes.component.scss']
})
export class PipingNotesComponent implements OnInit {
  @Input() pipeClassViewModel: PipeClassViewModel;

  displayedColumns: string[] = ["ID", "Note"];
  searchInputString = "";
  faSearch = faSearch;
  commonNoteViewModels: CommonNoteViewModel[] = [];
  dataSource: CommonNoteViewModel[] = [];
  currentPage = 0;
  pageSize = 10;
  totalCount: number;
  pageSizes = [2, 5, 10, 25, 50, 100];
  currentSorting: Sort = { active: 'code', direction: 'asc' };
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private esraPipingAPIClient: EsraPipingAPIClient
  ) { }

  ngOnInit(): void {
    const pipeClassId = this.pipeClassViewModel.id;

    if (pipeClassId) {
      this.esraPipingAPIClient.getAllCommonNotesForPipeClassByPipeClassId(
        pipeClassId
      ).pipe(first()).subscribe((response) => {
        this.loadCommonNotes(response);
      });
    }
  }

  private loadCommonNotes(response: CommonNoteViewModel[]) {
    this.commonNoteViewModels = response;
    this.dataSource = response;
    this.totalCount = response.length;
    this.updatePage({ pageIndex: 0, pageSize: 10, length: this.dataSource.length });
  }

  search(): void {
    this.currentPage = 0;
    this.PopulateResults(0, 10);
  }

  public updateSearchInputString(event: { target: { value: string; }; }): void {
    this.searchInputString = event.target.value;
  }

  public searchInputKeyupEnter(event: { target: { value: string; }; }): void {
    this.searchInputString = event.target.value;
    this.currentPage = 0;
    this.search();
  }

  clearButton(): void {
    this.searchInputString = "";
    this.dataSource = this.commonNoteViewModels;
    this.currentSorting = undefined;
    this.currentPage = 0;
    this.PopulateResults(0, 10);
  }

  getFilterResult() {
    const filteredResults = this.filterResults();

    const sortedResults: CommonNoteViewModel[] = this.sortResults(filteredResults);

    return sortedResults;
  }

  private sortResults(filterdResults: CommonNoteViewModel[]) {
    if (this.currentSorting) {

      const columName: string = this.currentSorting.active;
      const pascalCasing = columName.charAt(0).toLocaleLowerCase() + columName.substring(1, columName.length);
      const sortType = this.currentSorting.direction;
      if (sortType === 'asc') {
        filterdResults.sort((a, b) => {
          if (!isNaN(a[pascalCasing])) {
            return this.isBigger(+a[pascalCasing], +b[pascalCasing]);
          }
          return this.isBigger(a[pascalCasing], b[pascalCasing]);
        });
      } else if (sortType === 'desc') {
        filterdResults.sort((a, b) => {
          if (!isNaN(a[pascalCasing])) {
            return this.isSmaller(+a[pascalCasing], +b[pascalCasing]);
          }
          return this.isSmaller(a[pascalCasing], b[pascalCasing]);
        });
      }
    } 
    
    return filterdResults;
  }

  private isBigger(val1: number, val2: number): number{
    return val1 > val2 ? 1 : -1;
  }

  private isSmaller(val1: number, val2: number): number{
    return val1 < val2 ? 1 : -1;
  }

  private filterResults() {
    let filterResult = [];
    if (this.searchInputString && this.searchInputString.trim().length > 0) {
      filterResult = this.commonNoteViewModels.filter(
        (component) => component.codeId.toLowerCase().includes(this.searchInputString.toLocaleLowerCase()) ||
          component.note.toLowerCase().includes(this.searchInputString.toLocaleLowerCase())
      );
    } else {
      filterResult = this.commonNoteViewModels;
    }
    return filterResult;
  }

  PopulateResults(startIndex: number, endIndex: number) {
    const filterResult = this.getFilterResult();
    this.totalCount = filterResult.length;
    const pageItems = filterResult.slice(startIndex, endIndex);
    this.dataSource = pageItems;

    if (startIndex == 0 && this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }

  public updatePage(event: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex = startIndex + event.pageSize;
    this.PopulateResults(startIndex, endIndex);
  }

  public sortColumn(event: Sort): void {
    this.currentSorting = event;
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;
    this.PopulateResults(startIndex, endIndex);

  }
}
