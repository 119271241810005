// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-width: 300px;
}
.contact-us-content p {
  padding: 0 20px;
  text-align: center;
}
.contact-us-content h2 {
  padding: 20px 0;
}
.contact-us-content img {
  margin: 40px 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
