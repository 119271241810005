import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PipeClassViewModel } from 'src/app/shared/models/autogenerated-piping';

@Component({
  selector: 'app-valve-tab',
  styleUrls: ['valve-tab.component.scss'],
  templateUrl: 'valve-tab.component.html',
})
export class ValveTabComponent implements OnInit, OnChanges {
  @Input() pipeClassViewModelValves: PipeClassViewModel['valves'];

  valvesClicked = true;
  valvesPresent = false;

  constructor(private router: Router) {}

  ngOnChanges(): void {
    this.sortValves();
  }

  ngOnInit() {
    this.checkForValves();
  }

  checkForValves() {
    this.pipeClassViewModelValves.forEach(() => {
      if (!this.valvesPresent) {
        this.valvesPresent = true;
      }
    });
  }

  toggleValvesClicked() {
    this.valvesClicked = !this.valvesClicked;
  }

  sortValves() {
    this.pipeClassViewModelValves.sort(function (a, b) {
      if (a.componentType.name < b.componentType.name) {
        return -1;
      }
      if (a.componentType.name > b.componentType.name) {
        return 1;
      }
      return 0;
    });
  }

  openValveSummaryPage(valveId: string) {
    this.router.navigate(['/valveSummary', valveId]);
  }

  replaceUnderscoresWithSpaces(inputStr: string): string {
    const strArr = inputStr.split('_');

    if(strArr.length > 1) {
      strArr.shift()
      return strArr.join(" ")
    } else {
      return inputStr;
    }
  }

  extractSubstringBeforeUnderscore(str: string) {
    return str.split('_')[0];
  }
}
