import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, combineLatest, first, map, tap } from 'rxjs';
import { FilterDataService } from 'src/app/search/services/filter.service';
import { EsraPipingAPIClient, FilterType, FilterableProperty, PipingSearchFilterModel, SearchRequest, SearchResultObject_1OfObject as SearchResultObject } from 'src/app/shared/models/autogenerated-piping';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { indicate } from 'src/app/operators';
import { FilterablePropertyFactory } from 'src/app/shared/components/factory/filterable-property-factory.service';
import { PageEvent } from '@angular/material/paginator';
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-create-revision',
  templateUrl: './create-revision.component.html',
  styleUrls: ['./create-revision.component.scss']
})
export class CreateRevisionComponent implements OnInit {
  private readonly subscription = new Subscription();
  private readonly filtersDefinition: PipingSearchFilterModel[] = [];
  protected selectedItems: SearchResultObject[] = [];
  readonly faSearch = faSearch;
  readonly faTrashCan = faTrash;

  loadingFilters$ = new BehaviorSubject<boolean>(false);
  searching$ = new BehaviorSubject<boolean>(false);
  latestFilterSelection$ = new BehaviorSubject<string>('');
  facetValues$ = new BehaviorSubject<PipingSearchFilterModel[]>([]);
  filters$: Observable<PipingSearchFilterModel[]> = this.getFilters();
  pipeResults$: Observable<SearchResultObject[]>;
  filterableProperties: FilterableProperty[];
  resetAllFilters$ = new BehaviorSubject<boolean>(false);

  totalCount = 0;
  lastPage = 0;
  currentPage = 0;
  pageSize = 25;
  pageSizes = [2, 5, 10, 25, 50, 100];
  protected readonly maxDescriptionLength = 250;

  matSortActive = "available P&V"
  matSortDirection = "asc"
  orderBy = "";
  searchText = "";
  revisionDescription = ""
  searchResultsTable = new MatTableDataSource();
  describeComponentTable = new MatTableDataSource();

  creationStep = 0;
  
  owners: microsoftgraph.User[] = [];
  reviewers: microsoftgraph.User[] = [];
  contributors: microsoftgraph.User[] = [];

  @ViewChild(MatTable) matTable: MatTable<SearchResultObject>;

    columnNamesSorted = new Map([
      ['checkbox', 'checkbox'],
      ['available P&V', 'name'], 
      ['BU', 'businessUnits'],
      ['application', 'application'],
      ['component', 'objectType'],
      ['updated on', 'publishDate'],
      ['actions', 'actions']
    ]);

  constructor(
    private esraPipingAPIClient: EsraPipingAPIClient,
    private filterDataService: FilterDataService,
    private snackBarService: SnackBarService,
    private userService: UserService,
    public dialogRef: MatDialogRef<CreateRevisionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.filtersDefinition.push(new PipingSearchFilterModel({
      filterPropertyName: 'BusinessUnit',
      filterDisplayName: 'BU',
      filterType: 'MULTI_SELECT_NOFILTER',
      options: []
    }));
    this.filtersDefinition.push(new PipingSearchFilterModel({
      filterPropertyName: 'ObjectType',
      filterDisplayName: 'component',
      options: ['pipes', 'valves'],
      filterType: 'MULTI_SELECT_NOFILTER'
    }));
    this.filtersDefinition.push(new PipingSearchFilterModel({
      filterPropertyName: 'Application',
      filterDisplayName: 'application',
      filterType: 'MULTI_SELECT_NOFILTER',
      options: []
    }));

    const filterableProperty = FilterablePropertyFactory.createFilterbleProperty(
      FilterType._6,
      'IsArchivedOrDraft',
      'IsArchivedOrDraft'
    );

    filterableProperty.filterOptions=['0']
    this.filterDataService.setFilterValue(filterableProperty);

    this.subscription.add(
      this.filterDataService.currentFiltersSelection$
        .pipe(
          map((filterSelection) => {
            this.filterableProperties = Array.from(filterSelection.values());
            this.currentPage = 0;
            this.search();
          })
        )
        .subscribe()
    );
  }

  public search(): void {
    const request: SearchRequest = new SearchRequest();
    
    request.perPage = this.pageSize;
    request.page = this.currentPage;
    request.mainSearch = this.wrapSearchTextInWildcardsConditionally(this.searchText);
    request.filters = this.filterableProperties;
    request.orderBy = this.orderBy;
    request.highlightMatches = false;
    this.esraPipingAPIClient
        .searchPipingAndValves(request)
        .pipe(
          indicate(this.searching$),
          first(),
          tap((response) => {
            this.searchResultsTable.data = response.results;
            this.facetValues$.next(response.pipingSearchFilters);
            this.totalCount = response.totalResults;
            this.lastPage = Math.ceil(this.totalCount / this.pageSize);
          })
        ).subscribe();
  }

  private wrapSearchTextInWildcardsConditionally(searchText: string): string{
    let returnString = '';
    let notArray = searchText.split("+-");
    let andArray = searchText.split("+");

    if (notArray.length > 1) {
      notArray = notArray.map((element) => element.trim());
      for (let i = 0; i < notArray.length; i ++){
        if (i !== notArray.length - 1){
          returnString = returnString + notArray[i] + "+-";
        } else {
          returnString = returnString + notArray[i];
        }
      }
    }
    else if (andArray.length > 1) {
      andArray = andArray.map((element) => element.trim())
      for (let i = 0; i < andArray.length; i++){
        if (i !== andArray.length - 1){
          returnString = returnString + andArray[i] + "* + ";
        } else {
          returnString = returnString + andArray[i];
        }
      }
    }    
    else {
      returnString = searchText + "*";
    }
    return returnString;
  }

  private getFilters(): Observable<PipingSearchFilterModel[]> {
    return combineLatest([
      this.facetValues$,
      this.latestFilterSelection$,
    ]).pipe(
      map(([facets, latestFilterSelection]) => {
        return this.filtersDefinition
          .map((filter) => {
            if (filter.filterPropertyName != latestFilterSelection) {
              const matchingFacets = facets.filter(
                (facet) => facet.filterPropertyName === filter.filterPropertyName
              );
              if (matchingFacets[0] != null) {
                filter.options = matchingFacets[0].options;
                filter.minValue = matchingFacets[0].minValue;
                filter.maxValue = matchingFacets[0].maxValue;

                filter.options.sort((a, b) =>
                  a.localeCompare(b, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                  })
                );
              }
            }
            
            return filter;
        });
      })
    );
  }

  public sortColumn(event): void {
    this.matSortActive = event.active;
    this.matSortDirection = event.direction;
    let columnName: string = this.columnNamesSorted.get(event.active);
    if(columnName.toUpperCase() === 'BusinessUnits'.toUpperCase()) {
      columnName = 'BusinessUnit';
    }
    const pascalCasing = columnName.charAt(0).toUpperCase() + columnName.substring(1, columnName.length);
    this.orderBy = `${pascalCasing} ${event.direction}`;
    this.search();
  }

  public sortSelectedItems(event): void {
    const columnName: string = this.columnNamesSorted.get(event.active);
    this.selectedItems.sort((item1, item2) => {
      let val1, val2;
      if( columnName === 'component' ) {
        val1 = item1.objectType;
        val2 = item2.objectType;
      } else if( columnName === 'BU' )  {
        val1 = item1.object[columnName][0];
        val2 = item2.object[columnName][0];
      } else if( columnName === 'publishDate' ){
        val1 = new Date(item1.object[columnName]);
        val2 = new Date(item2.object[columnName]);
      } else {
        val1 = item1.object[columnName];
        val2 = item2.object[columnName];
      }

      if (event.direction === 'asc') {
        return val1 > val2 ? 1 : -1;
      } else {
        return val1 < val2 ? 1 : -1;
      }   
    })

    this.describeComponentTable = new MatTableDataSource(this.selectedItems);
  }

  public clearSearchText() {
    this.searchText = '';
    this.search();
  }

  public updatePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.search();
  }

  callSearch(e: any) {
    if (e.keyCode == 13) this.search();
  }

  resetFilters() {
    this.resetAllFilters$.next(true);
    this.filterDataService.removeSetOfFilters(['businessUnits', 'application', 'businessUnits']);
  }

  public popClearedFilter($event: any, filterPropertyName: string) {
    if (this.latestFilterSelection$.getValue() === filterPropertyName) {
      this.latestFilterSelection$.next('');
    }
  }

  public updateCheckboxFilterSelection(
    event: Set<string>,
    filterablePropertyName: string,
    filterDisplayName: string
  ) {
    
    this.currentPage = 0;
    if (event.size === 0) {
      this.filterDataService.removeFilterValue(filterablePropertyName);
      this.latestFilterSelection$.next('');
    } else {
      const filterableProperty = FilterablePropertyFactory.createFilterbleProperty(
        FilterType._1,
        filterablePropertyName,
        filterDisplayName,
        Array.from(event)
      );
      this.latestFilterSelection$.next(filterablePropertyName);
      this.filterDataService.setFilterValue(filterableProperty);
    }
  }

  getStandardColumnNames() : string[] {
    return [...this.columnNamesSorted.keys()]
  }

  removeSelectedItem(item): void {
    this.checkboxChanged(false, item);
    this.popuplateDescriptionsTable();
  }

  checkboxChanged(addItem: boolean, item) : void {
    if(addItem) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter(component => component.object.id !== item.object.id);
    }
  }

  //--- Members Start
  addRevisionApproval(user: microsoftgraph.User, role: string): void {
    let users: microsoftgraph.User[];
    if(role === 'owner') {
      users = this.owners;
    } else if(role === 'reviewer') {
      users = this.reviewers;
    } else {
      users = this.contributors;
    }

    if (this.userExistsWithinGivenRole(users, user.id) !== -1) {
      this.snackBarService.showSnackBar(
        true,
        `${user.displayName} is already added to this revision`, 
        'mat-snack-bar-error'
      );
    } else {
      users.push(user);
    }
  }

  private userExistsWithinGivenRole(usersList: microsoftgraph.User[], userOid: string): number {
    const index = usersList.findIndex(
      (approver) => approver.id === userOid
    );

    if ( index !== -1 ) {
      return index;
    } else {
      return -1;
    }
  }

  getNameForComponent( element, component){
    if (component === 'PIPE') {
      return element['name'];
    } else {
      return element['tag'].substring(0, element['tag'].indexOf('_'));
    }
  }

  removeMember(userToRemove: microsoftgraph.User, role: string): void {
    if(role === 'owner') {
      this.owners = this.owners.filter( (user) => user.id !== userToRemove.id );
    } else if(role === 'reviewer') {
      this.reviewers = this.reviewers.filter( (user) => user.id !== userToRemove.id );;
    } else {
      this.contributors = this.contributors.filter( (user) => user.id !== userToRemove.id );;
    }
  }
  getCurrentUserName(): string {
    return this.userService.getCurrentUser()?.name;
  }
  //---Members End

  isChecked(item) : boolean  {
    return this.selectedItems.filter(component => component.object.id === item.object.id).length !== 0;
  }

  popuplateDescriptionsTable(): void {
    this.describeComponentTable = new MatTableDataSource(this.selectedItems);
  }

  back() {
    this.creationStep--;
  }

  cancelAction(): void {
    this.dialogRef.close();
  }

  next(): void {
    this.creationStep++;
  }

  save(): void {
    console.log('save step');
  }
}
