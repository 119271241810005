// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  padding: 2%;
}

.top-container {
  height: 20%;
  display: flex;
  justify-content: space-between;
}
.top-container #title {
  font-size: xx-large;
  font-weight: bold;
}
.top-container #mat-close-btn {
  height: 36px;
  width: 20px;
  border: none;
  background-color: white;
}
.top-container #mat-close-btn fa-icon {
  color: #707070;
}

.lower-container .btn-divider {
  width: 2%;
  height: auto;
  display: inline-block;
}
.lower-container button {
  margin: 0;
  display: inline-block;
  width: 49%;
}

#mat-close-btn {
  cursor: pointer;
}

.cancel {
  margin-right: 2%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
