import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, tap } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import { CoatingSolutionDetailComponent } from 'src/app/shared/dialogs/Coating-Solution-Detail/coating-solution-detail.component';
import {
  EsraCoatingsAPIClient,
  CoatingSystemViewModel,
} from 'src/app/shared/models/autogenerated-coating';
import { ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ThemePalette } from '@angular/material/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-add-coating-system',
  templateUrl: './add-coating-system-dialogue.component.html',
  styleUrls: ['./add-coating-system-dialogue.component.scss'],
})
export class AddCoatingSystemDialogueComponent implements OnInit, OnDestroy {
  @ViewChild('searchBar') searchBar: ElementRef;
  addFormGroup: UntypedFormGroup;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER];
  coatingSystemTypeCtrl = new UntypedFormControl();
  coatingSystemTypes: string[] = [];
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    public dialogRef: MatDialogRef<CoatingSolutionDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private esraCoatingClient: EsraCoatingsAPIClient,
    private _formBuilder: UntypedFormBuilder,
    private snackBarService: SnackBarService
  ) {}

  readonly searchTermSubject = new Subject<string>();
  readonly searchTerm$ = this.searchTermSubject.asObservable();

  selectAction = true;
  createNewCoatingSystem = false;
  selectExistingCoatingSystem = false;
  addToRevisionButtonEnabled = false;

  title = 'select a coating system';
  primary: ThemePalette = 'primary';

  subscription = new Subscription();
  revisionId: number;
  overlayOpen = false;

  coatingSystems$: Observable<string[]> = this.getCoatingSystems();
  coatingSystemOptions: string[];

  selectedCoatingSystemLabelSubject = new BehaviorSubject<string>(
    'select a coating system'
  );
  selectedCoatingSystemLabel: string;

  addingToRevision$ = new BehaviorSubject<boolean>(false);

  isToggleSelected = false;

  vocCategories: string[] = ['Low VOC', 'Standard'];

  categoryOptions$: Observable<string[]>;
  serviceOptions$: Observable<string[]>;

  newCoatingSystem: CoatingSystemViewModel = new CoatingSystemViewModel();

  canCreateNew = false;
  
  allCoatingSystemTypesOptions: string[];
  coatingSystemTypeControl = new UntypedFormControl('');
  coatingSystemTypeFilteredOptions: Observable<string[]>;

  categoryOptions: string[];
  coatingSystemCategoryControl = new UntypedFormControl('');
  coatingSystemCategoryFilteredOptions: Observable<string[]>;

  serviceOptions: string[];
  coatingSystemServiceControl = new UntypedFormControl('');
  coatingSystemServiceFilteredOptions: Observable<string[]>;

  revisionSystemList: string[] = [];

  private _filter(value: string, source: any): string[] {
    const filterValue = value.toLowerCase();
    return source.filter(option => option.toLowerCase().includes(filterValue));
  }

  ngOnInit() {
    if (this.data) {
      this.revisionId = this.data.revisionId;
    }

    this.addFormGroup = this._formBuilder.group({
      coatingSystemId: ['', Validators.required],
      name: ['', Validators.required],
      coatingSystemCategory: ['', Validators.required],
      coatingSystemType: [''],
      service: ['', Validators.required],
      iopsSystem: [''],
      vocCategory: [''],
      surfacePrep: [''],
      anchorPattern: [''],
      totalDFT: [''],
      touchUp: [''],
      coatingSystemNotes: [''],
    });

    this.getCoatingSystemTypes();
    this.getAllCoatingSystemCategories();
    this.getAllCoatingSystemServices();

    this.coatingSystemTypeFilteredOptions = this.coatingSystemTypeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.allCoatingSystemTypesOptions)),
    );

    this.coatingSystemCategoryFilteredOptions = this.coatingSystemCategoryControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.categoryOptions)),
    );

    this.coatingSystemServiceFilteredOptions = this.coatingSystemServiceControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.serviceOptions)),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getCoatingSystems(): Observable<string[]> {
    return this.esraCoatingClient
      .getAllCoatingSystemLabels(this.data.techSpec)
      .pipe(
        tap(
          (records) => (this.coatingSystemOptions = records.map((x) => x.label))
        ),
        map((records) => records.map((x) => x.label))
      );
  }

  getCoatingSystemTypes() {
    this.esraCoatingClient
      .getAllCoatingSystemTypes()
      .subscribe((reponse)=>{
        this.allCoatingSystemTypesOptions = reponse.map((x)=> x.toString());
      })
  }

  getAllCoatingSystemCategories(){
    this.esraCoatingClient.getAllCoatingSystemCategories()
    .subscribe((reponse)=>{
      this.categoryOptions = reponse.map((x)=> x.toString());
    })

  }
  getAllCoatingSystemServices(){
    this.esraCoatingClient.getAllCoatingSystemServices().subscribe((reponse)=>{
      this.serviceOptions = reponse.map((x)=> x.toString());
    });
  }
 

  selectFromExisting() {
    this.selectAction = false;
    this.selectExistingCoatingSystem = true;
  }

  createNew() {
    this.selectAction = false;
    this.createNewCoatingSystem = true;

    // set pick lists
    this.categoryOptions$ =
      this.esraCoatingClient.getAllCoatingSystemCategories();

    this.serviceOptions$ = this.esraCoatingClient.getAllCoatingSystemServices();
  }

  toggle(event: MatSlideToggleChange) {
    if (event.checked) {
      this.isToggleSelected = true;
    } else {
      this.isToggleSelected = false;
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.subscription.add(
      this.coatingSystems$
        .pipe(
          tap(
            (coatingSystems) =>
              (this.coatingSystemOptions = coatingSystems.filter((l) =>
                l.toLocaleLowerCase().includes(filterValue?.toLocaleLowerCase())
              ))
          )
        )
        .subscribe()
    );
  }

  onSelection(selection: MatCheckboxChange) {
    if(selection.checked){
      this.addSystemIdToRevisionSystemIdList(selection.source.value);    
    } else {
      this.removeSystemIdFromRevisionSystemIdList(selection.source.value);
    }
    this.selectedCoatingSystemLabel = selection.source.value;
    if (this.selectedCoatingSystemLabel.length > 0) {
      this.addToRevisionButtonEnabled = true;
      this.selectedCoatingSystemLabelSubject.next(
        this.selectedCoatingSystemLabel
      );
    } else {
      this.addToRevisionButtonEnabled = false;
    }
  }

  addSystemIdToRevisionSystemIdList(system: string){
    this.revisionSystemList.push(system.split(' ')[0]);
  }

  removeSystemIdFromRevisionSystemIdList(system: string){
    const systemId = system.split(' ')[0];
    const index = this.revisionSystemList.indexOf(systemId);
    if(index > -1){
      this.revisionSystemList.splice(index, 1);
    }
  }

  addToRevision() {
    if (this.createNewCoatingSystem) {
      this.newCoatingSystem.technologyStandard = this.data.techSpec;

      this.subscription.add(
        this.esraCoatingClient
          .addNewCoatingSystemToRevision(this.revisionId, this.newCoatingSystem)
          .pipe(
            map(() => this.dialogRef.close()),
            catchError(err => of(alert(err.response))))
          .subscribe()
      );
    } else {

      if (this.isToggleSelected) {
        // add to be archived
        this.subscription.add(
          this.esraCoatingClient
            .addCoatingSystemsToBeDeleted(this.revisionId, this.revisionSystemList)
            .pipe(
              indicate(this.addingToRevision$),
              map(() => this.dialogRef.close())
            )
            .subscribe()
        );
      } else {
        // revise
          this.subscription.add(this.esraCoatingClient
            .addCoatingSystemsToRevision(this.revisionId, this.revisionSystemList)
            .pipe(
              indicate(this.addingToRevision$))
            .subscribe({
              next: () =>{
                this.dialogRef.close();
              },
              error: (err) => {
                this.snackBarService.showSnackBar(true, err.response, 'mat-snack-bar-error');
              }
            }));
      }
    }
  }

  cancel() {
    this.dialogRef.close();
  } 
}
