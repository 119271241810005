// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-section {
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: flex-end;
  row-gap: 100px;
}
.buttons-section button {
  margin: 10px 15px;
}

.coating-system-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 3%;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1%;
  margin-bottom: 1%;
  font-family: "GothamBold", Arial, sans-serif;
  font-weight: bold;
  font-size: 28px;
  letter-spacing: 0px;
  color: #0066b2;
}

.subtitle {
  font-family: "Gotham", Arial, sans-serif;
  font-size: 14px;
  color: #0066b2;
  font-weight: bold;
}

.main-pane {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2%;
}

.coats-title-container {
  display: flex;
  justify-content: space-between;
  margin: 3% 3%;
}

.coat {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}

.add-coat {
  display: flex;
  font-size: 20px;
}

.remove-coat {
  float: right;
  margin-right: 20px;
  padding: 10px 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
