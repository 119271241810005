
import { FilterType, FilterableProperty } from '../../models/autogenerated-piping';


export class FilterablePropertyFactory {

    public static createFilterbleProperty(
        filterType: FilterType,
        propertyName: string,
        filterDisplayName: string,
        filterOptions?: string[]
      ): FilterableProperty {
        const filterableProperty = new FilterableProperty();
        filterableProperty.filterType = filterType;
        filterableProperty.propertyName = propertyName;
        filterableProperty.displayName = filterDisplayName;
        filterableProperty.filterOptions = filterOptions;
        return filterableProperty;
      }
  }