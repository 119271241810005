import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, FormGroup, FormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { CoatingProductViewModel, EsraCoatingsAPIClient } from 'src/app/shared/models/autogenerated-coating';
import { CelsiusToFahrenheitPipe } from 'src/app/shared/pipes/celcius-to-fahrenheit.pipe'
import { FahrenheitToCelsiusPipe } from 'src/app/shared/pipes/fahrenheit-to-celcius.pipe'

@Component({
  selector: 'app-add-service-temperature',
  templateUrl: './add-service-temperature.component.html',
  styleUrls: ['./add-service-temperature.component.scss'],
})
export class AddServiceTemperatureComponent implements OnInit {
  private fahrenheitToCelsiusPipe = new FahrenheitToCelsiusPipe();
  private celsiusToFahrenheitPipe = new CelsiusToFahrenheitPipe();

  addServiceTempClicked = false;
  temperature;

  constructor(
    private esraCoatingClient: EsraCoatingsAPIClient,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    if (!this.parentForm) {
      this.parentForm = new FormGroup({});
    }

    this.initializeProductServiceTemperaturePairs(this.coatingProduct);
  }

  @Input()
  coatingProduct: CoatingProductViewModel;

  @Input()
  isFahrenheit = false;

  @Input()
  isReadOnly = true;

  @Input()
  parentForm: UntypedFormGroup;

  @Output()
  formEvent = new EventEmitter<UntypedFormGroup>();

  serviceControls = [];
  tempControls = [];

  // selection list observables
  services$: Observable<string[]> =
    this.esraCoatingClient.getAllCoatingSystemServices();

  onInputChange(eventTemp) {
    if (this.isTemperatureNumber(eventTemp.target.value)) {
      this.formEvent.emit(this.parentForm);
    } else {
      const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
      const verticalPosition: MatSnackBarVerticalPosition = 'top';
      const snackBarOptions = {
        horizontalPosition: horizontalPosition,
        verticalPosition: verticalPosition,
        duration: 1500
      }
      snackBarOptions['panelClass'] = ['snackBar'];

      this.snackBar.open('Temperature must be a number', '', snackBarOptions);
    }
  }

  initializeProductServiceTemperaturePairs(inputCoatingProduct) {
    if (inputCoatingProduct !== null) {
      this.coatingProduct.productServiceTemperatures.forEach((productServiceTemperatureVM) => {
        this.addProductServiceTemperaturePair(productServiceTemperatureVM.service, productServiceTemperatureVM.serviceTempMaxC);
      });
    }
  }

  addProductServiceTemperaturePair(service=null, temperature=null) {
    const serviceControlToAdd = 'service' + this.serviceControls.length.toString();
    const tempControlToAdd = 'temperature' + this.tempControls.length.toString();

    if (service !== null) {
      this.parentForm.addControl(serviceControlToAdd, new FormControl(service.toString()));
    } else {
      this.parentForm.addControl(serviceControlToAdd, new FormControl());
    }
    
    if (temperature !== null) {
      this.parentForm.addControl(tempControlToAdd, new FormControl(temperature.toString()));
    } else {
      this.parentForm.addControl(tempControlToAdd, new FormControl());
    }

    this.serviceControls.push(serviceControlToAdd);
    this.tempControls.push(tempControlToAdd);

    this.addServiceTempClicked = true;
    this.formEvent.emit(this.parentForm);
  }

  removeProductServiceTemperaturePair(index) {
    this.parentForm.removeControl('service' + index.toString());
    this.parentForm.removeControl('temperature' + index.toString());

    this.serviceControls.splice(index, 1);
    this.tempControls.splice(index, 1);

    const parentFormLen = Object.keys(this.parentForm.controls).length;

    const parentFormServices = [];
    const parentFormTemps = [];

    for (let i=0; i < parentFormLen/2; i++) {
      parentFormServices.push(this.parentForm.get(this.serviceControls[i]).value);
      parentFormTemps.push(this.parentForm.get(this.tempControls[i]).value);
    }

    Object.keys(this.parentForm.controls).forEach(key => {
      this.parentForm.removeControl(key);
    });

    this.serviceControls.splice(0, this.serviceControls.length);
    this.tempControls.splice(0, this.tempControls.length);
    
    for (let i=0; i < parentFormServices.length; i++) {
      this.addProductServiceTemperaturePair(parentFormServices[i], parentFormTemps[i]);
    }

    console.log(this.parentForm.controls);

    this.formEvent.emit(this.parentForm);
  }

  toggle(event: MatSlideToggleChange) {
    if (event.checked) {
      this.isFahrenheit = true;
    } else {
      this.isFahrenheit = false;
    }

    if (this.isTemperatureNumber(this.temperature)) {
      this.temperature = this.fahrenheitToCelsiusPipe.transform(Number(this.temperature));
      this.temperature = this.celsiusToFahrenheitPipe.transform(Number(this.temperature));
    }
  }

  isTemperatureNumber(temperature) {
    return !isNaN(Number(temperature));
  }

}
