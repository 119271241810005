import { Injectable } from "@angular/core";
import { Observable, first, map, tap } from "rxjs";
import { FileResponse } from "src/app/shared/models/autogenerated-welding";

@Injectable({
    providedIn: 'root',
  })
  export class DownloadService {
    downloadFile(fileData: Observable<FileResponse>, fileName?: string) {
          fileData
            .pipe(
                first(),
                tap((response) => {
                    const file = response.data;
                    const fileUrl = URL.createObjectURL(file);
                    const e = document.createElement('a');
                    e.href = fileUrl;
                    if(fileName) {
                        e.download = fileName;
                    } else {
                        e.download = response.fileName;
                    }
                    document.body.appendChild(e);
                    e.click();
                    document.body.removeChild(e);
                }),
                map(() => {
                    return;
                })
            )
            .subscribe();
      }
  }