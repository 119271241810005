import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { Counter, indicate, indicateWithCounter } from 'src/app/operators';
import {
  ApiException,
  EsraWeldingAPIClient,
  FileParameter,
  ICmidUser,
  WeldingRevision,
  WeldingRevisionViewModel, 
  WeldingProcedureViewModel,
  RevisionStatus 
} from 'src/app/shared/models/autogenerated-welding';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-create-welding-revision',
  templateUrl: './create-welding-revision.component.html',
  styleUrls: ['./create-welding-revision.component.scss'],
})
export class CreateWeldingRevisionComponent implements OnInit, OnDestroy {
  constructor(
    private _esraWeldingApiClient: EsraWeldingAPIClient,
    private _userService: UserService,
    private _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<CreateWeldingRevisionComponent>
  ) {}

  private readonly subscription = new Subscription();

  progressBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  archiveOnly = false;
  uploadOrUpdate = false;

  fileToUpload: File | null = null;
  canUpload = false;

  newRevision: WeldingRevision;
  newRevisionPreview: WeldingRevisionViewModel;

  weldingProcedure: WeldingProcedureViewModel;

  addApprover = false;

  revisionApprover: ICmidUser;

  allWpsNames$: Observable<string[]> = this.getAllWeldingProceduresToArchive();
  wpsOptions: string[];
  fileToArchive = "";

  ngOnInit(): void {
    this.newRevision = new WeldingRevision();
    this.newRevision.status = RevisionStatus._0;
    const currUser = this._userService.getCurrentUser();
    this.newRevision.authorOid = currUser.localAccountId;
    this.subscription.add(
      this.allWpsNames$
      .pipe(
        tap((file) => {
          const options = file.filter((p) =>
            p
          );
          this.wpsOptions = options;
        })
      )
      .subscribe()
    );
   
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.subscription.add(
      this.allWpsNames$
        .pipe(
          tap((file) => {
            const options = file.filter((p) =>
              p.startsWith(filterValue)
            );
            this.wpsOptions = options
          })
        )
        .subscribe());
  }

  onSelection(selection: MatRadioChange) {
    this.fileToArchive = selection.value;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    //First we check if there is active revision with same filename. Endpoint returns existing revision.
    //In case of no active revision with given filename we get 404 and we shall proceed with another validation 
    // in catchError section. Anothar check is to validate if this is new procedure or update of existing one.
    
    this._esraWeldingApiClient
    .getActiveRevisionWithFile(this.fileToUpload.name)
    .pipe(indicate(this.progressBar$),
      first(),
      tap(response => {
        this.newRevision.name = '';
        this.canUpload = false;
        this._snackBarService.showError(this.fileToUpload.name + ' is already part of an active revision (' + response.name + ').');
      }),
      catchError(() => 
        this._esraWeldingApiClient
          .getLatestPublishedVersionOfWeldingProcedure(this.fileToUpload.name)
          .pipe(indicate(this.progressBar$),
              first())
      )).subscribe({
        next: (response) => {
          if(response instanceof WeldingProcedureViewModel){
            this.newRevision.name =
              'Update WPS: ' + this.fileToUpload.name.split('.pdf')[0];
            this.canUpload = true;
          }
        },
        error: (err) => {
            if (err.status === 404) {
              this.newRevision.name =
                'New WPS: ' + this.fileToUpload.name.split('.pdf')[0];
              this.canUpload = true;
            } else {
              console.error(err);
              this.canUpload = false;
            }
          }
        });    
  }

  uploadFile() {
    if (this.newRevision.name === null || this.newRevision.name.trim() === '') {
      this._snackBarService.showError('You must provide a revision name.');
      return;
    }

    this.canUpload = false;
    const counter = new Counter();

    this._esraWeldingApiClient
      .createRevision(this.fileToUpload.name, this.newRevision)
      .pipe(indicateWithCounter(this.progressBar$, counter),
          first())
      .subscribe({
        next: (response) => {
            const fileParameter: FileParameter = {
              data: this.fileToUpload,
              fileName: this.fileToUpload.name,
            };
            this.newRevision.id = response.id;

            this._esraWeldingApiClient
              .addWPSToRevision(response.id, fileParameter)
              .pipe(indicateWithCounter(this.progressBar$, counter),
                  first())
              .subscribe({
                  next: (response) => {
                    this.weldingProcedure = response.weldingProcedures[0];
                  },
                  error: (err: ApiException) => {
                    this._snackBarService.showError(err.response);
                  }
                });
          },
          error: (err) => {
            console.log(err);
          }
      });
  }

  setFileToArchive(): void {
    this.newRevision.name = `Archiving: ${this.fileToArchive}`;
    this._esraWeldingApiClient.archiveRevision(this.newRevision).pipe(first())
    .subscribe({
        next: (response) => {
          this.newRevision.id = response.id;

          this._esraWeldingApiClient.setWeldingProcedureToBeArchived(this.newRevision.id, this.fileToArchive).pipe(
            first(),
            tap(() => this.addApprover = true)
            ).subscribe();
        },
        error: (err) => {
          console.log(err);
        }
      });
  }

  validatedWps(event: any) {
    this.addApprover = true;
  }

  setApprover(event: ICmidUser): void {
    this.revisionApprover = event;
  }

  publishOnProgressBar(event: boolean) {
    this.progressBar$.next(event);
  }

  sendForApproval(): void {
    this._esraWeldingApiClient
      .sendRevisionForApproval(this.newRevision.id)
      .pipe(indicate(this.progressBar$), first())
      .subscribe({
          next: () => {
            this._snackBarService.showInfo('Email notification sent to approver.');
            this.cancel();
          },
          error: (error) => {
            this._snackBarService.showError(error.response);
          }
        });
  }

  getAllWeldingProceduresToArchive(): Observable<string[]> {
    return this._esraWeldingApiClient.getAllPublishedWpsNames();
  }

  cancel() {
    this.dialogRef.close(true);
  }
}