
import {Component, Input} from '@angular/core';
import { PipeClassViewModel } from '../../shared/models/autogenerated-piping';

@Component({
  selector: 'app-piping-summary',
  styleUrls: ['piping-summary.component.scss'],
  templateUrl: 'piping-summary.component.html',
})
export class PipingSummaryComponent {
  openState = true;
  @Input() pipeClassViewModel: PipeClassViewModel;
  serviceDisplayName = "";
}
