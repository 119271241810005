// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}

.items-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mat-mdc-form-field {
  width: 60px;
  margin-left: 20px;
}

.pagination {
  display: flex;
}
.pagination div {
  text-align: center;
  font-size: 14px;
  font-family: "Gotham", Arial, sans-serif;
  letter-spacing: 0.1px;
  color: #0066b2;
}
.pagination div:hover {
  color: #0b2d71;
}
.pagination .active {
  color: #0b2d71;
  border-bottom-color: #0b2d71;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
