import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatPaginatorGoToComponent } from 'src/app/shared/components/mat-paginator-go-to/mat-paginator-go-to.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faInfoCircle, faPen, faSearch, faPlusCircle, faTrash, faPlane, faComment } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { productionGuard } from 'src/app/core/guards/guards';

@Component({
  selector: 'app-dictionary-simple-page',
  templateUrl: './dictionary-simple-page.component.html',
  styleUrls: ['./dictionary-simple-page.component.scss']
})
export abstract class DictionarySimplePageComponent<T> implements OnInit {
  static SEARCH_QUERY_NAME = 'searchQuery';
  static IS_ACTIVE_NAME = 'isActive';

  dictionaryDefinition: DictionaryItemDefinition<T>;
  
  currentPage = 0;
  totalCount: number;
  lastPage: number;
  pageSize = 25;
  pageSizes = [2, 5, 10, 25, 50, 100];
  filterIsShowAll = false;

  staticColumnNames: string[] = [
    'actions'
  ];
  columnNames: string[];

  faEdit = faPen;
  faSearch = faSearch;
  faInfo = faInfoCircle;
  faCreate = faPlusCircle;
  faTrashCan = faTrash;
  faPlane = faPlane;
  faComment = faComment;

  filterDictionary = new Map<string,string>();

  searching$ = new BehaviorSubject<boolean>(false);
  dataSource = new MatTableDataSource<T>();

  @ViewChild('searchInput') searchInput: ElementRef;

  @ViewChild('paginator') set paginator(paginator: MatPaginatorGoToComponent) {
    this.dataSource.paginator = paginator.paginator;
  }

  @ViewChild(MatSort, {static: false}) set sorter(sort: MatSort) {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (item, property) => {
        const dictionaryItem = this.dictionaryDefinition.dictionaryItems.find(di => di.propertyName === property);
        if (dictionaryItem) {
          return this.normalizeToSmallCaps(dictionaryItem.getItem(item));          
        } else {
          return this.normalizeToSmallCaps(item[property].toString());
        }
      };
      this.dataSource.sort = sort;
    }
  }

  ngOnInit(): void {
    this.dictionaryDefinition = this.initializeDictionaryDefinition();
    this.columnNames = this.dictionaryDefinition.dictionaryItems.map(item => item.propertyName);
    if (this.dictionaryDefinition.displayStatusColumn) {
      this.columnNames.push("isActive");
    }
    
    if (productionGuard()) {
      this.columnNames = this.columnNames.concat(this.staticColumnNames);
    }
    this.searchAll();
    this.filterDictionary.set(DictionarySimplePageComponent.SEARCH_QUERY_NAME, '');
    this.dataSource.filterPredicate = (record, filter) => {
      const map = new Map<string,string>(JSON.parse(filter));
      return this.getFilterPredicate(record, map);
    };
    this.search();
  }

  public updatePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
  }

  setSearchValueAndSearch(event) {
    this.filterDictionary.set(DictionarySimplePageComponent.SEARCH_QUERY_NAME, event.target.value.trim().toLocaleLowerCase());
    if (event.keyCode == 13) {
      this.search();
    }
  }

  public search() {
    if(this.filterIsShowAll) {
      this.filterDictionary.delete(DictionarySimplePageComponent.IS_ACTIVE_NAME);
    } else {
      this.filterDictionary.set(DictionarySimplePageComponent.IS_ACTIVE_NAME, String(!this.filterIsShowAll));
    }
    this.dataSource.filter = JSON.stringify(Array.from(this.filterDictionary.entries()));
  }

  public clearSearchText() {
    this.currentPage = 0;
    this.filterDictionary.set(DictionarySimplePageComponent.SEARCH_QUERY_NAME, '');
    this.searchInput.nativeElement.value = '';
    this.search();
  }

  toggle(event: MatSlideToggleChange) {
    this.filterIsShowAll = event.checked;
    this.search();
  }

  protected normalizeToSmallCaps(sortString: string): string {
    let cleanString = '';
    if (sortString) {
      cleanString = sortString.trim();
    }
    return cleanString.toLowerCase();
  }

  protected initializeDictionaryDefinition(): DictionaryItemDefinition<T> {
    return new DictionaryItemDefinition();
  }

  protected abstract updateStatus(item: T): void;

  protected abstract searchAll(): void;

  protected abstract getFilterPredicate(record: T, parametersMap: Map<string, string>): boolean ;

  protected abstract createNew(): void;

  protected abstract editItem(item: T): void;

  protected abstract editItemReferenced(item: object): void;

  protected abstract deleteItem(item: T): void;

  protected abstract getUsageInformation(item: object): void;

  protected abstract getNoMatchMessage(): string;
}


export class DictionaryItem<T> {
  displayName: string;
  propertyName: string;
  // standard or extended
  columnSize = 'standard';

  constructor(displayName: string, propertyName: string, columnSize?: string, getItemFunction?) {
    this.displayName = displayName;
    this.propertyName = propertyName;
    if (getItemFunction) {
      this.getItem = getItemFunction;
    }

    if (columnSize) {
      this.columnSize = columnSize;
    }
  }

  getItem = (item: T) => {
    return item[this.propertyName];
  }
}

export class DictionaryItemDefinition<T> {
  displayStatusColumn = false;
  dictionaryItems: DictionaryItem<T>[];
}
