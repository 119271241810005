import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valveSizeDisplay',
})
export class ValveSizeDisplayPipe implements PipeTransform {
  transform(size: string, units: string): string {
    if (!size) {
        return '';
    }
    
    return String(size)
        .replace(/(\d)(\s)/gi, '$1"$2')
        .replace(/\s.+$/gi, '$&' + units);
  }
}
