import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComponentCatalogComponent } from './piping/dictionaries/component-catalog/component-catalog.component';
import { DictionariesComponent } from './piping/dictionaries/dictionaries.component';
import { CommonnotesComponent } from './piping/dictionaries/common-notes/common-notes.component';
import { ServicesComponent } from './piping/dictionaries/services/services.component';
import { PressuretemperatureComponent } from './piping/dictionaries/pressure-temperature/pressure-temperature.component';
import { BranchComponent } from './piping/dictionaries/branch/branch.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PressureTempPreviewComponent } from './piping/dictionaries/dialogs/pressure-temp-preview/pressure-temp-preview.component';
import { PressureTempEditComponent } from './piping/dictionaries/dialogs/pressure-temp-edit/pressure-temp-edit.component';
import { SharedModule } from '../shared/shared.module';
import { SearchModule } from '../search/search.module';
import { DictionaryDeleteComponent } from './piping/dictionaries/dialogs/dictionary-delete/dictionary-delete.component';
import { ServiceAddEditComponent} from './piping/dictionaries/dialogs/service-add-edit/service-add-edit.component';
import { CommonNotesAddEditComponent } from './piping/dictionaries/dialogs/common-notes-add-edit/common-notes-add-edit.component';
import { ServiceNoteUsageComponent } from './piping/dictionaries/dialogs/service-note-usage/service-note-usage.component';
import { EditConfirmComponent } from './piping/dictionaries/dialogs/edit-confirm/edit-confirm.component';
import { ComponentAddEditComponent } from './piping/dictionaries/dialogs/component-add-edit/component-add-edit.component';
import { BranchPreviewComponent } from './piping/dictionaries/dialogs/branch-preview/branch-preview.component';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { BranchCreateComponent } from './piping/dictionaries/dialogs/branch-create/branch-create.component';
import { ServiceDetailsComponent } from './piping/dictionaries/service-details/service-details.component';
import { ServiceDetailsAddEditComponent } from './piping/dictionaries/dialogs/service-details-add-edit/service-details-add-edit.component';
import { BranchTableCodesComponent } from './piping/dictionaries/branch-table-codes/branch-table-codes.component';
import { BranchTableCodeAddEditComponent } from './piping/dictionaries/dialogs/branch-table-code-add-edit/branch-table-code-add-edit.component';

@NgModule({
  declarations: [
    ComponentCatalogComponent, 
    DictionariesComponent, 
    CommonnotesComponent, 
    ServicesComponent, 
    PressuretemperatureComponent, 
    BranchComponent, 
    PressureTempPreviewComponent, 
    PressureTempEditComponent, 
    CommonNotesAddEditComponent,
    DictionaryDeleteComponent,
    ServiceAddEditComponent,
    ServiceNoteUsageComponent,
    EditConfirmComponent,
    BranchPreviewComponent,
    ComponentAddEditComponent,
    ConfirmDialogComponent,
    BranchCreateComponent,
    ServiceDetailsComponent,
    ServiceDetailsAddEditComponent,
    BranchTableCodesComponent,
    BranchTableCodeAddEditComponent
  ],
  imports: [
    SharedModule,
    SearchModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    OverlayModule,
    RouterModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCardModule,
    MatChipsModule,
    MatPaginatorModule,
    FontAwesomeModule
  ],
})
export class AdmintoolModule {}
