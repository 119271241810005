import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { GraphService } from 'src/app/core/services/graph.service';
import { indicate } from 'src/app/operators';
import { Constants } from 'src/app/shared/constants/constants';
import {
  EsraWeldingAPIClient,
  RevisionCommentStatus,
  WeldingRevisionViewModel,
} from 'src/app/shared/models/autogenerated-welding';
import { CreateWeldingRevisionComponent } from '../../dialogues/create-welding-revision/create-welding-revision.component';

@Component({
  selector: 'app-all-welding-revisions',
  templateUrl: './all-welding-revisions.component.html',
  styleUrls: ['./all-welding-revisions.component.scss'],
})
export class AllWeldingRevisionsComponent implements OnDestroy {

  private readonly subscription = new Subscription();
  loading$ = new BehaviorSubject<boolean>(false);
  dataSource = null;

  revisionsFromAPI$: Observable<WeldingRevisionViewModel[]> =
    this.esraWeldingApiClient.getAllRevisions();

  revisions$: Observable<WeldingRevisionUserViewModel[]> =
    this.getAllRevisions();

  canCreateRevisions$: Observable<boolean> = this.checkCanCreate();

  columnsToDisplay: string[] = ['name', 'authorOid', 'status', 'publishDate'];
  
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }

  constructor(
    private esraWeldingApiClient: EsraWeldingAPIClient,
    private graphService: GraphService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkCanCreate(): Observable<boolean> {
    return this.esraWeldingApiClient
      .getUserAppRoles()
      .pipe(
        map((roles) =>
          roles.find((x) => x === Constants.weldingRevisionWriter) === undefined
            ? false
            : true
        )
      );
  }

  getAuthorsFromGraph(): Observable<microsoftgraph.User[]> {
    return combineLatest([this.revisionsFromAPI$]).pipe(
      switchMap(([revisions]) =>
        this.graphService.getUsersByOids(revisions.map((r) => r.authorOid))
      )
    );
  }

  getAllRevisions(): Observable<WeldingRevisionUserViewModel[]> {
    return this.revisionsFromAPI$.pipe(
      indicate(this.loading$),
      switchMap(revisions => 
        this.getAuthorsFromGraph()
        .pipe(first(),
        map(users => {
            const revisonModels = new Array<WeldingRevisionUserViewModel>();
            revisions.forEach((revision) =>
              revisonModels.push(revision as WeldingRevisionUserViewModel)
            );

            revisonModels.forEach((revision) => {
                revision.activeComments = revision.comments.filter(
                      (comment) =>
                        comment.status === RevisionCommentStatus._0 &&
                        comment.parentCommentId === null
                    ).length;
                const match = users.filter((u) => u.id === revision.authorOid);
                if (match[0]) {
                  revision.surname = match[0].surname ? match[0].surname : '';
                  revision.givenName = match[0].givenName ? match[0].givenName : '';
                  revision.initials =
                    revision.givenName.charAt(0) + ' ' + revision.surname.charAt(0);
                }

                if (
                  revision.publishDate.getDate() === new Date('1/1/0001').getDate()
                ) {
                  revision.publishDate = undefined;
                }
              });
            this.dataSource = new MatTableDataSource(revisonModels);
            this.dataSource.sort = this.matSort;
            return revisonModels;
          }))
      )
    );
  }

  getStatusClass(status: string): string {
    status = status.replace(/\s/g, '');
    return status.toLowerCase();
  }

  createNewRevision() {
    const dialogRef = this.dialog.open(CreateWeldingRevisionComponent, {
      width: '75%',
      height: '70%',
      disableClose: false,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if( result === true) {
        this.revisionsFromAPI$ = this.esraWeldingApiClient.getAllRevisions();
        this.revisions$ = this.getAllRevisions();
      }
    });
  }
}

export class WeldingRevisionUserViewModel extends WeldingRevisionViewModel {
  surname = '';
  givenName = '';
  initials = '';
  activeComments: number;
}
