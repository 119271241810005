import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { indicate } from 'src/app/operators';
import { Constants } from 'src/app/shared/constants/constants';
import {
  CoatingRevisionViewModel,
  EsraCoatingsAPIClient,
  FileResponse,
  TechnologyStandardViewModel,
} from 'src/app/shared/models/autogenerated-coating';

@Component({
  selector: 'app-spec-export',
  templateUrl: './spec-export.component.html',
  styleUrls: ['./spec-export.component.scss'],
})
export class SpecExportComponent implements OnDestroy {
  _all = 'all';
  faIcon = faFileExport;
  selectedTechSpec: string = this._all;
  private readonly subscription = new Subscription();
  loadingFilters$ = new BehaviorSubject<boolean>(false);
  loadingRevisions$ = new BehaviorSubject<boolean>(false);
  downloadingRevisions$ = new BehaviorSubject<boolean>(false);
  canExportRevisions$: Observable<boolean> = this.checkCanExport();
  showRevisions = false;
  downloadTables = this.canDownloadTables();

  technologySpecifications$: Observable<TechnologyStandardViewModel[]> =
    this.getTechnologyStandards();

  revisions$ = new BehaviorSubject<CoatingRevisionViewModel[]>(null);
  dataSource = new MatTableDataSource<CoatingRevisionViewModel>();
  columnsToDisplay: string[] = ['name', 'version', 'download'];

  constructor(private esraCoatingClient: EsraCoatingsAPIClient) {}

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getTechnologyStandards(): Observable<TechnologyStandardViewModel[]> {
    return this.esraCoatingClient.getTechnologyStandards().pipe(
      indicate(this.loadingFilters$),
      map((response) => response)
    );
  }

  getCoatingRevisions() {
    this.subscription.add(
      this.esraCoatingClient
        .getAllCoatingSystemRevisions(this.selectedTechSpec)
        .pipe(
          indicate(this.loadingRevisions$),
          tap((revisions) => {
            let revisonModels = new Array<CoatingRevisionViewModel>();
            revisions.forEach((revision) =>
              revisonModels.push(revision)
            );
            this.dataSource = new MatTableDataSource(revisonModels);
            this.dataSource.sort = this.matSort;
          })
        )
        .subscribe(() => (this.showRevisions = true))
    );
  }

  updateTechSpecSelection(): void {
    if (this.selectedTechSpec != this._all) {
      this.getCoatingRevisions();
    }
    this.downloadTables = this.canDownloadTables();
  }

  checkCanExport(): Observable<boolean> {
    return this.esraCoatingClient
      .getUserAppRoles()
      .pipe(
        map((roles) =>
          roles.find((x) => x === Constants.coatingSpecificationExport) ===
          undefined
            ? false
            : true
        )
      );
  }

  canDownloadTables(): boolean {
    return this.selectedTechSpec === 'COM-SU-5191' || this.selectedTechSpec === this._all;
  }

  getRevision(revision: number, revisionName: string) {
    this.subscription.add(
      this.esraCoatingClient
        .getThirdParty(this.selectedTechSpec, revision)
        .pipe(
          indicate(this.downloadingRevisions$),
          tap((response) => {
            this.createDownloadFile(response, revisionName);
          })
        )
        .subscribe()
    );
  }

  getSystemSelectionTable(revision: number, revisionName: string) {
    this.subscription.add(
      this.esraCoatingClient
        .getSystemSelectionReport(revision)
        .pipe(
          indicate(this.downloadingRevisions$),
          tap((response) => {
            this.createDownloadFile(response, revisionName + ' - system selection table')
          })
        )
        .subscribe()
    );
  }

  createDownloadFile(response: FileResponse, fileName: string){
    const file = response.data;
    const fileUrl = URL.createObjectURL(file);
    const e = document.createElement('a');
    e.href = fileUrl;
    e.download = fileName + '.docx';
    document.body.appendChild(e);
    e.click();
    document.body.removeChild(e);
  }
}
