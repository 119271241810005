// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  color: #0b2d71;
  font-weight: bolder;
  margin-bottom: 0;
}

hr {
  color: #0b2d71;
  border-top: 0.1em solid;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.data-container {
  margin-top: 4%;
}

.notes-container {
  margin-bottom: 2em;
}

.legend-container {
  display: flex;
  flex-direction: row;
}

#legend-table td {
  font-size: 12pt;
  font-family: "Gotham", Arial, sans-serif;
  padding: 8px 4px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
