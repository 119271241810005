import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentViewModel, SearchRequest, EsraPipingAPIClient,EsraSearchFilterModel } from 'src/app/shared/models/autogenerated-piping';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { indicate } from 'src/app/operators';

function equalsIgnoreCase(val1: string, val2: string): boolean {
  return val1.trim().toUpperCase() === val2.trim().toUpperCase();
}

@Component({
  selector: 'app-component-add-edit',
  templateUrl: './component-add-edit.component.html',
  styleUrls: ['./component-add-edit.component.scss']
})
export class ComponentAddEditComponent implements OnInit {
  addFormGroup: UntypedFormGroup;
  filteredSch1: Observable<string[]>;
  filteredSch2: Observable<string[]>;
  filteredEnds: Observable<string[]>;
  filteredType: Observable<string[]>;
  filteredGroup: Observable<string[]>;
  filteredMaterial: Observable<string[]>;
  filteredASTM: Observable<string[]>;
  filteredCONST: Observable<string[]>;
  filteredSTD: Observable<string[]>;
  filteredRequirements: Observable<string[]>;

  filteredSch1Init = [''];
  filteredSch2Init = [''];
  filteredEndsInit = [''];
  filteredTypeInit = [];
  filteredGroupInit = [''];
  filteredMaterialInit = [''];
  filteredASTMInit = [''];
  filteredCONSTInit = [''];
  filteredSTDInit = [''];
  filteredRequirementsInit = [''];

  searching$ = new BehaviorSubject<boolean>(false);

  componentModel: ComponentViewModel;
  isEditMode = false;
  title = 'create new component';

  genericAutocompleteErrorMessage = 'Please provide correct value from dropdown!';

  constructor(public dialogRef: MatDialogRef<ComponentAddEditComponent>,
    private _esraPipingAPIClient: EsraPipingAPIClient,
    private _formBuilder: UntypedFormBuilder,
    private _snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public _componentModel: any
  ) { }

  ngOnInit(): void {
    const searchRequest = new SearchRequest();
    searchRequest.page = 0;
    searchRequest.perPage = 0;
    searchRequest.mainSearch = "*";

    this._esraPipingAPIClient.componentCatalogSearch(searchRequest)
      .pipe(
        indicate(this.searching$),
        first()
      )
      .subscribe(
        {
          next: (searchResponse) => {
            for (const filter of searchResponse.searchFilters) {
              this.buildFilterOptions(filter);
            }
            this.initAutocompleteFiledValues();
          },
          error: (err) => console.log('Error: ' + err)
        }
      );
        
    this.loadModelData();
    this.initFormGroup();
  }

  loadModelData(): void {
    this.componentModel = { ... this._componentModel };
    if (this.componentModel.componentId) {
      this.isEditMode = true;
      this.title = "Edit component";
    }
  }

  private buildFilterOptions(filter: EsraSearchFilterModel): void{
    const filterPropertyName = filter.filterPropertyName;

    if (equalsIgnoreCase(filterPropertyName, 'sch1rat')) {
      this.filteredSch1Init = this.filteredSch1Init.concat(filter.options);
      return;
    }

    if (equalsIgnoreCase(filterPropertyName, 'sch2')) {
      this.filteredSch2Init = this.filteredSch2Init.concat(filter.options);
      return;
    }

    if (equalsIgnoreCase(filterPropertyName, 'ends')) {
      this.filteredEndsInit = this.filteredEndsInit.concat(filter.options);
      return;
    }

    if (equalsIgnoreCase(filterPropertyName, 'type')) {
      this.filteredTypeInit = filter.options;
      return;
    }

    if (equalsIgnoreCase(filterPropertyName, 'material')) {
      this.filteredMaterialInit = this.filteredMaterialInit.concat(filter.options);
      return;
    }

    if (equalsIgnoreCase(filterPropertyName, 'astm')) {
      this.filteredASTMInit = this.filteredASTMInit.concat(filter.options);
      return;
    }

    if (equalsIgnoreCase(filterPropertyName, 'construction')) {
      this.filteredCONSTInit = this.filteredCONSTInit.concat(filter.options);
      return;
    }

    if (equalsIgnoreCase(filterPropertyName, 'designstandard')) {
      this.filteredSTDInit = this.filteredSTDInit.concat(filter.options);
      return;
    }

    if (equalsIgnoreCase(filterPropertyName, 'SpecialRequirements')) {
      this.filteredRequirementsInit = this.filteredRequirementsInit.concat(filter.options);
      return;
    }
    
    if (equalsIgnoreCase(filterPropertyName, 'group')) {
      this.filteredGroupInit = filter.options;
    }
  }

  private initFormGroup() {
    this.addFormGroup = this._formBuilder.group({
      description: [''],
      sch1: [''],
      sch2: [''],
      ends: [''],
      type: ['', Validators.required],
      group: ['', Validators.required],
      material: [''],
      astm: [''],
      const: [''],
      design: [''],
      requirements: [''],
    });

    this.addFormGroup.get('description').disable();
  }

  private initAutocompleteFiledValues() {
    this.addFormGroup.get('sch1').setValidators([this.autocompleteStringValidator(this.filteredSch1Init)]);
    this.addFormGroup.get('sch2').setValidators([this.autocompleteStringValidator(this.filteredSch2Init)]);
    this.addFormGroup.get('ends').setValidators([this.autocompleteStringValidator(this.filteredEndsInit)]);
    this.addFormGroup.get('type').setValidators([this.autocompleteStringValidator(this.filteredTypeInit), Validators.required]);
    this.addFormGroup.get('group').setValidators([this.autocompleteStringValidator(this.filteredGroupInit), Validators.required]);
    this.addFormGroup.get('material').setValidators([this.autocompleteStringValidator(this.filteredMaterialInit)]);
    this.addFormGroup.get('astm').setValidators([this.autocompleteStringValidator(this.filteredASTMInit)]);
    this.addFormGroup.get('const').setValidators([this.autocompleteStringValidator(this.filteredCONSTInit)]);
    this.addFormGroup.get('design').setValidators([this.autocompleteStringValidator(this.filteredSTDInit)]);
    this.addFormGroup.get('requirements').setValidators([this.autocompleteStringValidator(this.filteredRequirementsInit)]);

    this.filteredSch1 = this.addFormGroup.get('sch1').valueChanges.pipe(
      startWith(''),
      map(value => this._filterOptions(value, this.filteredSch1Init))
    );
    this.filteredSch2 = this.addFormGroup.get('sch2').valueChanges.pipe(
      startWith(''),
      map(value => this._filterOptions(value, this.filteredSch2Init))
    );
    this.filteredEnds = this.addFormGroup.get('ends').valueChanges.pipe(
      startWith(''),
      map(value => this._filterOptions(value, this.filteredEndsInit))
    );
    this.filteredType = this.addFormGroup.get('type').valueChanges.pipe(
      startWith(''),
      map(value => this._filterOptions(value, this.filteredTypeInit))
    );
    this.filteredGroup = this.addFormGroup.get('group').valueChanges.pipe(
      startWith(''),
      map(value => this._filterOptions(value, this.filteredGroupInit))
    );
    this.filteredMaterial = this.addFormGroup.get('material').valueChanges.pipe(
      startWith(''),
      map(value => this._filterOptions(value, this.filteredMaterialInit))
    );
    this.filteredASTM = this.addFormGroup.get('astm').valueChanges.pipe(
      startWith(''),
      map(value => this._filterOptions(value, this.filteredASTMInit))
    );
    this.filteredCONST = this.addFormGroup.get('const').valueChanges.pipe(
      startWith(''),
      map(value => this._filterOptions(value, this.filteredCONSTInit))
    );
    this.filteredSTD = this.addFormGroup.get('design').valueChanges.pipe(
      startWith(''),
      map(value => this._filterOptions(value, this.filteredSTDInit))
    );
    this.filteredRequirements = this.addFormGroup.get('requirements').valueChanges.pipe(
      startWith(''),
      map(value => this._filterOptions(value, this.filteredRequirementsInit))
    );
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (!this.addFormGroup.valid) {
      this.addFormGroup.markAllAsTouched();
      this._snackBarService.showSnackBar(true, 'Please correct errors in selected fields.');
      return;
    }

    this.dialogRef.close(this.componentModel);
  }

  autocompleteStringValidator(validOptions: Array<string>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (validOptions.indexOf(control.value) !== -1) {
        return null;  /* valid option selected */
      }
      return { 'invalidAutocompleteString': { value: control.value } }
    }
  }

  private _filterOptions(label: string, collection: Array<string>): string[] {
    if (label === '') {
      return collection.slice()
    }
    const filterValue = label.toLowerCase()
    return collection.filter(option => option.toLowerCase().includes(filterValue))
  }
}
