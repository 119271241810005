import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoatingSolutionDetailComponent } from '../../dialogs/Coating-Solution-Detail/coating-solution-detail.component';
import { CoatingSystemSolutionViewModel } from '../../models/autogenerated-coating';
import { CoatOrderService } from '../../services/coat-order.service';
import { TechnologyEnum } from '../../models/technology-enum';
import { ProductPanelHelper } from '../product-panel/product-panel-helper';

@Component({
  selector: 'app-coating-solution-panel',
  templateUrl: './coating-solution-panel.component.html',
  styleUrls: ['./coating-solution-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CoatingSolutionPanelComponent implements OnInit {
  constructor(
    private coatOrderService: CoatOrderService,
    private dialog: MatDialog,
    private productPanelHelper: ProductPanelHelper
  ) {}
  @Input()
  solution: CoatingSystemSolutionViewModel;

  @Input()
  addWorkspaceShow: boolean;

  coatsAndProducts = [];

  technology = TechnologyEnum.COATINGS;

  ngOnInit(): void {
    this.organizeProductsByCoat();

    this.solution.manufacturer =
      this.productPanelHelper.strReplaceHighlightHtml(
        this.solution.manufacturer
      );

    this.solution.coatingSystem.coatingSystemId =
      this.productPanelHelper.strReplaceHighlightHtml(
        this.solution.coatingSystem.coatingSystemId
      );

    this.solution.coatingSystem.name =
      this.productPanelHelper.strReplaceHighlightHtml(
        this.solution.coatingSystem.name
      );

    this.solution.coatingSystem.coatingSystemCategory =
      this.productPanelHelper.strReplaceHighlightHtml(
        this.solution.coatingSystem.coatingSystemCategory
      );

    this.solution.coatingSystem.coatingSystemType =
      this.productPanelHelper.strReplaceHighlightHtml(
        this.solution.coatingSystem.coatingSystemType
      );

    this.solution.coatingSystem.vocCategory =
      this.productPanelHelper.strReplaceHighlightHtml(
        this.solution.coatingSystem.vocCategory
      );
  }

  organizeProductsByCoat() {
    const coats = new Set();
    this.solution.coatingSolutionProducts.forEach((prod) => {
      coats.add(prod.coatName);
      prod.productName = this.productPanelHelper.strReplaceHighlightHtml(
        prod.productName
      );
    });

    coats.forEach((coat) => {
      const prodNames = this.solution.coatingSolutionProducts
        .filter((p) => p.coatName == coat)
        .map((x) => x.productName);
      this.coatsAndProducts.push({
        key: coat,
        value: prodNames,
      });
    });

    this.coatsAndProducts.sort((a, b) =>
    {
      const coatA = this.coatOrderService.coatOrders.find((x) => x.coat === a.key);
      const coatB = this.coatOrderService.coatOrders.find((x) => x.coat === b.key);
      if (coatA && coatB) { //if we find both coat types, order them accordingly
        return coatA.order - coatB.order
      } else if (coatA) { //if we only find one coat type, the listed order goes first
        return -1
      } else if (coatB) { 
        return 1
      } else { //if we can't match any coat types to our list, do nothing
        return 0
      }
    }
    );
  }

  OpenDetail() {
    const dialogRef = this.dialog.open(CoatingSolutionDetailComponent, {
      width: '75%',
      height: '80%',
      disableClose: false,
      data: {
        title: 'Coating Solution Detail',
        body: this.solution.solutionName,
        addWorkspaceShow: this.addWorkspaceShow,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      return;
    });
  }
}
