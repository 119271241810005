// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  padding: 3%;
}

.comment-container {
  display: flex;
  flex-direction: column;
}

.buttons-container {
  display: flex;
  margin-top: 2%;
  justify-content: flex-end;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
