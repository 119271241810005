import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  Subscription,
  combineLatest,
  ReplaySubject,
  Observable,
} from 'rxjs';
import { tap, first, map, switchMap } from 'rxjs/operators';
import { faAngleLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import {
  CoatingSystemSolutionViewModel,
  EsraCoatingsAPIClient,
  CoatingRevisionViewModel,
  CoatingProductViewModel,
} from 'src/app/shared/models/autogenerated-coating';
import { indicate } from 'src/app/operators';

@Component({
  selector: 'app-update-coating-solution',
  templateUrl: './update-coating-solution.component.html',
  styleUrls: ['./update-coating-solution.component.scss'],
})
export class UpdateCoatingSolutionComponent implements OnInit {
  constructor(
    private location: Location,
    private esraCoatingClient: EsraCoatingsAPIClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
  ) {}

  loadingCoatingSolution$ = new BehaviorSubject<boolean>(false);
  loadingProductByManufacturer$ = new BehaviorSubject<boolean>(false);
  coatSolustionHeaderDataSource =
    new MatTableDataSource<UpdateCoatingSystemSolutionViewModel>();
  coatSolustionDataSource = new MatTableDataSource<ProductViewModel>();
  private readonly subscription = new Subscription();

  coatSolustionHeaderColumns: string[] = [
    'header',
    'publishedInformation',
    'draftUpdate',
  ];

  solutionProductsTableColumns: string[] = [
    'coatName',
    'coatProductName',
    'buttons',
  ];

  coatSolutionColumns: string[] = ['coatName', 'coatProductName', 'buttons'];

  faAngleLeft = faAngleLeft;
  faEdit = faPen;

  draftCoatingSolution: CoatingSystemSolutionViewModel;
  draftCoatingSolution$: Observable<CoatingSystemSolutionViewModel> =
    this.getDraftCoatingSolution();

  publishedCoatingSolution$: Observable<CoatingSystemSolutionViewModel> =
    this.getPublishedCoatingSolution();

  selectedManufacturer$ = new ReplaySubject<string>(1);

  coatingProductsByManufacturer$: Observable<CoatingProductViewModel[]> =
    this.getManufacturerProducts();

  coatingProductsByManufacturer: CoatingProductViewModel[];

  coatingProductRowInEdit: number;

  coatingSolutionId = '';
  solutionName = '';
  commentText = '';
  manufacturerSelected = '';
  productNameSelected = '';
  coatingSoluationId = '';
  isInEditMode = false;
  manufacturers: string[] = [];
  coatingProductsBymanufacturer: Product[] = [];
  coatSolutionProductsLenght: number;
  coatSolutionProducts: ProductViewModel[];
  coatingSolutionViewModel: CoatingSystemSolutionViewModel;
  
  loadingRevision$ = new BehaviorSubject<boolean>(false);
  revisionId: number;
  revision$ = new ReplaySubject<CoatingRevisionViewModel>(1);
  currentRevision: CoatingRevisionViewModel;
  
  ngOnInit(): void {
    this.activeRoute.params
      .pipe(
        tap((params) => {
          this.coatingSolutionId = params.id;
          this.revisionId = params.revisionid;
        })
      )
      .subscribe();

    this.getCoatingSolution();
    this.getManufacturers();
    this.getRevision();
  }

  getDraftCoatingSolution(): Observable<CoatingSystemSolutionViewModel> {
    return this.activeRoute.params.pipe(
      map((params) => params.id),
      switchMap((solutionId) =>
        this.esraCoatingClient.getCoatingSystemSolutionById(solutionId)
      ),
      tap((solution) => {
        this.draftCoatingSolution = solution;
        this.selectedManufacturer$.next(solution.manufacturer);
      })
    );
  }

  getPublishedCoatingSolution(): Observable<CoatingSystemSolutionViewModel> {
    return combineLatest([this.draftCoatingSolution$]).pipe(
      switchMap(([draftCoatingSolution]) =>
        this.esraCoatingClient.getCoatingSystemSolutionById(
          draftCoatingSolution.prevRevisionSolutionId
        )
      )
    );
  }

  editCoatProduct(row: number): void {
    this.coatingProductRowInEdit = row;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getRevision() {
    this.esraCoatingClient.getCoatingRevisionById(this.revisionId)
    .pipe(
      indicate(this.loadingRevision$),
      first(),
      tap((revision) => {
        this.revision$.next(revision);
        this.currentRevision = revision;
      }))
    .subscribe();
  }

  getCoatingSolution() {
    this.esraCoatingClient
      .getCoatingSystemSolutionById(this.coatingSolutionId)
      .pipe(
        indicate(this.loadingCoatingSolution$),
        tap((solution) => {
          const revisonModels = new Array<UpdateCoatingSystemSolutionViewModel>();
          this.coatingSolutionViewModel =
            this.createACoatinSolutionCopy(solution);
          this.solutionName = solution.solutionName;
          this.revisionId = solution.fromRevision;
          this.coatingSoluationId = solution.solutionId;

          const firstLine = new UpdateCoatingSystemSolutionViewModel();
          firstLine.titleName = 'Coating Solution Name';
          firstLine.publishedOption = solution.solutionName;
          firstLine.draftUpdate = solution.solutionName;
          revisonModels.push(firstLine);

          const secondLine = new UpdateCoatingSystemSolutionViewModel();
          secondLine.titleName = 'Coating System ID';
          secondLine.publishedOption = solution.coatingSystem.coatingSystemId;
          secondLine.draftUpdate = solution.coatingSystem.coatingSystemId;
          revisonModels.push(secondLine);

          const thirdLine = new UpdateCoatingSystemSolutionViewModel();
          thirdLine.titleName = 'Manufacturer';
          thirdLine.publishedOption = solution.manufacturer;
          thirdLine.draftUpdate = solution.manufacturer;
          this.manufacturerSelected = solution.manufacturer;
          revisonModels.push(thirdLine);

          const fourthLine = new UpdateCoatingSystemSolutionViewModel();
          fourthLine.titleName = 'Solution Notes';
          fourthLine.publishedOption = solution.notes;
          fourthLine.draftUpdate = solution.notes;
          revisonModels.push(fourthLine);

          this.coatSolutionProducts = [];
          const coatName = solution.coats[0].coatModel.coatName;
          const coatId = solution.coats[0].coatModel.id;
          solution.coats[0].products.forEach((p) => {
            const product = new ProductViewModel();
            product.coatModelName = coatName;
            product.coatModelId = coatId;
            product.coatingProductId = p.coatingProductId;
            product.isEditable = false;
            product.name = p.name;
            this.coatSolutionProducts.push(product);
          });
          this.coatSolutionProductsLenght = this.coatSolutionProducts.length;
          this.coatSolustionDataSource = new MatTableDataSource(
            this.coatSolutionProducts
          );
          this.coatSolustionHeaderDataSource = new MatTableDataSource(
            revisonModels
          );
        })
      )
      .subscribe();
  }

  createACoatinSolutionCopy(
    r: CoatingSystemSolutionViewModel
  ): CoatingSystemSolutionViewModel {
    return JSON.parse(JSON.stringify(r));
  }

  getManufacturers() {
    this.esraCoatingClient
      .getAllManufacturers()
      .pipe(
        indicate(this.loadingCoatingSolution$),
        first(),
        tap((manufacturers) => (this.manufacturers = manufacturers))
      )
      .subscribe();
  }

  getManufacturerProducts(): Observable<CoatingProductViewModel[]> {
    return combineLatest([this.selectedManufacturer$]).pipe(
      switchMap(([selectedManufacturer]) =>
        this.esraCoatingClient.getCoatingProductsByManufacturerName(
          selectedManufacturer
        )
      ),
      tap((products) => (this.coatingProductsByManufacturer = products))
    );
  }

  manufacturerChanged(manufacturer: string) {
    this.selectedManufacturer$.next(manufacturer);

    this.draftCoatingSolution.manufacturer = manufacturer;
    this.draftCoatingSolution.solutionName =
      this.draftCoatingSolution.coatingSystem.coatingSystemId +
      ' | ' +
      manufacturer;
  }

  saveEditedRow(row: number) {
    const updating = this.draftCoatingSolution.coatingSolutionProducts[row];

    const productMatch = this.coatingProductsByManufacturer.filter(
      (x) => x.name === updating.productName
    )[0];

    this.draftCoatingSolution.coatingSolutionProducts[row].coatingProductId =
      productMatch.coatingProductId;

    this.coatingProductRowInEdit = null;
  }

  save() {
    this.esraCoatingClient
      .updateCoatingSystemSolution(this.draftCoatingSolution)
      .subscribe({
        next: (result) => this.goBack(),
        error: (error) => {
          alert(error);
        }
    });
  }

  goBack() {
    this.location.back();  
  }

  cancel() {
    this.goBack();
  }

  areProductBeingArchived(): boolean{
    const coatingSystemGuard = this.currentRevision.coatingSystems;
    if(coatingSystemGuard !== undefined) {
      for(const solution of this.currentRevision.coatingSystems){
        if(solution.id === this.draftCoatingSolution.coatingSystem.id){
          return solution.toBeDeleted;
        }
      }
    }
    return false;
  }
}

export class Product {
  value: string;
  viewValue: string;
}

export class UpdateCoatingSystemSolutionViewModel {
  titleName = '';
  publishedOption = '';
  draftUpdate = '';
}

export class ProductViewModel {
  isEditable: boolean;
  coatModelName: string;
  coatModelId: string;
  name: string;
  coatingProductId: string;
}
