import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { ProductPanelHelper } from '../product-panel/product-panel-helper';
import { ValveViewModel } from '../../models/autogenerated-piping';
import { PipingAndValvesNavigationService } from 'src/app/piping-valves/services/piping-and-valves-navigation.service';
import { PipingAndValvesDocumentService } from 'src/app/piping-valves/services/piping-and-valves-document.service';

@Component({
  selector: 'app-valve-panel',
  templateUrl: './valve-panel.component.html',
  styleUrls: ['./valve-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ValvePanelComponent implements OnInit {
  @Input()
  valve: ValveViewModel;
  @Input()
  addWorkspaceShow: boolean;
  @Input()
  isAdminSearch: boolean;

  @Output() openPage = new EventEmitter<string>();

  constructor(
    private productPanelHelper: ProductPanelHelper,
    private pipingAndValvesNavigationService: PipingAndValvesNavigationService,
    private pipingAndValvesDocumentService: PipingAndValvesDocumentService
  ) {}

  ngOnInit() {
    this.addWorkspaceShow = true;

    this.valve.tag = this.productPanelHelper.strReplaceHighlightHtml(
      this.valve.tag
    );
    this.valve.businessUnits = this.productPanelHelper.arrReplaceHighlightHtml(
      this.valve.businessUnits
    );

    this.valve.application = this.productPanelHelper.strReplaceHighlightHtml(
      this.valve.application
    );
    this.valve.componentTypeString =
      this.productPanelHelper.strReplaceHighlightHtml(
        this.valve.componentTypeString
      );

    this.valve.rating = this.productPanelHelper.strReplaceHighlightHtml(
      this.valve.rating
    );
    this.valve.ends = this.productPanelHelper.strReplaceHighlightHtml(
      this.valve.ends
    );
    this.valve.body = this.productPanelHelper.strReplaceHighlightHtml(
      this.valve.body
    );

    this.valve.trim = this.productPanelHelper.strReplaceHighlightHtml(
      this.valve.trim
    );
    this.valve.pipeClassNames = this.highlightStringList(
      this.valve.pipeClassNames
    );

    if (this.valve.fromRevision) {
      this.valve.fromRevision.version =
        this.productPanelHelper.strReplaceHighlightHtml(
          this.valve.fromRevision.version
        );
    }
  }

  highlightStringList(stringList: string[]): string[] {
    const returnStringArray: Array<string> = [];
    for (const string in stringList) {
      returnStringArray.push(
        this.productPanelHelper.strReplaceHighlightHtml(string)
      );
    }
    return returnStringArray;
  }

  public stripEmphasisClassFromString(title: string): string {
    const openTagString = '<em class="highlighted">';
    const closeTagString = '</em>';
    if (title) {
      title = title.replace(new RegExp(openTagString, 'g'), '');
      title = title.replace(new RegExp(closeTagString, 'g'), '');
    }
    return title;
  }

  stripTrailingCharactersFromValveTag(tag: string): string {
    if (tag) {
      const delimiter = '_';
      if (tag.includes(delimiter)) {
        const substring = tag.split(delimiter);
        return substring[0];
      }
    }
    return tag;
  }

  getValueOrDash(value) {
    if (value === undefined || value === null) {
      value = '-';
    }

    return value;
  }

  // Erase this method and switch back to (click)="this.openPage.emit(this.pipeClass.id)"
  // when the PDF backup solution is no longer implemented
  tryOpenValveSummaryPage() {
    if (this.isAdminSearch) {
      this.pipingAndValvesNavigationService.openValveSummaryPage(this.valve.id);
    } else {
      this.pipingAndValvesDocumentService.openPDF(
        this.valve.tag,
        this.valve.businessUnits[0]
      );
    }
  }
}
