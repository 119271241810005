export class Constants {
  static esraDelimiter = '_%$%_';

  // app roles
  static readonly admin: string = 'Admin';
  static readonly user: string = 'User';
  static readonly scanner: string = 'Scanner';
  static readonly coatingSpecificationExport: string =
    'Coatings.Specification.Export';
  static readonly coatingRevisionWriter: string = 'Coatings.Revision.Write';
  static readonly weldingRevisionWriter: string = 'Welding.Revision.Write';
  static readonly pipingRevisionWriter: string = 'Piping.Revision.Write';
  static readonly pipingRevisionEditor: string = 'Piping.Revision.Editor';
  static readonly pipingRevisionReviewer: string = 'Piping.Revision.Reviewer';

  static readonly allRevisionsURL: string = 'allrevisions';
  static readonly coatingRevisions: string = 'coatingsrevision';
  static readonly coatingSystemURL: string = 'coatingsystem';
  static readonly coatingProductURL: string = 'coatingproduct';
  static readonly updateCoatingSolutionURL: string = 'updatecoatingsolution';

  static readonly metricPipeClassPressureUnits = 'kPag';
  static readonly imperialPipeClassPressureUnits = 'psig';
  static readonly metricPipeClassTemperatureUnits = 'C';
  static readonly imperialPipeClassTemperatureUnits = 'F';
}
