import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import {
  RevisionStatus,
} from 'src/app/shared/models/autogenerated-coating';

@Component({
  selector: 'app-workflow-status-bar',
  templateUrl: './workflow-status-bar.component.html',
  styleUrls: ['./workflow-status-bar.component.scss'],
})
export class WorkflowStatusBarComponent implements OnChanges, AfterViewInit {
  

  @Input()
  currentRevisionStatus: RevisionStatus;

  @Input()
  isStatus_0Visible = true;
  @Input()
  isStatus_1Visible = true;
  @Input()
  isStatus_2Visible = true;
  @Input()
  isStatus_3Visible = true;

  navigationItems: WorflowItem[] = [
    {
      text: 'draft',
      status: RevisionStatus._0,
      isActive: true,
      isVisible: this.isStatus_0Visible,
    },
    {
      text: 'in review',
      status: RevisionStatus._1,
      isActive: false,
      isVisible: this.isStatus_1Visible,
    },
    {
      text: 'approval',
      status: RevisionStatus._2,
      isActive: false,
      isVisible: this.isStatus_2Visible,
    },
    {
      text: 'published',
      status: RevisionStatus._3,
      isActive: false,
      isVisible: this.isStatus_3Visible,
    },
  ];

  ngAfterViewInit(): void {
    this.navigationItems[0].isVisible = this.isStatus_0Visible;
    this.navigationItems[1].isVisible = this.isStatus_1Visible;
    this.navigationItems[2].isVisible = this.isStatus_2Visible;
    this.navigationItems[3].isVisible = this.isStatus_3Visible;
  }

  ngOnChanges() {
    for (let item of this.navigationItems) {
      if (this.currentRevisionStatus === item.status) {
        if(this.currentRevisionStatus === RevisionStatus._3) {
          item.isActive = false;
          item.finished = true;
        } else {
          item.isActive = true;
          break;
        }
      } else {
        item.isActive = false;
        item.finished = true;
      }
    }
  }

  setSingleStatus(
    status: RevisionStatus,
    failed: boolean,
    active?: boolean,
    finished?: boolean
  ) {
    let item = this.navigationItems.find((item) => item.status === status);
    if (item) {
      item.finished = finished ?? item.finished;
      item.isActive = active ?? item.isActive;
      item.isFailed = failed ?? undefined;
    }
  }

  getIcon(workflowItem: WorflowItem): string {
    if (workflowItem.isActive) {
      return 'radio_button_checked';
    } else if(workflowItem.isFailed) {
      return 'cancel';
    } else if(workflowItem.finished) {
      return 'check_circle';
    } else {
      return 'radio_button_unchecked'
    }
  }
}

export class WorflowItem {
  text: string;
  status: RevisionStatus;
  finished? = false;
  isFailed? = false;
  isActive? = false;
  isVisible = true;
}
