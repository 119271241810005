import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
} from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CelsiusToFahrenheitPipe } from 'src/app/shared/pipes/celcius-to-fahrenheit.pipe';
import { FahrenheitToCelsiusPipe } from 'src/app/shared/pipes/fahrenheit-to-celcius.pipe';
import { GramPerLiterToPoundPerGallonPipe } from 'src/app/shared/pipes/gramperliter-to-poundpergallon.pipe';
import { PoundPerGallonToGramPerLiterPipe } from 'src/app/shared/pipes/poundpergallon-to-gramperliter.pipe';

@Component({
  selector: 'app-range-filter',
  templateUrl: './range-filter.component.html',
  styleUrls: ['./range-filter.component.scss'],
})
export class RangeFilterComponent implements OnInit, OnChanges {
  public panelOpenState: boolean = false;

  @Input()
  title: string;

  @Input()
  type: string;

  @Input()
  typeDisplayName: string;

  @Input()
  floor: number;

  @Input()
  ceiling: number;

  @Input()
  rangeFilterCategory: RangeFilterCategory | undefined;

  displayToggle: boolean = true;
  userInputValue: number;
  minValue: number = 0;
  maxValue: number;

  fromLabel: string = '';
  toLabel: string = '';

  isToggleSelected: boolean = false;

  options: Options;
  private fahrenheitToCelsius = new FahrenheitToCelsiusPipe();
  private poundPerGallonToGramPerLiterPipe = new PoundPerGallonToGramPerLiterPipe();

  @Output()
  singleValueOutput = new EventEmitter<number>();

  @Output()
  minMaxValueOutput = new EventEmitter<number[]>();

  ngOnInit(): void {
    this.setDefaultValue();
    this.FromToLabels();
    this.displayToggle = this.rangeFilterCategory != undefined;
  }

  ngOnChanges(): void {
    if (this.rangeFilterCategory == RangeFilterCategory.TEMPERATURE) {
      let celsiusToFahrenheit = new CelsiusToFahrenheitPipe();
      this.minValue = Number(celsiusToFahrenheit.transform(this.floor));
      this.maxValue = Number(celsiusToFahrenheit.transform(this.ceiling));
      this.options = {
        floor: this.minValue,
        ceil: this.maxValue,
      };
    } else {
      this.maxValue = this.ceiling;
      this.minValue = this.floor;
      this.options = {
        floor: this.floor,
        ceil: this.ceiling,
      };
    }
  }

  private FromToLabels() {
    if (this.rangeFilterCategory == RangeFilterCategory.TEMPERATURE) {
      this.fromLabel = '°C';
      this.toLabel = '°F';
    } else if (this.rangeFilterCategory == RangeFilterCategory.DENSITY) {
      this.fromLabel = 'g/L';
      this.toLabel = 'lb/gal';
    }
  }

  private setDefaultValue(): void {
    if (this.rangeFilterCategory == RangeFilterCategory.TEMPERATURE) {
      let celsiusToFahrenheit = new CelsiusToFahrenheitPipe();
      this.isToggleSelected = true;
      this.minValue = Number(celsiusToFahrenheit.transform(this.floor));
      this.maxValue = Number(celsiusToFahrenheit.transform(this.ceiling));
      this.options = {
        floor: this.minValue,
        ceil: this.maxValue,
      };
      this.userInputValue = this.minValue;
    } else {
      this.maxValue = this.ceiling;
      this.minValue = this.floor;
      this.options = {
        floor: this.floor,
        ceil: this.ceiling,
      };

      if (this.type == 'MAXIMUM') {
        this.userInputValue = this.maxValue;
      } else {
        this.userInputValue = this.minValue;
      }
    }
  }

  tryToSetSlider(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      this.userInputValue = null;
    } else {
      if (
        this.userInputValue > this.floor &&
        this.userInputValue < this.ceiling
      ) {
        this.minValue = this.userInputValue;
      }
    }
  }

  setValueWitihnApplicableRange() {
    if (this.userInputValue < this.floor) {
      this.userInputValue = this.floor;
      this.minValue = this.floor;
    } else if (this.userInputValue > this.ceiling) {
      this.userInputValue = this.ceiling;
      this.minValue = this.ceiling;
    } else {
      this.minValue = this.userInputValue;
    }
    this.singleValueOutput.emit(this.minValue);
  }

  userChange() {
    if(this.rangeFilterCategory == RangeFilterCategory.TEMPERATURE) {
      this.convertAndPublishTemperature();
    } else if(this.rangeFilterCategory == RangeFilterCategory.DENSITY) {
      this.convertAndPublishDensity();
    }
  }

  private convertAndPublishTemperature() {
    if(this.type === 'RANGE') {
      if(this.isToggleSelected) {
        this.minMaxValueOutput.emit([
          Number(this.fahrenheitToCelsius.transform(this.minValue)),
          Number(this.fahrenheitToCelsius.transform(this.maxValue)),
        ]);
      } else {
        this.minMaxValueOutput.emit([this.minValue, this.maxValue]);
      }
    } else {
      if(this.isToggleSelected) {
        this.singleValueOutput.emit(Number(this.fahrenheitToCelsius.transform(this.userInputValue)));
      } else {
        this.singleValueOutput.emit(this.userInputValue);
      }
    }
  }

  private convertAndPublishDensity() {
    if(this.type === 'RANGE') {
      if(this.isToggleSelected) {
        this.minMaxValueOutput.emit([
          Number(this.poundPerGallonToGramPerLiterPipe.transform(this.minValue)),
          Number(this.poundPerGallonToGramPerLiterPipe.transform(this.maxValue)),
        ]);
      } else {
        this.minMaxValueOutput.emit([this.minValue, this.maxValue]);
      }
    } else {
      if(this.isToggleSelected) {
        this.singleValueOutput.emit(Number(this.poundPerGallonToGramPerLiterPipe.transform(this.userInputValue)));
      } else {
        this.singleValueOutput.emit(this.userInputValue);
      }
    }
  }

  toggle(event: MatSlideToggleChange) {
    const celsiusToFahrenheit = new CelsiusToFahrenheitPipe();
    const fahrenheitToCelsius = new FahrenheitToCelsiusPipe();
    const gramPerLiterToPoundPerGallonPipe = new GramPerLiterToPoundPerGallonPipe();
    const poundPerGallonToGramPerLiter = new PoundPerGallonToGramPerLiterPipe();

    if (event.checked) {
      this.isToggleSelected = true;
      if (this.rangeFilterCategory == RangeFilterCategory.TEMPERATURE) {
        this.minValue = Number(celsiusToFahrenheit.transform(this.floor));
        this.maxValue = Number(celsiusToFahrenheit.transform(this.ceiling));

        this.options = {
          floor: this.minValue,
          ceil: this.maxValue,
        };

        this.userInputValue = Number(
          celsiusToFahrenheit.transform(this.userInputValue)
        );
        this.userChange();
      }
      if (this.rangeFilterCategory == RangeFilterCategory.DENSITY) {
        this.minValue = Number(
          gramPerLiterToPoundPerGallonPipe.transform(this.floor)
        );
        this.maxValue = Number(
          gramPerLiterToPoundPerGallonPipe.transform(this.ceiling)
        );

        this.options = {
          floor: this.minValue,
          ceil: this.maxValue,
        };

        this.userInputValue = Number(
          gramPerLiterToPoundPerGallonPipe.transform(this.userInputValue)
        );
      }
    } else {
      this.isToggleSelected = false;
      this.options = { floor: this.floor, ceil: this.ceiling };
      this.minValue = this.floor;
      this.maxValue = this.ceiling;

      if (this.rangeFilterCategory == RangeFilterCategory.TEMPERATURE) {
        this.userInputValue = Number(
          fahrenheitToCelsius.transform(this.userInputValue)
        );
      }

      if (this.rangeFilterCategory == RangeFilterCategory.DENSITY) {
        this.userInputValue = Number(
          poundPerGallonToGramPerLiter.transform(this.userInputValue)
        );
      }

      this.userChange();
    }
  }
}

export enum RangeFilterCategory {
  TEMPERATURE,
  DENSITY,
  WIDTH,
}
