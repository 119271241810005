// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  display: flex;
  flex-direction: column;
}

.data-container {
  margin-top: 4%;
}

.header-text {
  color: #0b2d71;
  font-weight: bolder;
  margin-bottom: 0;
}

hr {
  color: #0b2d71;
  border-top: 0.1em solid;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.empty-cell {
  background-color: white;
  border: none;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}

.empty-cell-bottom-left {
  background-color: white;
  border-bottom: solid 2px #8c8f93;
  border-left: solid 2px #8c8f93;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}

.empty-cell-top-left {
  background-color: white;
  border-left: solid 2px #8c8f93;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}

.empty-cell-right {
  background-color: white;
  border-bottom: solid 2px #8c8f93;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}

.header-branch-size-cell {
  color: #0b2d71;
  font-size: larger;
  font-weight: 900;
  background-color: white;
  border: solid 3px #8c8f93;
  text-align: center;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}

.header-branch-size-cell-highlight {
  color: white;
  font-size: larger;
  font-weight: 900;
  background-color: #0b2d71;
  border: solid 3px #8c8f93;
  text-align: center;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}

.data-cell {
  color: #0b2d71;
  background-color: #edeaea;
  border: solid 3px #8c8f93;
  text-align: center;
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}

.header-size-label {
  background-color: white;
  text-align: center;
  border-right: solid 2px #8c8f93;
  border-bottom: solid 2px #8c8f93;
  border-left: solid 2px #8c8f93;
}

.branch-size-label {
  padding: auto;
  background-color: white;
  text-align: center;
  border: solid 2px #8c8f93;
}

.rotate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rotate-container .rotate-letter {
  display: flex;
  margin: 0;
}
.rotate-container .rotate-letter p {
  transform: rotate(90deg);
  margin: 0;
}

.data-cell-hover {
  color: white;
  background-color: #0b2d71;
}

.table {
  table-layout: fixed;
  min-width: 55px;
  max-width: 55px;
}
.table td {
  min-width: 55px;
  max-width: 55px;
  white-space: nowrap;
}
.table tbody {
  display: flex;
  overflow-x: auto;
  scroll-padding: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
