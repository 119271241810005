import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'splitByCase',
  })
  export class SplitByCasePipe implements PipeTransform {
    transform(textToSplit: string): string {
        let result = textToSplit.replace( /([A-Z])/g, " $1" );
        let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }
  }