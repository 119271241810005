// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outline-panel {
  border: 1px solid #ededee;
}

.text-filter-title {
  text-align: left;
  font: normal normal medium "Gotham", Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0066b2;
  opacity: 1;
}

.comparison-label {
  font-size: small;
}

mat-form-field {
  width: 100%;
}

input {
  width: 100%;
}

.mat-expansion-panel-header {
  justify-content: space-between;
}

.warning {
  display: flex;
  color: #e21836;
}
.warning .warning-text {
  margin: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
