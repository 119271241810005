import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import { first, map, switchMap, tap, finalize } from 'rxjs/operators';
import { GraphService } from 'src/app/core/services/graph.service';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { UserService } from 'src/app/core/services/user.service';
import {
  DocumentStatus,
  EsraWeldingAPIClient,
  RevisionApprovalStatus,
  RevisionApprovalViewModel,
  RevisionStatus,
  WeldingRevisionViewModel,
} from 'src/app/shared/models/autogenerated-welding';
import { ApprovalStatusPipe } from '../../pipes/approval-status.pipe';
import { Counter, indicate } from 'src/app/operators';
import { UpdateWeldingProcedureComponent } from '../../components/update-welding-procedure/update-welding-procedure.component';
import { EditWeldingApproverComponent } from '../../components/edit-welding-approver/edit-welding-approver.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-welding-revision-summary',
  templateUrl: './welding-revision-summary.component.html',
  styleUrls: ['./welding-revision-summary.component.scss'],
})
export class WeldingRevisionSummaryComponent implements OnInit, OnDestroy {
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private esraWeldingApiClient: EsraWeldingAPIClient,
    private graphService: GraphService,
    private pdfPreviewDialog: MatDialog,
    private userService: UserService,
    private router: Router,
    private snackBarService: SnackBarService
  ) { }

  counter = new Counter();

  private readonly subscription = new Subscription();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  sendToApproverSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  approvingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  deleteSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  documentStatus: DocumentStatus;
  weldingRevision$: Observable<WeldingRevisionViewModel> =
    this.getWeldingRevision();

  updatedRevision: WeldingRevisionViewModel;

  weldingRevisionId: number;

  authorName$: Observable<string> = this.getAuthorName();
  approverName$: Observable<string> = this.getApproverName();

  approvalCols = ['name', 'status'];
  approvalsTableDataSource: Array<string[]>;

  approvers: RevisionApprovalViewModel[] = [];

  editApproversList = [];

  editDescription = false;

  refreshRevisionData = () => {
    this.weldingRevision$ = this.esraWeldingApiClient.getRevisionById(
      this.updatedRevision.id
    );
    this.getApprovals();
  }

  ngOnInit(): void {
    this.getApprovals();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  canUserEdit(): boolean {
    return this.userService.getCurrentUser().localAccountId ===
      this.updatedRevision.authorOid && this.updatedRevision.status === 0;
  }

  getWeldingRevision(): Observable<WeldingRevisionViewModel> {
    const prepareMethod = () => {
      this.counter.increase();
      this.loading$.next(true);
    }

    const finalizeMethod = () => {
      this.counter.decrease();
      if (this.counter.getValue() <= 0) {
        this.loading$.next(false);
        this.counter = new Counter();
      }
    }

    return this.route.params.pipe(
      first(),
      tap(prepareMethod),
      switchMap((params) =>
        this.esraWeldingApiClient.getRevisionById(params.id)
      ),
      tap((revision) => {
        this.updatedRevision = revision;
        this.weldingRevisionId = revision.id;
        if (revision.status !== RevisionStatus._3) {
          this.documentStatus = DocumentStatus._0;
        } else {
          this.documentStatus = revision.itemsToArchive?.length
            ? DocumentStatus._2
            : DocumentStatus._1;
        }
      }),
      finalize(finalizeMethod)
    );
  }

  getUserDisplayName(userOid: string): Observable<string> {
    return this.graphService
      .getUserByOid(userOid)
      .pipe(map((user) => user.givenName + ' ' + user.surname));
  }

  getAuthorName(): Observable<string> {
    return combineLatest([this.weldingRevision$]).pipe(
      switchMap(([revision]) => this.getUserDisplayName(revision.authorOid))
    );
  }

  getApproverName(): Observable<string> {
    return combineLatest([this.weldingRevision$]).pipe(
      switchMap(([revision]) =>
        this.getUserDisplayName(revision.approvals[0].approverOid)
      )
    );
  }

  private getApproversFromGraph(): Observable<MicrosoftGraph.User[]> {
    return combineLatest([this.weldingRevision$]).pipe(
      switchMap(([revision]) => of(revision.approvals)),
      switchMap((approvals) => this.graphService.getUsersByOids(approvals.map((a) => a.approverOid)))
    );
  }

  private getApprovals() {
    return combineLatest([this.weldingRevision$, this.getApproversFromGraph()])
      .pipe(
        first(),
        map(([revision, users]) => {
          this.approvers = revision.approvals;

          const approvers: Array<string[]> = [];
          this.editApproversList = [];

          revision.approvals.forEach((approval) => {
            const match = users.filter((u) => u.id === approval.approverOid);
            if (match[0] !== null) {
              const nextApprover: string[] = [];
              nextApprover.push(`${match[0].displayName}`);
              const approvalPipe = new ApprovalStatusPipe();
              nextApprover.push(approvalPipe.transform(approval.status));
              approvers.push(nextApprover);
              this.editApproversList.push({ "id": approval.id, "status": approvalPipe.transform(approval.status), "approverOid": approval.approverOid, "displayName": `${match[0].displayName}` });
            }
          });
          this.approvalsTableDataSource = approvers;
        })
      )
      .subscribe();
  }

  isUserApprover(): boolean {
    const match = this.approvers.filter(
      (x) => x.approverOid === this.userService.getCurrentUser().localAccountId
    );

    return match.length > 0 ? true : false;
  }

  OpenDetail(fileName: string) {
    this.esraWeldingApiClient
      .getWeldingDocument(fileName, this.documentStatus)
      .subscribe({
        next: (response) => {
          window.open(URL.createObjectURL(response.data), '_blank');
        },
        error: (err) => {
          console.log("error: " + err);
          this.snackBarService.showError("PDF Document Not Found");
        }
      })
  }

  deleteRevision(): void {
    const confirmation = confirm(
      'Are you sure you want to delete this revision?'
    );

    if (confirmation) {
      this.subscription.add(
        this.route.params
          .pipe(
            indicate(this.deleteSubject$),
            switchMap((params) =>
              this.esraWeldingApiClient.deleteRevision(params.id)
            )
          )
          .subscribe({
            next: (response) => {
              this.router.navigate(['/weldingrevision/all']);
            },
            error: (err) => {
              console.log(err);
            }
          }
          )
      );
    }
  }

  editRevision(): void {
    this.subscription.add(
      this.weldingRevision$.subscribe((revision) => {
        const dialogRef = this.pdfPreviewDialog.open(
          UpdateWeldingProcedureComponent,
          {
            width: '85%',
            height: '85%',
            disableClose: true,
          }
        );

        const updateWeldingProcedureComponent = dialogRef.componentInstance;
        updateWeldingProcedureComponent.weldingProcedure = revision.weldingProcedures[0];
        updateWeldingProcedureComponent.cancelButtonVisible = true;

        dialogRef.afterClosed().subscribe((result) => {
          this.weldingRevision$ = this.esraWeldingApiClient.getRevisionById(
            this.updatedRevision.id
          );
        });
      })
    );
  }

  approveRevision(): void {
    this.esraWeldingApiClient
      .approveRevision(
        this.updatedRevision.id,
        this.userService.getCurrentUser().localAccountId
      )
      .pipe(
        indicate(this.approvingSubject$),
        first(),
        tap((response) => {
          this.weldingRevision$ = of(response);
          this.getApprovals();
        })
      )
      .subscribe({
        error: (err) => this.processErrorMessage(err, this.refreshRevisionData)
      });
  }

  rejectRevision(): void {
    const confirmation = confirm(
      'Are you sure you want to reject this revision?'
    );

    if (confirmation) {
      this.subscription.add(
        this.esraWeldingApiClient
          .rejectRevision(this.weldingRevisionId)
          .pipe(
            indicate(this.approvingSubject$),
            first(),
            tap((response) => {
              this.weldingRevision$ = of(response);
              this.getApprovals();
            }))
          .subscribe({
            error: (err) => this.processErrorMessage(err, this.refreshRevisionData)
          })
      );
    }
  }

  updateDescription(): void {
    //Creating copy of revision to remove approers as updateDate (with default value to DateTime.MinValue)
    //is incorrectly translated to JSON causing API issues
    const revisionToSend = Object.assign({}, this.updatedRevision);
    revisionToSend.approvals = [];

    this.esraWeldingApiClient
      .updateRevision(this.updatedRevision.id.toString(), revisionToSend)
      .pipe(
        first(),
        tap((response) => {
          this.editDescription = false;
          this.weldingRevision$ = of(response);
        })
      )
      .subscribe();
  }

  editApprover(templateRef: TemplateRef<any>): void {

    const dialogRef = this.pdfPreviewDialog.open(EditWeldingApproverComponent, {
      width: '50%',
      height: '60%',
      disableClose: false,
      data: {
        approvers: this.editApproversList,
        revisionId: this.updatedRevision.id,
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.weldingRevision$ = this.esraWeldingApiClient.getRevisionById(
        this.updatedRevision.id
      );
      this.getApprovals();
    });
  }

  sendForApproval(): void {
    this.esraWeldingApiClient
      .sendRevisionForApproval(this.updatedRevision.id)
      .pipe(indicate(this.sendToApproverSubject$), first())
      .subscribe({
        next: (response) => {
          this.snackBarService.showInfo('Email notification sent to approver.');
          this.refreshRevisionData();
        },
        error: (err) => this.processErrorMessage(err, this.refreshRevisionData)
      });
  }

  approveSectionVisible(revision: WeldingRevisionViewModel): boolean {
    const currentUser = this.userService.getCurrentUser();

    const correctRevisionStatus = this.approvers && 
        this.isUserApprover() && 
        revision.status === 2;

    let approverApproved = false;
    this.approvers.forEach(approval => {
      if(approval.approverOid === currentUser.localAccountId) {
        approverApproved = approval.status !== RevisionApprovalStatus._0
      }
    })

    return correctRevisionStatus && !approverApproved;
  }

  canAddReviewers(revision: WeldingRevisionViewModel) {
    return revision.status === RevisionStatus._0;
  }

  private processErrorMessage(error: any, callback: () => void) {
    this.snackBarService.showError(error.response);
    if(callback !== null && error.status == 550) {
        callback()
    }
  }

  cancel() {
    this.location.back();
  }
}
