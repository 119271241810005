// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-tips {
  margin: 1% 7.5%;
  width: 85%;
}

.search-input-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 25px;
}
.search-input-container input {
  flex: 0 0 90%;
  font-size: 14px;
  height: 48px;
}
.search-input-container .search-btn {
  display: flex;
  justify-items: center;
  align-items: center;
  background: #0066b2 0% 0% no-repeat padding-box;
  padding: 20px;
  height: 48px;
  color: white;
  font-family: "GothamBold", Arial, sans-serif;
  font-size: 14px;
  cursor: pointer;
}
.search-input-container .search-btn:hover {
  background-color: #0b2d71;
}
.search-input-container .clear-button {
  height: 36px;
  width: 20px;
  margin-left: -18%;
}
.search-input-container .clear-button .button {
  border: none;
}

.container-horizontal {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.container-vertical {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
}

.search-result-pane {
  flex: 3 100%;
  flex-basis: 100%;
}

.filters-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-top: 10px;
}

.items-per-page {
  margin: 2%;
}

.reset {
  display: flex;
  justify-content: flex-end;
}
.reset .text-button {
  font-size: 14px;
}

.export-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 25px;
}

.progress-bar-container {
  min-height: 6px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 25px;
  margin-left: 25px;
}
.progress-bar-container .searching-progress-bar {
  height: 5px;
}

.spinner-message {
  display: flex;
  font-style: "Gotham", Arial, sans-serif;
  align-items: center;
}
.spinner-message .mat-mdc-progress-spinner {
  margin-right: 10px;
  margin-left: 10px;
}

.welding-procedure-preview-pane {
  top: 100px;
}

.horizontal-container-for-results-and-preview {
  display: flex;
  align-items: center;
  flex-grow: 3;
}

.filter-selection-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Gotham", Arial, sans-serif;
  font-size: 14px;
  padding: 20px 25px;
}
.filter-selection-container .filter-selection-item {
  flex: 0 0 33%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
