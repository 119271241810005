import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { UserService } from '../services/user.service';

/** This component is used only to avoid Angular reload when doing acquireTokenSilent() */
@Component({
  selector: 'app-msal',
  template: '',
})
export class MsalComponent implements OnInit {
  constructor(
    private msalService: MsalService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.msalService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          this.userService.setCurrentUser(result.account);
          console.log(result);
        }
      },
      error: (error) => console.log(error),
    });
  }
}
