import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceNullWithText',
})
export class NullTypePipe implements PipeTransform {
  transform(value: any): string {
    if (value == undefined || value == null) {
      return '-';
    }

    return value;
  }
}
