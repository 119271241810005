// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-section {
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: flex-end;
  row-gap: 100px;
}
.buttons-section button {
  margin: 10px 15px;
}

.progress-bar-container {
  min-height: 6px;
  margin: 2% 3%;
}
.progress-bar-container div {
  height: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
