import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { indicate } from 'src/app/operators';
import { EsraPipingAPIClient, BranchTableViewModel } from 'src/app/shared/models/autogenerated-piping';
import { BehaviorSubject } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import {
  faSearch,
  faTrash,
  faPen,
  faPlusCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { BranchPreviewComponent } from '../dialogs/branch-preview/branch-preview.component';
import { ServiceNoteUsageComponent } from '../dialogs/service-note-usage/service-note-usage.component';
import { DictionaryDeleteComponent } from '../dialogs/dictionary-delete/dictionary-delete.component';
import { productionGuard } from 'src/app/core/guards/guards';
import { MatTableDataSource } from '@angular/material/table';
import { InsightsService } from 'src/app/shared/services/insights.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss'],
})
export class BranchComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;

  constructor(
    private esraApiClient: EsraPipingAPIClient,
    private snackBarService: SnackBarService,
    private insightsService: InsightsService,
    private dialog: MatDialog
  ) {}

  searching$ = new BehaviorSubject<boolean>(false);
  tableBranchArray: BranchTableViewModel[] = [];
  searchText = '';
  faEdit = faPen;
  faTrashCan = faTrash;
  faSearch = faSearch;
  faPlusCircle = faPlusCircle;
  faInfo = faInfoCircle;

  currentPage = 0;
  pageSize = 25;
  totalCount: number;
  pageSizes = [2, 5, 10, 25, 50, 100];

  columnNames: string[] = ['NAME', 'PREVIEW', 'STATUS'];
  newBranchTable: BranchTableViewModel | null = null;
  dataSource = new MatTableDataSource<BranchTableViewModel>(this.tableBranchArray);

  ngOnInit() {
    if (productionGuard()) {
      this.columnNames.push('actions');
    }
    this.populateTableBranchArray();
    this.insightsService.logPageView('branchTableDictionary ngOnInit');
  }

  populateTableBranchArray() {
    this.esraApiClient
      .getCurrentBranchTablesByPageNumberAndNumberOfResults(
        Number(this.currentPage),
        Number(this.pageSize),
        this.searchText
      )
      .pipe(
        indicate(this.searching$),
        first(),
        tap((searchResult) => {
          this.totalCount = searchResult.totalResults;
          this.tableBranchArray = searchResult.results;
          this.dataSource.data = this.tableBranchArray;
        })
      )
      .subscribe();
  }

  public previewBranch(table: BranchTableViewModel) {
    this.dialog.open(BranchPreviewComponent, {
        minWidth: '60vw',
        minHeight: '50vh',
        maxWidth: '90vw',
        maxHeight: '80vh',
      data: table,
    });
  }

  public createNewBranchTable() {
    this.insightsService.logEvent('branchTableDictionary createNewBranchTableSTART');
    window.open('path/branchTableAddEdit');
  }

  public editBranch(branchTable) {
    localStorage.setItem('branchTable', JSON.stringify(branchTable));
    this.insightsService.logEvent('branchTableDictionary editBranchTableSTART');
    window.open('path/branchTableAddEdit');
  }

  private addNewBranchTableToTop(): void {
    if (this.newBranchTable) {
      this.tableBranchArray = [this.newBranchTable, ...this.tableBranchArray];
      this.dataSource.data = this.tableBranchArray;
      this.newBranchTable = null;
    }  
  }

  public getUsageInformation(table: BranchTableViewModel) {
    this.dialog.open(ServiceNoteUsageComponent, {
      width: '40%',
      height: '40%',
      data: table,
    });
  }

  openDeletionDialog(branchTable: BranchTableViewModel) {
    this.dialog.open(DictionaryDeleteComponent, {
      width: '40%',
      height: '23%',
      data: {
        item: branchTable,
        objectName: 'branch table',
        objectType: 'branch table'
      }
    }).afterClosed().subscribe((result) => {
      if(result) 
      {
        this.esraApiClient
        .deleteBranchTable(branchTable.branchTableId)
        .subscribe({
          next: () => {
            this.insightsService.logEvent('DeleteBranchTable', {
              branchTable: JSON.stringify(branchTable)
            });
            this.snackBarService.showSnackBar(
              true,
              `Branch table "${branchTable.name}" was successfully deleted.`,
              'mat-snack-bar-info'
            );
            this.populateTableBranchArray();
          },
          error: (error) => {
            this.snackBarService.showSnackBar(
              true,
              `Sorry, something went wrong. Error trying to delete branch table "${branchTable.name}".`,
              'mat-snack-bar-error'
            ),
            this.insightsService.logException(error);
          }});
      }
    });
  }

  public clearSearchText() {
    this.searchText = '';
    this.currentPage = 0;
    this.paginator.firstPage();
    this.populateTableBranchArray();
  }

  public updatePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.populateTableBranchArray();
  }

  protected updateStatus(branchTable: BranchTableViewModel) {
    this.esraApiClient.toggleIsActiveBranchTables(branchTable.isActive, [branchTable.branchTableId]).pipe(indicate(this.searching$), first(),
    tap(() => this.populateTableBranchArray())).subscribe({error: () => this.populateTableBranchArray()});
  }
}
